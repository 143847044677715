export default {

    getStandardFeeForMXN(amount) {
        if (isNaN(amount)) {
            return 0;
        }
        return (amount < 999.99) ? 0.039 : (amount < 2999.99) ? 0.029 : 0.019;
    },

    getStandardFeeForUSD(amount) {
        if (isNaN(amount)) {
            return 0;
        }
        return (amount < 49.99) ? 0.039 : (amount < 149.99) ? 0.029 : 0.019;
    },

    getExpressFeeForMXN(amount) {
        if (isNaN(amount)) {
            return 0;
        }
        return (amount < 999.99) ? 0.079 : (amount < 2999.99) ? 0.069 : 0.059;
    },

    getExpressFeeForUSD(amount) {
        if (isNaN(amount)) {
            return 0;
        }
        return (amount < 49.99) ? 0.079 : (amount < 149.99) ? 0.069 : 0.059;
    }
}