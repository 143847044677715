<template>
  <div id="login-form-container">
    <ui-card id="card-container" v-shadow.transition="[2, 8]">
      <img id="logo" alt="Logo de Ticket Dancer" src="@/assets/logo-td.png" width="100" />
      <ui-list-divider />
      <ui-form type="|" item-margin-bottom="20" action-align="right">
        <template #default="{ subitemClass, actionClass }">
          <ui-form-field style="margin-top: 20px">
            <label> {{ $t('user.login') }}:</label>
            <div :class="subitemClass">
              <ui-form-field>
                <ui-radio v-model="loginType" value="producer" :disabled="formLoading" />
                <label>{{ $t('user.producer') }}</label>
              </ui-form-field>
              <ui-form-field>
                <ui-radio v-model="loginType" value="staff" :disabled="formLoading" />
                <label>{{ $t('user.staff') }}</label>
              </ui-form-field>
            </div>
          </ui-form-field>
          <ui-form-field>
            <ui-textfield input-type="email" outlined fullwidth v-model="formData.email"
              helper-text-id="email-helper-text" :disabled="formLoading">
              {{ $t('filters.email') }}
              <template #before>
                <ui-textfield-icon>person</ui-textfield-icon>
              </template>
            </ui-textfield>
            <ui-textfield-helper id="email-helper-text" v-model:validMsg="validMsg.email"></ui-textfield-helper>
          </ui-form-field>
          <ui-form-field>
            <ui-textfield input-type="password" outlined fullwidth helper-text-id="password-helper-text"
              v-model="formData.password" :disabled="formLoading" :attrs="{ autocomplete: 'current-password' }"
              @keyup.enter="loginFromStore">
              {{ $t('user.password') }}
              <template #before>
                <ui-textfield-icon>vpn_key</ui-textfield-icon>
              </template>
            </ui-textfield>
            <ui-textfield-helper id="password-helper-text" v-model:validMsg="validMsg.password"></ui-textfield-helper>
          </ui-form-field>
          <ui-form-field :class="actionClass">
            <ui-button raised native-type="submit" @click.prevent="loginFromStore" @keyup.enter="loginFromStore"
              :disabled="formLoading"> {{ $t('actions.login') }}
              <template #after="{ iconClass }">
                <ui-icon :class="iconClass">arrow_forward</ui-icon>
              </template>
            </ui-button>
          </ui-form-field>
        </template>
      </ui-form>
      <ui-progress v-show="formLoading" indeterminate active></ui-progress>
      <error-component v-show="errorLogin" :message="errorMessage" />
    </ui-card>
    <h5>
      <a href="https://ticketdancer.com.mx"> {{ $t('user.rights', [(new Date().getFullYear())]) }}</a>
    </h5>
  </div>
</template>

<script>
import { useValidator } from "balm-ui";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import { useMainStore } from '@/stores/main';
import { mapActions } from 'pinia';
import { RECAPTCHA_ACTIONS } from "@/utils/constants";
import commonsApi from "@/api/CommonsApi";

const validations = [
  {
    key: "email",
    label: "Email",
    validator: "required",
  },
  {
    key: "password",
    label: "Password",
    validator: "required",
  },
];

export default {
  name: "LoginForm",
  components: {
    ErrorComponent,
  },
  data() {
    return {
      balmUI: useValidator(),
      validations,
      loginType: "producer",
      validMsg: {},
      formData: {
        email: "",
        password: "",
      },
      formLoading: false,
      errorLogin: false,
      errorMessage: "",
    };
  },
  methods: {
    ...mapActions(useMainStore, ['login']),
    async loginFromStore() {
      let result = this.balmUI.validate(this.formData);
      let { valid, validMsg } = result;
      this.validMsg = validMsg;

      if (valid) {

        window.grecaptcha.enterprise.ready(async () => {

          try {

            this.formLoading = true;
            this.errorLogin = false;

            const token = await window.grecaptcha.enterprise.execute(process.env.VUE_APP_RECAPTCHA_KEY, { action: RECAPTCHA_ACTIONS.LOGIN });
            await commonsApi.checkReCaptcha(RECAPTCHA_ACTIONS.LOGIN, token, process.env.VUE_APP_RECAPTCHA_KEY)

            const payload = {
              loginType: this.loginType,
              email: this.formData.email,
              password: this.formData.password,
            };

            await this.login(payload);

            this.formLoading = false;
            this.$router.push("/registrar");
          } catch (error) {
            this.formLoading = false;
            this.errorMessage = error;
            this.errorLogin = true;
            //console.log(error);
          }
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label {
  font-size: 1.2em;
  font-weight: 600;
  color: #222;
}

h2,
h5 {
  text-align: center;
  margin-top: 2em;
}

#login-form-container {
  max-width: 480px;
  margin: auto;
}

#logo {
  margin: auto;
  padding-bottom: 2em;
}

#card-container {
  padding: 2em;
  margin-top: 3em;
}
</style>
