<template>
    <ui-grid>
        <ui-grid-cell columns="10">
            <ui-select :disabled="disableSelect || formLoading" outlined fullwidth v-model="selectedClassificationId"
                :options="classifications" :option-format="optionFormat" @selected="onSelected($event)"> {{
                    $t('commonElements.selectOtherPaymentRegistry') }}
            </ui-select>
        </ui-grid-cell>
        <ui-grid-cell columns="2" align="middle">
            <ui-icon-button icon="refresh" @click="getClassificationsData"></ui-icon-button>
        </ui-grid-cell>
    </ui-grid>
    <ui-progress v-show="formLoading" indeterminate active></ui-progress>
    <error-component v-show="!formLoading && errorClassifications" :message="errorMessage" />
</template>

<script>
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import EventsAPI from "@/api/EventsAPI";
import { useMainStore } from '@/stores/main';
import { mapState, mapWritableState } from 'pinia';

export default {
    components: {
        ErrorComponent,
    },
    props: {
        disableSelect: Boolean
    },
    emits: ["onSelectedClassificationId"],
    async mounted() {
        await this.getClassificationsData();
    },
    data() {
        return {
            classifications: [],
            formLoading: false,
            errorClassifications: false,
            errorMessage: "",
        }
    },
    computed: {
        ...mapState(useMainStore, ['defaultLanguage', 'userLogged']),
        ...mapWritableState(useMainStore, ['selectedClassificationId']),
        optionFormat() {
            return {
                label: this.defaultLanguage === 'es' ? "nameEs" : "nameEn",
                value: "registerTypeClassificationId",
            }
        },
    },
    methods: {
        async getClassificationsData() {
            this.formLoading = true;
            this.errorClassifications = false;
            const mainStore = useMainStore()
            try {
                const eventsApi = new EventsAPI();
                const classificationsData = await eventsApi.getAllRegisterTypeClassifications(this.userLogged.token);
                this.classifications = [...classificationsData];

                if (this.classifications.length > 0) {
                    this.$emit('onSelectedClassificationId')
                }
                else {
                    this.classifications = [];
                    mainStore.$patch({ selectedClassificationId: null })
                }
                this.formLoading = false;
            } catch (error) {
                this.formLoading = false;
                this.errorMessage = error;
                this.errorClassifications = true;
                this.classifications = [];
                mainStore.$patch({ selectedClassificationId: null })
            }
        },
        onSelected(selected) {
            //console.log("selected: " + selected.value);
            const mainStore = useMainStore()
            mainStore.$patch({
                selectedClassificationId: selected.value
            })
            this.$emit("onSelectedClassificationId");
        },
    },
}

</script>