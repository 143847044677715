<template>
  <h3>{{ $t('purchases.registriesTitle') }}</h3>
  <ui-grid>
    <ui-grid-cell columns="4">
      <ui-select outlined fullwidth :options="filtros" v-model="filtroSeleccionado">{{ $t('filters.filterBy')
      }}</ui-select>
    </ui-grid-cell>
    <ui-grid-cell columns="4" v-if="filtroSeleccionado === 'tipoPago'">
      <ui-form-field>
        <ui-radio v-model="tipoPago" value="tarjeta" />
        <label>{{ $t('purchases.card') }}</label>
        <ui-radio v-model="tipoPago" value="oxxo" />
        <label>{{ $t('purchases.oxxo') }}</label>
        <ui-radio v-model="tipoPago" value="bankTransfer" />
        <label>{{ $t('purchases.bankTransfer') }}</label>
      </ui-form-field>
    </ui-grid-cell>
    <ui-grid-cell columns="4" v-else-if="filtroSeleccionado === 'moneda'">
      <ui-form-field>
        <ui-radio v-model="monedaPago" value="mxn" />
        <label>MXN</label>
        <ui-radio v-model="monedaPago" value="usd" />
        <label>USD</label>
      </ui-form-field>
    </ui-grid-cell>
    <ui-grid-cell columns="4" v-else>
      <ui-textfield outlined fullwidth v-model="criterioBusqueda" @keyup.enter="buscar">{{ $t('filters.searchBy') }}
      </ui-textfield>
    </ui-grid-cell>
    <ui-grid-cell columns="1">
      <ui-icon-button icon="search" @click="buscar"> </ui-icon-button>
    </ui-grid-cell>
    <ui-grid-cell columns="1">
      <ui-icon-button icon="undo" @click="undo"> </ui-icon-button>
    </ui-grid-cell>
    <ui-grid-cell columns="1">
      <ui-icon-button icon="refresh" @click="updateRegistries">
      </ui-icon-button>
    </ui-grid-cell>
    <ui-grid-cell columns="1">
      <ui-icon-button icon="sim_card_download" @click="downloadReport"> </ui-icon-button>
    </ui-grid-cell>
  </ui-grid>
  <ui-progress v-show="fileDownloading" indeterminate active></ui-progress>
  <error-component v-show="errorLoad" :message="errorMessage" />
  <ui-table fullwidth :data="paginatedPaymentsRegistries" :thead="thead" :tbody="tbody">
    <ui-pagination v-model="page" :page-size="itemsPerPage" :total="total" show-total></ui-pagination>
  </ui-table>
</template>
<script>
import { helpers } from "balm-ui";
import { useMainStore } from '@/stores/main';
import { mapState } from 'pinia';
import PaymentsAPI from "@/api/PaymentsAPI";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import formatters from "@/utils/formatters";
import i18n from "@/i18n";

const tiposFiltro = [
  {
    label: i18n.global.t('filters.orderNumber'),
    value: "id",
  },
  {
    label: i18n.global.t('filters.paymentMethod'),
    value: "tipoPago",
  },
  {
    label: i18n.global.t('filters.cardNumber'),
    value: "tarjeta",
  },
  {
    label: i18n.global.t('filters.currency'),
    value: "moneda",
  },
  {
    label: i18n.global.t('filters.registerType'),
    value: "tipo",
  },
  {
    label: i18n.global.t('filters.groupCompany'),
    value: "grupo",
  },
  {
    label: i18n.global.t('filters.tdUser'),
    value: "correo",
  },
];

export default {
  name: "TablaPagosRegistros",
  components: {
    ErrorComponent
  },
  props: {
    paymentsRegistries: Array,
  },
  emits: ["refreshPaymentsRegistries"],
  data() {
    return {
      thead: [
        this.$t('headers.paymentDate'),
        this.$t('headers.orderNumber'),
        this.$t('filters.paymentMethod'),
        this.$t('filters.cardNumber'),
        this.$t('filters.registerType'),
        this.$t('headers.registerNumber'),
        this.$t('filters.groupCompany'),
        this.$t('headers.q1'),
        this.$t('headers.a1'),
        this.$t('headers.q2'),
        this.$t('headers.a2'),
        this.$t('headers.q3'),
        this.$t('headers.a3'),
        this.$t('filters.tdUser'),
        { value: this.$t('filters.currency'), align: "center" },
        { value: this.$t('headers.amount'), align: "right" },
        { value: this.$t('headers.netAmount'), align: "right" },
      ],
      tbody: [
        "paymentDateString",
        "id",
        {
          field: "paymentSource",
          fn: (data) => {
            return data.paymentSource === "card" ? this.$t('purchases.card') : data.paymentSource === "customer_balance" ? this.$t('purchases.bankTransfer') : this.$t('purchases.oxxo');
          },
        },
        "cardId",
        {
          field: "registerFullName",
          fn: (data) => {
            const mainStore = useMainStore();
            const registerTitle = mainStore.defaultLanguage === 'es' ? data.registerTypeTitle : data.registerTypeTitleEn
            return registerTitle + ":" + data.registerTypeName;
          },
        },
        "registersNum",
        "companyGroup",
        "question1",
        "answer1",
        "question2",
        "answer2",
        "question3",
        "answer3",
        "correo",
        {
          field: "currency",
          fn: (data) => {
            return data.currency.toUpperCase();
          },
          align: "center",
        },
        {
          field: "amount",
          fn: (data) => {
            return formatters.formatCurrency(data.amount);
          },
          align: "right",
        },
        {
          field: "promotorAmount",
          fn: (data) => {
            return formatters.formatCurrency(data.promotorAmount);
          },
          align: "right",
        },
      ],
      tfoot: [
        null,
        null,
        null,
        null,
        null,
        {
          field: "ticketsNum",
          fnName: "sum",
        },
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      ],
      page: 1,
      itemsPerPage: 20,
      filtros: tiposFiltro,
      filtroSeleccionado: "id",
      tipoPago: "tarjeta",
      monedaPago: "mxn",
      filteredPaymentsRegistries: [...this.paymentsRegistries],
      criterioBusqueda: "",
      errorLoad: false,
      fileDownloading: false,
      errorMessage: ""
    };
  },
  computed: {
    ...mapState(useMainStore, ['userLogged', 'selectedEventId']),
    paginatedPaymentsRegistries() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      //console.log("start: " + start + " end: " + end);
      return this.filteredPaymentsRegistries.slice(start, end);
    },
    total() {
      return this.filteredPaymentsRegistries.length;
    },
  },
  methods: {
    updateRegistries() {
      this.$emit("refreshPaymentsRegistries", "registros");
    },
    undo() {
      this.filteredPaymentsRegistries = [...this.paymentsRegistries];
      this.criterioBusqueda = "";
      this.page = 1;
    },
    buscar() {
      if (this.filtroSeleccionado === "tipoPago") {
        if (this.tipoPago === "tarjeta") {
          this.filteredPaymentsRegistries = [...this.paymentsRegistries].filter(
            (payment) => payment.paymentSource === "card"
          );
        } else if (this.tipoPago === "oxxo") {
          this.filteredPaymentsRegistries = [...this.paymentsRegistries].filter(
            (payment) => payment.paymentSource === "oxxo"
          );
        } else {
          this.filteredPaymentsRegistries = [...this.paymentsRegistries].filter(
            (payment) => payment.paymentSource === "customer_balance"
          );
        }
        this.page = 1;
      } else if (this.filtroSeleccionado === "moneda") {
        if (this.monedaPago === "mxn") {
          this.filteredPaymentsRegistries = [...this.paymentsRegistries].filter(
            (payment) => payment.currency === "mxn"
          );
        } else {
          this.filteredPaymentsRegistries = [...this.paymentsRegistries].filter(
            (payment) => payment.currency === "usd"
          );
        }
        this.page = 1;
      } else if (!helpers.isEmpty(this.criterioBusqueda.trim())) {
        var criterioBusquedaLowerCase = this.criterioBusqueda.trim().toLowerCase();
        switch (this.filtroSeleccionado) {
          case "id":
            this.filteredPaymentsRegistries = [
              ...this.paymentsRegistries,
            ].filter((payment) => payment.id.toLowerCase().includes(criterioBusquedaLowerCase));
            break;
          case "tarjeta":
            this.filteredPaymentsRegistries = [
              ...this.paymentsRegistries,
            ].filter(
              (payment) =>
                !helpers.isEmpty(payment.cardId) &&
                payment.cardId.toLowerCase().includes(criterioBusquedaLowerCase)
            );
            break;
          case "tipo":
            this.filteredPaymentsRegistries = [
              ...this.paymentsRegistries,
            ].filter(
              (payment) =>
                payment.registerTypeTitle.toLowerCase().includes(criterioBusquedaLowerCase) ||
                payment.registerTypeTitleEn.toLowerCase().includes(criterioBusquedaLowerCase) ||
                payment.registerTypeName.toLowerCase().includes(criterioBusquedaLowerCase)
            );
            break;
          case "grupo":
            this.filteredPaymentsRegistries = [
              ...this.paymentsRegistries,
            ].filter(
              (payment) =>
                !helpers.isEmpty(payment.companyGroup) &&
                payment.companyGroup.toLowerCase().includes(criterioBusquedaLowerCase)
            );
            break;
          case "correo":
            this.filteredPaymentsRegistries = [
              ...this.paymentsRegistries,
            ].filter((payment) =>
              payment.correo.toLowerCase().includes(criterioBusquedaLowerCase)
            );
            break;
        }
        this.page = 1;
      }
    },
    async downloadReport() {
      try {

        this.fileDownloading = true;
        let fileName = "pagos_complementarios_" + new Date().toLocaleDateString() + ".xlsx"

        const paymentsApi = new PaymentsAPI();

        const fileData = await paymentsApi.downloadRegistryPayments({
          token: this.userLogged.token,
          eventId: this.selectedEventId
        });
        const link = document.createElement('a')
        link.href = URL.createObjectURL(fileData)
        link.target = "_blank"
        link.download = fileName
        link.click()
        URL.revokeObjectURL(link.href)
        this.fileDownloading = false;

      } catch (error) {
        this.errorLoad = true;
        this.fileDownloading = false;
        this.errorMessage = error;
      }
    }
  },
};
</script>
<style scoped>
h3 {
  text-align: center;
  margin-top: 2em;
}
</style>