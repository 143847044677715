import { URLS, LS_KEYS } from "@/utils/constants";
import commonsApi from "./CommonsApi";
import i18n from "@/i18n";

export default class UserAPI {

    async login(userModel) {

        let capcha = await commonsApi.getCaptcha();

        const loginUrl = userModel.loginType === "producer" ? URLS.OWNER_LOGIN : URLS.STAFF_LOGIN;

        let response = await fetch(loginUrl, {
            method: "POST",
            body: JSON.stringify({
                correo: userModel.email,
                password: userModel.password,
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let userData = await response.json();
            localStorage.setItem(LS_KEYS.USER_LOGIN_KEY, JSON.stringify(userData));
            return userData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    logout() {
        localStorage.removeItem(LS_KEYS.USER_LOGIN_KEY);
    }

    async getUserData(payload) {

        let capcha = await commonsApi.getCaptcha();

        const getDataUrl = payload.sessionType === "OWNER" ? URLS.OWNER_GET_USER_DATA : URLS.STAFF_GET_USER_DATA;

        let response = await fetch(getDataUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let userData = await response.json();
            //console.log(userData);
            return userData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async validatePassword(payload) {

        let capcha = await commonsApi.getCaptcha();

        //console.log(payload);

        const passwordValidationUrl = payload.sessionType === "OWNER" ? URLS.OWNER_PASS_VALIDATION : URLS.STAFF_PASS_VALIDATION;

        let response = await fetch(passwordValidationUrl, {
            method: "POST",
            body: JSON.stringify({
                correo: payload.correo,
                password: payload.password,
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            return response.status === 200
        }
    }

    async updateUserData(payload) {

        let capcha = await commonsApi.getCaptcha();

        //console.log(payload);

        const updateUserUrl = payload.sessionType === "OWNER" ? URLS.OWNER_UPDATE_USER_DATA : URLS.STAFF_UPDATE_USER_DATA;

        let response = await fetch(updateUserUrl, {
            method: "PUT",
            body: JSON.stringify({
                correo: payload.correo,
                nombre: payload.nombre,
                password: payload.password,
                apellidos: payload.apellidos,
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let userData = await response.json();
            localStorage.setItem(LS_KEYS.USER_LOGIN_KEY, JSON.stringify(userData));
            //console.log(userData);
            return userData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }
}