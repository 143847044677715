
<template>
    <h2>{{ $t('subtitles.listOfBankTransfers') }}</h2>
  <div id="event-selector">
    <ui-grid>
      <ui-grid-cell columns="8">
        <event-selector @on-selected-event="reestablecer" />
      </ui-grid-cell>
      <ui-grid-cell columns="4">
        <entry-type-selector @on-selected-entry-type="reestablecer" />
      </ui-grid-cell>
    </ui-grid>
  </div>
  <div class="content">
    <error-component v-show="errorTable" :message="errorMessage" />
    <ui-skeleton :loading="loadingTable" :title="false" active :paragraph="{ rows: 15 }">
    <ui-grid>
        <ui-grid-cell columns="1" align="middle">
          <label> {{ $t('filters.filterBy') }}: </label>
        </ui-grid-cell>
        <ui-grid-cell columns="4" align="middle">
          <ui-textfield outlined fullwidth v-model="userSearch"> {{ $t('filters.tdUser')}} </ui-textfield>
        </ui-grid-cell>
        <ui-grid-cell columns="2" align="middle">
          <ui-button outlined :disabled="loadingTable" class="action" @click.prevent="buscar" @keyup.enter="buscar">
            {{ $t('actions.search') }}
            <template #after="{ iconClass }">
              <ui-icon :class="iconClass">search</ui-icon>
            </template>
          </ui-button>
        </ui-grid-cell>
        <ui-grid-cell columns="2" align="middle">
          <ui-button outlined :disabled="loadingTable" class="action" @click.prevent="reestablecer">
            {{ $t('actions.clear') }}
            <template #after="{ iconClass }">
              <ui-icon :class="iconClass">refresh</ui-icon>
            </template>
          </ui-button>
        </ui-grid-cell>
      </ui-grid>
      <template v-if="totalRows > 0">
        <ui-table fullwidth :data="bankTransfers" :thead="thead" :tbody="tbody">
          <ui-pagination v-model="page" :page-size="itemsPerPage" :total="totalRows" show-total></ui-pagination>
        </ui-table>
      </template>
      <template v-else>
        <ui-divider></ui-divider>
          <p style="text-align: center;">
              {{ $t('commonElements.noResults') }}
          </p>
      </template>
    </ui-skeleton>
  </div>
</template>

<script>
import { useMainStore } from '@/stores/main';
import { helpers } from "balm-ui";
import EventSelector from "@/components/common/EventSelector.vue";
import EntryTypeSelector from "@/components/common/EntryTypeSelector.vue";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import bankTransfersAPI from "@/api/BankTransfersAPI"
import formatters from "@/utils/formatters";
import i18n from "@/i18n"

export default {
  name: "ListadoTransferenciasPage",
  components: {
    EventSelector,
    EntryTypeSelector,
    ErrorComponent
  },
  created() {

    const mainStore = useMainStore()
    mainStore.$patch({ pageTitle: i18n.global.t('titles.listOfBankTransfers') })
    //console.log("ListadoEntradasPage created");
  },
  data() {
    return {
      thead: [
        this.$t('headers.transferDate'),
        this.$t('commonElements.description'),
        this.$t('headers.bankReference'),
        this.$t('headers.depositedAmount'),
        this.$t('filters.currency'),
        this.$t('headers.bankTransferComplete'),
        this.$t('filters.orderNumber'),
        this.$t('filters.tdUser'),
      ],
      tbody: [
        'lastPaymentDate',
        {
          field: "description",
          fn: (data) => {
            if (data.type === 'TICKET') {
              return data.ticketsNum + " x " + data.ticketName
            } else if (data.type === 'REGISTRY') {
              const mainStore = useMainStore();
              const registerTitle = mainStore.defaultLanguage === 'es' ? data.registerTypeTitle : data.registerTypeTitleEn
              return data.registersNum + " x " + registerTitle
            } else {
              return ''
            }
          },
        },
        'reference',
        {
          field: "depositedAmount",
          fn: (data) => {
            return formatters.formatCurrency(data.depositedAmount);
          },
          align: "right",
        },
        {
          field: "currency",
          fn: (data) => {
            return data.currency.toUpperCase();
          },
          align: "center",
        },
        {
          field: "complete",
          fn: (data) => {
            return data.complete ? this.$t('commonElements.yes') : this.$t('commonElements.no');
          },
          class: (data) => {
            return data.complete ? 'green' : 'red';
          },
          align: "center",
        },
        'orderId',
        'tdUser'
      ],
      userSearch: '',
      bankTransfers: [],
      page: 1,
      itemsPerPage: 10,
      totalRows: 0,
      disablePageWatcher: false,
      loadingTable: false,
      errorTable: false,
      errorMessage: "",
    }
  },
  watch: {
    page() {
      if (!this.disablePageWatcher) {
        this.updateTable();
        }
      }
  },
  methods: {
    async updateTable() {
      this.loadingTable = true;
      this.errorTable = false;
      const pageForApi = this.page - 1
      try {
        const response = await bankTransfersAPI.getBankTransfersByEvent(this.userSearch, pageForApi, this.itemsPerPage);
        //console.log(response)
        if (helpers.isEmpty(response)) {
          this.bankTransfers = []
          this.totalRows = 0
        } else {
          this.bankTransfers = [...response.data]
          this.totalRows = response.totalRows
        }
      } catch (error) {
        this.errorTable = true;
        this.errorMessage = error;
      }
      this.loadingTable = false;
      this.disablePageWatcher = false

    },
    async buscar() {
      this.disablePageWatcher = true
      this.page = 1
      await this.updateTable();
    },
    async reestablecer() {
      this.userSearch = ''
      this.disablePageWatcher = true
      this.page = 1
      await this.updateTable();
    }
  }
}

</script>

<style scoped>
h2,
h5 {
  text-align: center;
  margin-top: 2em;
}

#event-selector {
  margin: auto;
  width: 75%;
}

.content {
  margin: auto;
  width: 90%;
}

.green {
  font-weight: bold;
  color: #006600;
}

.red {
  font-weight: bold;
  color: #d32f2f;
}

button.action {
  width: 100%;
  height: 50px;
}
</style>