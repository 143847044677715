import { helpers } from 'balm-ui'; // Default Usage
import i18n from '@/i18n';
// OR
// import helpers from 'balm-ui/utils/helpers'; // ### Individual Usage

export default {
  required: {
    validate(value) {
      return !helpers.isEmpty(value);
    },
    message: '%s ' + i18n.global.t('validations.required')
  },
  positive: {
    validate(value) {
      const number = Number(value)
      return !isNaN(number) && number > 0
    },
    message: '%s ' + i18n.global.t('validations.greaterThanZero')
  },
}
