<template>
  <!--<router-view v-if="isMainPage" />
  <sidebar-content-layout v-else></sidebar-content-layout>-->
  <sidebar-content-layout></sidebar-content-layout>
</template>

<script>

import SidebarContentLayout from '@/components/common/SidebarContentLayout.vue'

export default {
  name: "App",
  components: {
    SidebarContentLayout
  },
  computed: {
    isMainPage() {
      return this.$route.name === 'home'
    }
  }
};
</script>

<style>
html {
  --mdc-typography-font-family: "Nunito", sans-serif;
}

label {
  font-family: "Nunito", sans-serif;
}

body {
  display: block;
  margin: 0px;
}

#app {
  font-family: "Nunito", sans-serif;
}
</style>
