<template>
    <div class="images-container">
        <image-uploader :img-width="800" :img-height="500" :img-title="this.$t('myEvents.mainImage')"
            :img-url="principalImage" img-type="EVENT_PRINCIPAL"></image-uploader>
        <ui-divider></ui-divider>
        <image-uploader :img-width="800" :img-height="400" :img-title="this.$t('myEvents.bannerImage')"
            :img-url="bannerImage" img-type="EVENT_BANNER"></image-uploader>
        <ui-divider></ui-divider>
        <image-uploader :img-width="500" :img-height="400" :img-title="this.$t('myEvents.logoImage')" :img-url="logoImage"
            img-type="EVENT_LOGO"></image-uploader>
    </div>
</template>

<script>
import ImageUploader from '@/components/common/ImageUploader.vue';
import { useMainStore } from '@/stores/main';
import { mapState } from 'pinia';
export default {
    components: {
        ImageUploader
    },
    computed: {
        ...mapState(useMainStore, ['writableEvent']),
        principalImage() {
            return this.writableEvent ? this.writableEvent.urlPrincipalImage : ''
        },
        bannerImage() {
            return this.writableEvent ? this.writableEvent.urlBanner : ''
        },
        logoImage() {
            return this.writableEvent ? this.writableEvent.urlLogo : ''
        }
    }
}
</script>

<style scoped>
.images-container {
    margin-top: 20px;
    text-align: center;
}
</style>