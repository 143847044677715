<template>
  <ui-grid>
    <ui-grid-cell columns="10" align="middle">
      <ui-select outlined fullwidth icon="local_activity" v-model="selectedEvent" :options="events"
        :option-format="optionFormat" :disabled="formLoading" @selected="onSelected($event)">
        {{ $t('commonElements.selectEvent') }}
      </ui-select>
    </ui-grid-cell>
    <ui-grid-cell columns="2" align="middle">
      <ui-icon-button icon="refresh" @click="getUserEventsData"></ui-icon-button>
    </ui-grid-cell>
  </ui-grid>
  <ui-progress v-show="formLoading" indeterminate active></ui-progress>
  <error-component v-show="!formLoading && errorEvents" :message="errorMessage" />
</template>
<script>
import { useMainStore } from '@/stores/main';
import { mapState, mapActions } from 'pinia';
import ErrorComponent from "@/components/common/ErrorComponent.vue";

const eventFormatForSelect = {
  label: "eventNameAndId",
  value: "id",
};

export default {
  name: "EventSelector",
  emits: ["onSelectedEvent"],
  components: {
    ErrorComponent,
  },
  data() {
    return {
      selectedEvent: "",
      optionFormat: eventFormatForSelect,
      events: [],
      formLoading: false,
      errorEvents: false,
      errorMessage: "",
    };
  },
  async mounted() {
    //console.log("EventSelector mounted");
    await this.getUserEventsData();
  },
  computed: {
    ...mapState(useMainStore, ['selectedEventId', 'ownerEvents']),
  },
  methods: {
    ...mapActions(useMainStore, ['refreshOwnerEvents']),
    async getUserEventsData() {
      //console.log("getUserEventsData()");
      const mainStore = useMainStore()
      this.formLoading = true;
      try {

        await this.refreshOwnerEvents();
        this.events = [...this.ownerEvents];

        if (this.events.length > 0) {

          this.selectedEvent = this.selectedEventId;
          this.$emit("onSelectedEvent");

        } else {
          this.selectedEvent = "";
          this.events = [];
          mainStore.$patch({ selectedEventId: null, writableEvent: null })
        }

        this.formLoading = false;
      } catch (error) {
        this.formLoading = false;
        this.errorMessage = error;
        this.errorEvents = true;
        this.selectedEvent = "";
        this.events = [];
        mainStore.$patch({ selectedEventId: null })
      }
    },
    onSelected(selected) {
      //console.log("selected: " + selected.value);
      const mainStore = useMainStore()
      mainStore.$patch({
        selectedEventId: selected.value,
        writableEvent: this.events.find(x => x.id === this.selectedEvent)
      })
      this.$emit("onSelectedEvent");
    },
  },
};
</script>