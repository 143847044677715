<template>
  <h3> {{ $t('entryReg.logTitle') }}</h3>
  <ui-card id="card-container" v-shadow.transition="[2, 8]">
    <div id="found-content" v-if="showData">
      <ui-table :data="tableData" :thead="thead" :tbody="tbody" fullwidth></ui-table>
    </div>
    <div id="no-content-found" v-else>
      <p> {{ $t('commonElements.noResults') }}</p>
    </div>
  </ui-card>
</template>
<script>
export default {
  name: "LogBoletosRegistros",
  props: {
    ticketFound: Object,
    registryFound: Object,
  },
  data() {
    return {
      thead: [
        this.$t('headers.logDate'),
        this.$t('headers.logType'),
        this.$t('headers.detail'),
        this.$t('headers.enteredBy'),
      ],
      tbody: [
        "fechaMovimiento",
        {
          field: "tipoMovimiento",
          fn: (data) => {
            switch (data.tipoMovimiento) {
              case 'ENTRADA':
                return this.$t('entryReg.entry');
              case 'CANCELACION':
                return this.$t('entryReg.cancellation');
              default:
                return ''
            }
          },
        },
        {
          field: "detalleMovimiento",
          fn: (data) => {
            switch (data.tipoMovimiento) {
              case 'ENTRADA':
                return this.$t('entryReg.entryDetail');
              default:
                return data.detalleMovimiento
            }
          },
        },
        "hechoPor",
      ],
    };
  },
  computed: {
    showData() {
      return this.ticketFound || this.registryFound;
    },
    tableData() {
      if (this.showData) {
        if (this.ticketFound) {
          return this.ticketFound.ticketLogModel;
        } else if (this.registryFound) {
          return this.registryFound.registryLogModel;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
  },
};
</script>
<style scoped>
h3 {
  text-align: center;
  margin-top: 1.5em;
}

#card-container {
  padding: 1.2em;
  margin: 1em;
}

#no-content-found {
  margin: 0 auto;
  margin-top: 10px;
}
</style>