<template>
  <h3> {{ $t('reports.ticketsTitle') }}</h3>
  <ui-grid>
    <ui-grid-cell columns="2">
      <ui-form-field>
        <ui-checkbox v-model="filtrarPorEvento" input-id="checkbox"></ui-checkbox>
        <label for="checkbox">{{ $t('reports.filterEvent') }}</label>
      </ui-form-field>
    </ui-grid-cell>
    <ui-grid-cell columns="2">
      <ui-textfield outlined fullwidth v-model="criterioBusquedaEvento" :disabled="!filtrarPorEvento"
        @keyup.enter="buscar">{{ $t('commonElements.event') }}</ui-textfield>
    </ui-grid-cell>
    <ui-grid-cell columns="2">
      <ui-form-field>
        <ui-checkbox v-model="filtrarPorTipo" input-id="checkboxT"></ui-checkbox>
        <label for="checkboxT">{{ $t('reports.filterTicket') }}</label>
      </ui-form-field>
    </ui-grid-cell>
    <ui-grid-cell columns="2">
      <ui-textfield outlined fullwidth v-model="criterioBusquedaTipo" :disabled="!filtrarPorTipo" @keyup.enter="buscar">
        {{ $t('commonElements.ticket') }}</ui-textfield>
    </ui-grid-cell>
    <ui-grid-cell columns="1">
      <ui-icon-button icon="search" @click="buscar"> </ui-icon-button>
    </ui-grid-cell>
    <ui-grid-cell columns="1">
      <ui-icon-button icon="undo" @click="undo"> </ui-icon-button>
    </ui-grid-cell>
    <ui-grid-cell columns="1">
      <ui-icon-button icon="refresh" @click="updateTickets"> </ui-icon-button>
    </ui-grid-cell>
    <ui-grid-cell columns="1">
      <ui-icon-button icon="sim_card_download" @click="downloadReport"> </ui-icon-button>
    </ui-grid-cell>
  </ui-grid>
  <ui-progress v-show="fileDownloading" indeterminate active></ui-progress>
  <error-component v-show="errorLoad" :message="errorMessage" />
  <ui-table fullwidth :data="filteredTickets" :thead="thead" :tbody="tbody" :tfoot="tfoot">
  </ui-table>
</template>
<script>
import { helpers } from "balm-ui";
import { useMainStore } from '@/stores/main';
import { mapState } from 'pinia';
import PaymentsAPI from "@/api/PaymentsAPI";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import formatters from "@/utils/formatters";

export default {
  name: "TablaReporteBoletos",
  components: {
    ErrorComponent
  },
  props: {
    ticketsReport: Array,
  },
  emits: ["refreshReportTickets"],
  computed: {
    ...mapState(useMainStore, ['userLogged']),
  },
  data() {
    return {
      thead: [
        this.$t('reports.eventName'),
        this.$t('reports.ticketTypeQty'),
        {
          value: this.$t('commonElements.netMXN'),
          align: "right",
          sort: "none",
          columnId: "totalSold",
        },
      ],
      tbody: [
        "eventName",
        "ticketType",
        {
          field: "totalSold",
          fn: (data) => {
            return formatters.formatCurrency(data.totalSold);
          },
          align: "right",
        },
      ],
      tfoot: [
        null,
        null,
        {
          field: "totalSold",
          fnName: "sum",
          fn: (data) => {
            return formatters.formatCurrency(data);
          },
          align: "right",
        },
      ],
      filtrarPorEvento: false,
      filtrarPorTipo: false,
      filteredTickets: [...this.ticketsReport],
      criterioBusquedaEvento: "",
      criterioBusquedaTipo: "",
      errorLoad: false,
      fileDownloading: false,
      errorMessage: ""
    };
  },
  methods: {
    updateTickets() {
      this.$emit("refreshReportTickets", "boletos");
    },
    undo() {
      this.filteredTickets = [...this.ticketsReport];
      this.criterioBusquedaEvento = "";
      this.criterioBusquedaTipo = "";
      this.filtrarPorEvento = false;
      this.filtrarPorTipo = false;
      this.page = 1;
    },
    buscar() {
      if (this.filtrarPorEvento && this.filtrarPorTipo) {
        if (
          !helpers.isEmpty(
            this.criterioBusquedaEvento.trim() &&
            !helpers.isEmpty(this.criterioBusquedaTipo.trim())
          )
        ) {
          this.filteredTickets = [...this.ticketsReport].filter(
            (report) =>
              report.eventName.toLowerCase().includes(this.criterioBusquedaEvento.toLowerCase()) &&
              report.ticketType.toLowerCase().includes(this.criterioBusquedaTipo.toLowerCase())
          );
        }
      } else if (
        this.filtrarPorEvento &&
        !helpers.isEmpty(this.criterioBusquedaEvento.trim())
      ) {
        this.filteredTickets = [...this.ticketsReport].filter((report) =>
          report.eventName.toLowerCase().includes(this.criterioBusquedaEvento.toLowerCase())
        );
      } else if (
        this.filtrarPorTipo &&
        !helpers.isEmpty(this.criterioBusquedaTipo.trim())
      ) {
        this.filteredTickets = [...this.ticketsReport].filter((report) =>
          report.ticketType.toLowerCase().includes(this.criterioBusquedaTipo.toLowerCase())
        );
      }
    },
    async downloadReport() {
      try {

        this.fileDownloading = true;
        let fileName = new Date().toLocaleDateString() + ".xlsx"

        const paymentsApi = new PaymentsAPI();

        const fileData = await paymentsApi.downloadTicketsReport({
          token: this.userLogged.token,
        });
        const link = document.createElement('a')
        link.href = URL.createObjectURL(fileData)
        link.target = "_blank"
        link.download = fileName
        link.click()
        URL.revokeObjectURL(link.href)
        this.fileDownloading = false;

      } catch (error) {
        this.errorLoad = true;
        this.fileDownloading = false;
        this.errorMessage = error;
      }
    }
  },
};
</script>
<style scoped>
h3 {
  text-align: center;
  margin-top: 2em;
}
</style>