import { defineStore } from 'pinia'
import { LS_KEYS } from "@/utils/constants";
import { helpers } from 'balm-ui';
import UserAPI from '@/api/UserAPI.js';
import EventsAPI from '@/api/EventsAPI';
import { selectedLocale } from '@/i18n'

export const useMainStore = defineStore('main', {
  state: () => {
    return {
      userLogged: JSON.parse(localStorage.getItem(LS_KEYS.USER_LOGIN_KEY)),
      pageTitle: "",
      selectedEventId: null,
      selectedClassificationId: null,
      selectedType: "none",
      ownerEvents: [],
      writableEvent: null,
      accountType: null,
      defaultLanguage: selectedLocale
    }
  },
  actions: {

    updateDefaultLanguage(newLanguage) {
      localStorage.setItem(LS_KEYS.USER_DEFAULT_LOCALE, newLanguage);
      this.defaultLanguage = newLanguage
    },

    async login(payload) {

      const userAPI = new UserAPI();
      const userData = await userAPI.login(payload)
      this.userLogged = userData;

    },
    logout() {

      const userAPI = new UserAPI();
      userAPI.logout();
      this.userLogged = null;
      this.selectedEventId = null;
      this.selectedClassificationId = null;
      this.selectedType = "none";
      this.accountType = null;
    },

    async updateUser(payload) {
      const userAPI = new UserAPI();
      const userData = await userAPI.updateUserData(payload);
      this.userLogged = userData;
    },

    async refreshOwnerEvents() {

      //console.log('Refreshing events...')
      const eventsAPI = new EventsAPI();
      const ownerStaffData = await eventsAPI.getEvents({
        sessionType: this.userLogged.sessionType,
        token: this.userLogged.token
      });

      this.ownerEvents = (helpers.isEmpty(ownerStaffData) || helpers.isEmpty(ownerStaffData.events)) ? [] : [...ownerStaffData.events]
      this.accountType = helpers.isEmpty(ownerStaffData) ? null : ownerStaffData.tipoCuenta

      if (!helpers.isEmpty(this.ownerEvents)) {

        if (helpers.isEmpty(this.selectedEventId) || helpers.isEmpty(this.writableEvent)) {
          const defaultEvent = this.ownerEvents.at(0);
          this.selectedEventId = defaultEvent.id
          this.writableEvent = defaultEvent
          this.selectedClassificationId = null;
          //console.log(this.selectedEventId)
        }
      }

    },

    async getSelectedEvent() {

      const eventsAPI = new EventsAPI();
      this.writableEvent = await eventsAPI.getEventById({
        eventId: this.selectedEventId,
        token: this.userLogged.token
      })
    },

    async createEvent(eventData) {
      const eventsAPI = new EventsAPI();
      await eventsAPI.createEvent(
        this.userLogged.token,
        eventData)

      // We don't clear the selectedEventId nor writableEvent due to they are cleared when showing the Create Event page

      await this.refreshOwnerEvents();
    },

    async updateEvent(eventData) {
      const eventsAPI = new EventsAPI();
      this.writableEvent = await eventsAPI.updateEvent(
        this.userLogged.token,
        eventData)
      this.selectedEventId = this.writableEvent.id
      this.selectedClassificationId = null;
    },

    async deleteSelectedEvent() {
      const eventsAPI = new EventsAPI();
      await eventsAPI.deleteEvent(
        this.userLogged.token,
        this.selectedEventId)

      this.selectedEventId = null
      this.writableEvent = null
      this.selectedClassificationId = null;

      await this.refreshOwnerEvents();
    },

    async requestPublish() {
      const eventsAPI = new EventsAPI();
      await eventsAPI.requestPublish(
        this.userLogged.token,
        this.selectedEventId
      )
    },

    async updateTicketsOrder() {
      const eventsAPI = new EventsAPI();
      const updatesTicketsData = await eventsAPI.updateTicketsOrder(this.userLogged.token, {
        eventId: this.selectedEventId,
        tickets: this.writableEvent.tickets
      })
      this.writableEvent.tickets = [...updatesTicketsData];
    },

    async createTicket(ticketData) {
      const eventsAPI = new EventsAPI();
      const newTicketData = await eventsAPI.createTicket(this.userLogged.token, this.selectedEventId, ticketData);
      this.writableEvent.tickets.push({ ...newTicketData })
    },

    async updateTicket(ticketData) {
      const eventsAPI = new EventsAPI();
      const updatedTicketData = await eventsAPI.updateTicket(this.userLogged.token, this.selectedEventId, ticketData);
      this.writableEvent.tickets = this.writableEvent.tickets.map(ticket => ticket.ticketId === updatedTicketData.ticketId ? { ...updatedTicketData } : ticket)
    },

    async activateTicket(ticketId) {
      const eventsAPI = new EventsAPI();
      const updatedTicketData = await eventsAPI.activateTicket(this.userLogged.token, this.selectedEventId, ticketId);
      this.writableEvent.tickets = this.writableEvent.tickets.map(ticket => ticket.ticketId === updatedTicketData.ticketId ? { ...updatedTicketData } : ticket)
    },

    async deactivateTicket(ticketId) {
      const eventsAPI = new EventsAPI();
      const updatedTicketData = await eventsAPI.deactivateTicket(this.userLogged.token, this.selectedEventId, ticketId);
      this.writableEvent.tickets = this.writableEvent.tickets.map(ticket => ticket.ticketId === updatedTicketData.ticketId ? { ...updatedTicketData } : ticket)
    },

    async deleteTicket(ticketId) {
      const eventsAPI = new EventsAPI();
      await eventsAPI.deleteTicket(this.userLogged.token, this.selectedEventId, ticketId);
      this.writableEvent.tickets = this.writableEvent.tickets.filter(ticket => ticket.ticketId !== ticketId)
    },

    async updateRegistrationOrder() {
      const eventsAPI = new EventsAPI();
      const updatedRegistrationData = await eventsAPI.updateRegistrationOrder(this.userLogged.token, {
        eventId: this.selectedEventId,
        registerTypes: this.writableEvent.registerTypes
      })
      this.writableEvent.registerTypes = [...updatedRegistrationData];
    },

    async createRegisterType(registerTypeData) {
      const eventsAPI = new EventsAPI();
      const newRegisterTypeData = await eventsAPI.createRegisterType(this.userLogged.token, this.selectedEventId, registerTypeData);
      this.writableEvent.registerTypes.push({ ...newRegisterTypeData })
    },

    async updateRegisterType(registerTypeData) {
      const eventsAPI = new EventsAPI();
      const updatedRegisterTypeData = await eventsAPI.updateRegisterType(this.userLogged.token, this.selectedEventId, registerTypeData);
      this.writableEvent.registerTypes = this.writableEvent.registerTypes.map(registerType => registerType.registerTypeId === updatedRegisterTypeData.registerTypeId ? { ...updatedRegisterTypeData } : registerType)
    },

    async activateRegisterType(registerTypeId) {
      const eventsAPI = new EventsAPI();
      const updatedRegisterTypeData = await eventsAPI.activateRegisterType(this.userLogged.token, this.selectedEventId, registerTypeId);
      this.writableEvent.registerTypes = this.writableEvent.registerTypes.map(registerType => registerType.registerTypeId === updatedRegisterTypeData.registerTypeId ? { ...updatedRegisterTypeData } : registerType)
    },

    async deactivateRegisterType(registerTypeId) {
      const eventsAPI = new EventsAPI();
      const updatedRegisterTypeData = await eventsAPI.deactivateRegisterType(this.userLogged.token, this.selectedEventId, registerTypeId);
      this.writableEvent.registerTypes = this.writableEvent.registerTypes.map(registerType => registerType.registerTypeId === updatedRegisterTypeData.registerTypeId ? { ...updatedRegisterTypeData } : registerType)
    },

    async deleteRegisterType(registerTypeId) {
      const eventsAPI = new EventsAPI();
      await eventsAPI.deleteRegisterType(this.userLogged.token, this.selectedEventId, registerTypeId);
      this.writableEvent.registerTypes = this.writableEvent.registerTypes.filter(registerType => registerType.registerTypeId !== registerTypeId)
    },
  }
})
