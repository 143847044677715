<template>
  <h3> {{ $t('entryReg.registerEntranceTitle') }}</h3>
  <ui-card id="card-container" v-shadow.transition="[2, 8]">
    <ui-form type="|" item-margin-bottom="10" action-align="right">
      <template #default="{ subitemClass, actionClass }">
        <ui-form-field style="margin-top: 20px">
          <label> {{ $t('entryReg.typeEntrySelect') }}</label>
          <div :class="subitemClass">
            <ui-form-field>
              <ui-radio v-model="formData.tipoEntrada" value="boleto" input-id="boleto" :disabled="formLoading" />
              <ui-icon outlined size="18">confirmation_number</ui-icon>
              <label for="boleto"> {{ $t('commonElements.ticket') }}</label>
            </ui-form-field>
            <ui-form-field>
              <ui-radio v-model="formData.tipoEntrada" value="registro" input-id="registro" :disabled="formLoading" />
              <ui-icon outlined size="18">request_quote</ui-icon>
              <label for="registro">{{ $t('commonElements.registries') }}</label>
            </ui-form-field>
          </div>
        </ui-form-field>
        <ui-form-field style="margin-top: 10px">
          <label> {{ $t('entryReg.searchSelect') }}</label>
          <div :class="subitemClass">
            <ui-form-field>
              <ui-radio v-model="formData.submissionType" value="qr" :disabled="formLoading" />
              <label>{{ $t('entryReg.qr') }}</label>
            </ui-form-field>
            <ui-form-field>
              <ui-radio v-model="formData.submissionType" value="manual" :disabled="formLoading" />
              <label>{{ $t('filters.sn') }}</label>
            </ui-form-field>
          </div>
        </ui-form-field>
        <template v-if="formData.submissionType === 'qr'">
          <ui-form-field :class="subitemClass">
            <ui-select style="width: 300px;" outlined v-model="selectedInputType" :options="inputOptions">
              {{ $t('entryReg.selectInput') }}
            </ui-select>
          </ui-form-field>
          <ui-form-field v-if="selectedInputType === 'camera'" :class="subitemClass">
            <label v-show="loadingCamera"> {{ $t('entryReg.loadingCamera') }}</label>
            <ui-progress v-show="loadingCamera" indeterminate active></ui-progress>
            <stream-barcode-reader @decode="onDecodeFromCamera" @loaded="onCameraLoaded"></stream-barcode-reader>
          </ui-form-field>
        </template>
        <ui-form-field :class="subitemClass" v-show="formData.submissionType === 'qr'">
          <ui-textfield outlined fullwidth v-model="formData.qrCode" helper-text-id="qr-helper-text"
            :disabled="formLoading" @keyup.enter="buscar">
            {{ $t('entryReg.qr') }}
            <template #before>
              <ui-textfield-icon>qr_code</ui-textfield-icon>
            </template>
          </ui-textfield>
          <ui-textfield-helper id="qr-helper-text" v-model:validMsg="validMsg.qrCode"></ui-textfield-helper>
        </ui-form-field>
        <!-- Boleto Manual -->
        <ui-form-field :class="subitemClass" v-show="formData.tipoEntrada === 'boleto' &&
          formData.submissionType === 'manual'
          ">
          <ui-textfield outlined fullwidth v-model="formData.ticketId" helper-text-id="ticket-id-helper-text"
            :disabled="formLoading" @keyup.enter="buscar">
            {{ $t('filters.sn') }}
            <template #before>
              <ui-textfield-icon>tag</ui-textfield-icon>
            </template>
          </ui-textfield>
          <ui-textfield-helper id="ticket-id-helper-text" v-model:validMsg="validMsg.ticketId"></ui-textfield-helper>
        </ui-form-field>
        <!-- Registro Manual -->
        <ui-form-field :class="subitemClass" v-show="formData.tipoEntrada === 'registro' &&
          formData.submissionType === 'manual'
          ">
          <ui-textfield outlined fullwidth v-model="formData.registryId" helper-text-id="registry-id-helper-text"
            :disabled="formLoading" @keyup.enter="buscar">
            {{ $t('filters.sn') }}
            <template #before>
              <ui-textfield-icon>tag</ui-textfield-icon>
            </template>
          </ui-textfield>
          <ui-textfield-helper id="registry-id-helper-text" v-model:validMsg="validMsg.registryId">
          </ui-textfield-helper>
        </ui-form-field>
        <!-- Botones -->
        <ui-form-field class="buttons" :class="actionClass">
          <ui-button outlined native-type="reset" :disabled="formLoading" @click="limpiar"> {{ $t('actions.clear') }}
            <template #after="{ iconClass }">
              <ui-icon :class="iconClass">delete_outline</ui-icon>
            </template>
          </ui-button>
          <ui-button raised native-type="submit" @click.prevent="buscar" @keyup.enter="buscar" :disabled="formLoading">
            {{ $t('actions.search') }}
            <template #after="{ iconClass }">
              <ui-icon :class="iconClass">search</ui-icon>
            </template>
          </ui-button>
        </ui-form-field>
      </template>
    </ui-form>
    <ui-progress v-show="formLoading" indeterminate active></ui-progress>
    <error-component v-show="errorRegistrar" :message="errorMessage" />
  </ui-card>
</template>
<script>
import { useValidator, helpers } from "balm-ui";
import { StreamBarcodeReader } from "vue-barcode-reader";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import EventsAPI from "@/api/EventsAPI.js";
import { useMainStore } from '@/stores/main';
import { mapState } from 'pinia';
import i18n from "@/i18n";

const validations = [
  {
    key: "qrCode",
    label: i18n.global.t('entryReg.qr'),
    validator: "requiredIfEnabled",
    requiredIfEnabled: {
      validate(value, data) {
        if (data.submissionType === "qr" && helpers.isEmpty(value))
          return false;
        return true;
      },
      message: "%s " + i18n.global.t('validations.required'),
    },
  },
  {
    key: "ticketId",
    label: i18n.global.t('filters.sn'),
    validator: "requiredIfEnabled",
    requiredIfEnabled: {
      validate(value, data) {
        if (
          data.submissionType === "manual" &&
          data.tipoEntrada === "boleto" &&
          helpers.isEmpty(value)
        )
          return false;
        return true;
      },
      message: "%s " + i18n.global.t('validations.required'),
    },
  },
  {
    key: "registryId",
    label: i18n.global.t('filters.sn'),
    validator: "requiredIfEnabled",
    requiredIfEnabled: {
      validate(value, data) {
        if (
          data.submissionType === "manual" &&
          data.tipoEntrada === "registro" &&
          helpers.isEmpty(value)
        )
          return false;
        return true;
      },
      message: "%s " + i18n.global.t('validations.required'),
    },
  },
];

export default {
  name: "RegistrarEntradaForm",
  components: {
    ErrorComponent,
    StreamBarcodeReader
  },
  emits: ["ticketFound", "registryFound"],
  data() {
    return {
      selectedInputType: "reader",
      inputOptions: [
        {
          label: this.$t('entryReg.reader'),
          value: "reader",
        },
        {
          label: this.$t('entryReg.camera'),
          value: "camera",
        },
      ],
      loadingCamera: false,
      balmUI: useValidator(),
      validations,
      validMsg: {},
      formData: {
        tipoEntrada: "boleto",
        submissionType: "qr",
        qrCode: "",
        ticketId: "",
        registryId: "",
      },
      formLoading: false,
      errorRegistrar: false,
      errorMessage: "",
    };
  },
  watch: {
    selectedInputType(newValue) {
      if (newValue === 'camera') {
        this.loadingCamera = true
      }
    },
    'formData.submissionType'(newValue) {
      if (newValue === 'qr' && this.selectedInputType === 'camera') {
        this.loadingCamera = true
      }
    }
  },
  computed: {
    ...mapState(useMainStore, ["userLogged", "selectedEventId"]),
  },
  methods: {
    onCameraLoaded() {
      this.loadingCamera = false
    },
    async onDecodeFromCamera(result) {
      this.formData.qrCode = result
      await this.buscar();
    },
    limpiar() {
      this.formData.qrCode = "";
      this.formData.ticketId = "";
      this.formData.registryId = "";
      this.$emit("registryFound", null);
      this.$emit("ticketFound", null);
    },
    async buscar() {
      let result = this.balmUI.validate(this.formData);
      let { valid, validMsg } = result;
      this.validMsg = validMsg;

      if (valid) {
        this.formLoading = true;
        this.errorRegistrar = false;
        const eventsAPI = new EventsAPI();

        try {
          if (
            this.selectedEventId &&
            this.formData.tipoEntrada === "boleto" &&
            this.formData.submissionType === "qr"
          ) {
            const ticketData = await eventsAPI.searchTicketQR({
              token: this.userLogged.token,
              qrString: this.formData.qrCode,
              eventId: this.selectedEventId,
            });
            //console.log(ticketData);
            this.$emit("registryFound", null);
            this.$emit("ticketFound", ticketData);
          } else if (
            this.selectedEventId &&
            this.formData.tipoEntrada === "boleto" &&
            this.formData.submissionType === "manual"
          ) {
            const ticketData = await eventsAPI.searchTicketManual({
              token: this.userLogged.token,
              ticketPurchasedId: this.formData.ticketId,
              eventId: this.selectedEventId,
            });
            //console.log(ticketData);
            this.$emit("registryFound", null);
            this.$emit("ticketFound", ticketData);
          } else if (
            this.selectedEventId &&
            this.formData.tipoEntrada === "registro" &&
            this.formData.submissionType === "qr"
          ) {
            const registryData = await eventsAPI.searchRegistroQR({
              token: this.userLogged.token,
              qrString: this.formData.qrCode,
              eventId: this.selectedEventId,
            });
            //console.log(registryData);
            this.$emit("ticketFound", null);
            this.$emit("registryFound", registryData);
          } else if (
            this.selectedEventId &&
            this.formData.tipoEntrada === "registro" &&
            this.formData.submissionType === "manual"
          ) {
            const registryData = await eventsAPI.searchRegistroManual({
              token: this.userLogged.token,
              registryId: this.formData.registryId,
              eventId: this.selectedEventId,
            });
            //console.log(registryData);
            this.$emit("ticketFound", null);
            this.$emit("registryFound", registryData);
          }

          this.formLoading = false;
        } catch (error) {
          this.formLoading = false;
          this.errorMessage = error;
          this.errorRegistrar = true;
          this.$emit("ticketFound", null);
          this.$emit("registryFound", null);
        }
      }
    },
  },
};
</script>
<style scoped>
h3 {
  text-align: center;
  margin-top: 1.5em;
}

#card-container {
  padding: 1.2em;
  margin: 1em;
  min-height: 455px;
}

.buttons {
  margin-top: 50px;
}
</style>