<template>
  <h2>{{ $t('subtitles.profile') }}</h2>
  <user-data />
</template>
<script>
import UserData from "@/components/user/UserData.vue";
import i18n from "@/i18n";
import { useMainStore } from '@/stores/main';

export default {
  name: "PerfilUsuario",
  components: {
    UserData,
  },
  created() {
    const mainStore = useMainStore()
    mainStore.$patch({ pageTitle: i18n.global.t('titles.profile') })
  },
};
</script>

<style scoped>
h2,
h5 {
  text-align: center;
  margin-top: 2em;
}
</style>