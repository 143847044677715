<template>
  <h3> {{ $t('purchases.listOfTicketsPurchased') }}</h3>
  <ui-grid>
    <ui-grid-cell columns="3">
      <ui-select outlined fullwidth :options="filtros" v-model="filtroSeleccionado">{{ $t('filters.filterBy')
      }}</ui-select>
    </ui-grid-cell>
    <ui-grid-cell columns="4" v-show="filtroSeleccionado !== 'estatus'">
      <ui-textfield outlined fullwidth v-model="criterioBusqueda" @keyup.enter="buscar">{{ $t('filters.searchBy') }}
      </ui-textfield>
    </ui-grid-cell>
    <ui-grid-cell columns="3" v-show="filtroSeleccionado === 'estatus'">
      <ui-form-field>
        <ui-checkbox v-model="ingresado" input-id="checkbox"></ui-checkbox>
        <label for="checkbox">{{ $t('purchases.hasEntered') }}</label>
      </ui-form-field>
    </ui-grid-cell>
    <ui-grid-cell columns="1">
      <ui-icon-button icon="search" @click="buscar"> </ui-icon-button>
    </ui-grid-cell>
    <ui-grid-cell columns="1">
      <ui-icon-button icon="undo" @click="undo"> </ui-icon-button>
    </ui-grid-cell>
    <ui-grid-cell columns="1">
      <ui-icon-button icon="refresh" @click="updateTickets"> </ui-icon-button>
    </ui-grid-cell>
    <ui-grid-cell columns="1" v-show="isOwner">
      <ui-icon-button icon="sim_card_download" @click="downloadReport"> </ui-icon-button>
    </ui-grid-cell>
  </ui-grid>
  <ui-progress v-show="fileDownloading" indeterminate active></ui-progress>
  <error-component v-show="errorLoad" :message="errorMessage" />
  <ui-table fullwidth :data="paginatedTicketsPurchased" :thead="thead" :tbody="tbody" selected-key="ticketId">
    <template #actions="{ data }">
      <ui-icon-button icon="checklist" @click="showLog(data)"></ui-icon-button>
    </template>
    <ui-pagination v-model="page" :page-size="itemsPerPage" :total="total" show-total></ui-pagination>
  </ui-table>
</template>
<script>
import { helpers } from "balm-ui";
import { useMainStore } from '@/stores/main';
import { mapState } from 'pinia';
import PaymentsAPI from "@/api/PaymentsAPI";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import i18n from "@/i18n";

const tiposFiltro = [
  {
    label: i18n.global.t('filters.sn'),
    value: "folio",
  },
  {
    label: i18n.global.t('filters.ticketType'),
    value: "tipo",
  },
  {
    label: i18n.global.t('filters.attendee'),
    value: "nombre",
  },
  {
    label: i18n.global.t('filters.groupCompany'),
    value: "grupo",
  },
  {
    label: i18n.global.t('filters.status'),
    value: "estatus",
  },
  {
    label: i18n.global.t('filters.orderNumber'),
    value: "orden",
  },
  {
    label: i18n.global.t('filters.tdUser'),
    value: "userId",
  },
];
export default {
  name: "TablaBoletosComprados",
  components: {
    ErrorComponent
  },
  props: {
    ticketsPurchased: Array,
  },
  emits: ["refreshTickets", "selectedTicketId"],
  data() {
    return {
      thead: [
        this.$t('headers.purchaseDate'),
        this.$t('filters.sn'),
        this.$t('filters.ticketType'),
        this.$t('filters.attendee'),
        this.$t('filters.groupCompany'),
        this.$t('purchases.hasEntered'),
        this.$t('headers.log'),
        this.$t('headers.orderNumber'),
        this.$t('filters.tdUser'),
      ],
      tbody: [
        "ticketPurchasedDate",
        "ticketId",
        "ticketType",
        "ticketName",
        "companyGroup",
        {
          field: "hasBeenRegistered",
          fn: (data) => {
            return data.hasBeenRegistered ? this.$t('commonElements.yes') : this.$t('commonElements.no');
          },
        },
        {
          slot: "actions",
        },
        "paymentId",
        "userId",
      ],
      page: 1,
      itemsPerPage: 20,
      filtros: tiposFiltro,
      filtroSeleccionado: "folio",
      ingresado: false,
      filteredTicketsPurchased: [...this.ticketsPurchased],
      criterioBusqueda: "",
      errorLoad: false,
      fileDownloading: false,
      errorMessage: ""
    };
  },
  computed: {
    ...mapState(useMainStore, ['userLogged', 'selectedEventId']),
    paginatedTicketsPurchased() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      //console.log("start: " + start + " end: " + end);
      return this.filteredTicketsPurchased.slice(start, end);
    },
    total() {
      return this.filteredTicketsPurchased.length;
    },
    isOwner() {
      return this.userLogged && this.userLogged.sessionType === "OWNER";
    }
  },
  methods: {
    showLog(ticketSelected) {
      this.$emit("selectedTicketId", ticketSelected.ticketId);
    },
    updateTickets() {
      this.$emit("refreshTickets", "boletos");
    },
    undo() {
      this.filteredTicketsPurchased = [...this.ticketsPurchased];
      this.criterioBusqueda = "";
      this.page = 1;
    },
    buscar() {
      if (this.filtroSeleccionado === "estatus") {
        this.filteredTicketsPurchased = [...this.ticketsPurchased].filter(
          (ticketPurchased) =>
            ticketPurchased.hasBeenRegistered === this.ingresado
        );
        this.page = 1;
      } else if (!helpers.isEmpty(this.criterioBusqueda.trim())) {
        var criterioBusquedaLowerCase = this.criterioBusqueda.trim().toLowerCase();
        switch (this.filtroSeleccionado) {
          case "folio":
            this.filteredTicketsPurchased = [...this.ticketsPurchased].filter(
              (ticketPurchased) =>
                ticketPurchased.ticketId.toLowerCase().includes(criterioBusquedaLowerCase)
            );
            break;
          case "tipo":
            this.filteredTicketsPurchased = [...this.ticketsPurchased].filter(
              (ticketPurchased) =>
                ticketPurchased.ticketType.toLowerCase().includes(criterioBusquedaLowerCase)
            );
            break;
          case "nombre":
            this.filteredTicketsPurchased = [...this.ticketsPurchased].filter(
              (ticketPurchased) =>
                !helpers.isEmpty(ticketPurchased.ticketName) &&
                ticketPurchased.ticketName.toLowerCase().includes(criterioBusquedaLowerCase)
            );
            break;
          case "orden":
            this.filteredTicketsPurchased = [...this.ticketsPurchased].filter(
              (ticketPurchased) =>
                ticketPurchased.paymentId.toLowerCase().includes(criterioBusquedaLowerCase)
            );
            break;
          case "grupo":
            this.filteredTicketsPurchased = [...this.ticketsPurchased].filter(
              (ticketPurchased) =>
                !helpers.isEmpty(ticketPurchased.companyGroup) &&
                ticketPurchased.companyGroup.toLowerCase().includes(criterioBusquedaLowerCase)
            );
            break;
          case "userId":
            this.filteredTicketsPurchased = [...this.ticketsPurchased].filter(
              (ticketPurchased) =>
                ticketPurchased.userId.toLowerCase().includes(criterioBusquedaLowerCase)
            );
            break;
        }
        this.page = 1;
      }
    },
    async downloadReport() {
      try {

        this.fileDownloading = true;
        let fileName = "reporte_boletos_comprados_" + new Date().toLocaleDateString() + ".xlsx"

        const paymentsApi = new PaymentsAPI();

        const fileData = await paymentsApi.downloadTicketsPurchasedReport({
          token: this.userLogged.token,
          eventId: this.selectedEventId
        });
        const link = document.createElement('a')
        link.href = URL.createObjectURL(fileData)
        link.target = "_blank"
        link.download = fileName
        link.click()
        URL.revokeObjectURL(link.href)
        this.fileDownloading = false;

      } catch (error) {
        this.errorLoad = true;
        this.fileDownloading = false;
        this.errorMessage = error;
      }
    }
  },
};
</script>
<style scoped>
h3 {
  text-align: center;
  margin-top: 2em;
}
</style>