<template>
    <div class="info-container">
        <ui-grid>
            <ui-grid-cell columns="11">
                <h3 :class="publishedStyle"> {{ eventPublishedText }}</h3>
            </ui-grid-cell>
            <ui-grid-cell columns="1">
                <ui-icon size="48" :class="publishedStyle"> {{ publishedIcon }}</ui-icon>
            </ui-grid-cell>
            <ui-grid-cell columns="4" align="middle">
                <h4>{{ $t('myEvents.dateField') }}</h4>
            </ui-grid-cell>
            <ui-grid-cell columns="2" align="middle">
                <label>{{ $t('myEvents.startDate') }}</label>
            </ui-grid-cell>
            <ui-grid-cell columns="2">
                <input type="datetime-local" class="input-td" :disabled="eventPublished"
                    v-model="eventData.eventStartDate" />
            </ui-grid-cell>
            <ui-grid-cell columns="2" align="middle">
                <label>{{ $t('myEvents.endDate') }}</label>
            </ui-grid-cell>
            <ui-grid-cell columns="2">
                <input type="datetime-local" class="input-td" :disabled="eventPublished" v-model="eventData.eventEndDate" />
            </ui-grid-cell>
            <ui-grid-cell columns="12">
                <ui-textfield v-model="eventData.eventId" :disabled="!isNewEvent" with-counter fullwidth maxlength="90"
                    required>
                    {{ $t('myEvents.linkField') }}
                </ui-textfield>
            </ui-grid-cell>
            <ui-grid-cell columns="11">
                <ui-textfield v-model="eventData.eventName" :disabled="!activeName" with-counter fullwidth maxlength="99"
                    required>
                    {{ $t('myEvents.eventName') }}
                </ui-textfield>
            </ui-grid-cell>
            <ui-grid-cell columns="1" align="middle">
                <ui-button v-show="!activeName" @click="toggleEventName">{{ $t('actions.edit') }}</ui-button>
                <ui-button v-show="activeName" @click="toggleEventName">{{ $t('actions.ok') }}</ui-button>
            </ui-grid-cell>
            <ui-grid-cell columns="11">
                <ui-textfield v-model="eventData.eventVideo" :disabled="!activeVideo" with-counter fullwidth
                    maxlength="200">
                    {{ $t('myEvents.youtubeLink') }}
                </ui-textfield>
            </ui-grid-cell>
            <ui-grid-cell columns="1" align="middle">
                <ui-button v-show="!activeVideo" @click="toggleEventVideo">{{ $t('actions.edit') }}</ui-button>
                <ui-button v-show="activeVideo" @click="toggleEventVideo">{{ $t('actions.ok') }}</ui-button>
            </ui-grid-cell>
            <ui-grid-cell columns="3" align="middle">
                <h4>{{ $t('myEvents.eventDescription') }}</h4>
            </ui-grid-cell>
            <ui-grid-cell columns="9" align="middle">
                <ui-button v-show="!activeDescription" @click="toggleEventDescription">{{ $t('actions.edit') }}</ui-button>
                <ui-button v-show="activeDescription" @click="toggleEventDescription">{{ $t('actions.ok') }}</ui-button>
            </ui-grid-cell>
            <ui-grid-cell columns="12">
                <ui-editor v-if="activeDescription" v-model="eventData.eventDescription" fullwidth with-counter></ui-editor>
                <label v-else v-html="eventData.eventDescription"></label>
            </ui-grid-cell>
            <ui-grid-cell columns="3" align="middle">
                <h4>{{ $t('myEvents.eventDescriptionEn') }}</h4>
            </ui-grid-cell>
            <ui-grid-cell columns="9" align="middle">
                <ui-button v-show="!activeDescriptionEn" @click="toggleEventDescriptionEn">{{ $t('actions.edit')
                }}</ui-button>
                <ui-button v-show="activeDescriptionEn" @click="toggleEventDescriptionEn">{{ $t('actions.ok')
                }}</ui-button>
            </ui-grid-cell>
            <ui-grid-cell columns="12">
                <ui-editor v-if="activeDescriptionEn" v-model="eventData.eventDescriptionEn" fullwidth
                    with-counter></ui-editor>
                <label v-else v-html="eventData.eventDescriptionEn"></label>
            </ui-grid-cell>
            <ui-grid-cell columns="1" align="middle">
                <ui-icon size="36">place</ui-icon>
            </ui-grid-cell>
            <ui-grid-cell columns="1" align="middle">
                <h4> {{ $t('myEvents.location') }}</h4>
            </ui-grid-cell>
            <ui-grid-cell columns="6" align="middle">
                <div v-show="!activeLocation">
                    <label id="locationName">{{ locationName }}</label>
                    <br>
                    <label id="locationAddress">{{ locationAddress }}</label>
                </div>
                <input v-show="activeLocation" id="autocomplete" type="text" class="input-td"
                    :placeholder="this.$t('myEvents.locationField')" />
            </ui-grid-cell>
            <ui-grid-cell columns="1" align="middle">
                <ui-button v-show="!activeLocation" @click="toggleLocation">{{ $t('actions.edit') }}</ui-button>
                <ui-button v-show="activeLocation" @click="toggleLocation">{{ $t('actions.cancel') }}</ui-button>
            </ui-grid-cell>
            <ui-grid-cell columns="4" align="middle">
                <h4> {{ $t('myEvents.enableMSI') }}</h4>
            </ui-grid-cell>
            <ui-grid-cell columns="1" align="middle">
                <ui-switch v-model="eventData.msiEnabled" />
            </ui-grid-cell>
        </ui-grid>
        <ui-divider></ui-divider>
        <ui-progress class="spinner" v-show="savingEvent" indeterminate active></ui-progress>
        <error-component v-show="!savingEvent && errorEvent" :message="errorMessage" />
        <div class="buttons-content">
            <template v-if="isNewEvent">
                <ui-button class="action-button" @click="$emit('cancelEdit')">{{ $t('actions.cancel') }}</ui-button>
                <ui-button class="action-button" raised @click.prevent="creaNuevoEvento">{{ $t('actions.createEvent')
                }}</ui-button>
            </template>
            <template v-else>
                <template v-if="!eventPublished">
                    <ui-button class="action-button" @click.prevent="eliminaEvento">{{ $t('actions.delete')
                    }}</ui-button>
                    <ui-button class="action-button" outlined @click.prevent="actualizarEvento">{{ $t('actions.save')
                    }}</ui-button>
                    <ui-button class="action-button" raised @click.prevent="solicitarPublicacion">{{
                        $t('actions.requestPublish')
                    }}</ui-button>
                </template>
                <ui-button v-else class="action-button" raised @click.prevent="actualizarEvento">{{ $t('actions.save')
                }}</ui-button>
            </template>
        </div>
    </div>
</template>

<script>

import { useMainStore } from '@/stores/main';
import { mapState, mapActions } from 'pinia';
import { helpers } from 'balm-ui';
import formatters from '@/utils/formatters'
import ErrorComponent from "@/components/common/ErrorComponent.vue";

export default {
    components: {
        ErrorComponent
    },
    mounted() {
        this.configurePlacesScript();
        //console.log("mounted")
        this.prefillEventData();

    },
    unmounted() {
        this.removePlacesScript();
        //console.log("unmounted")
    },
    emits: ['cancelEdit'],
    data() {
        return {
            config: {
                mode: 'range',
                dateFormat: 'Y-m-d',
            },
            eventData: {
                eventId: '',
                eventStartDate: '',
                eventEndDate: '',
                eventName: '',
                eventVideo: '',
                eventDescription: '',
                eventDescriptionEn: '',
                eventLocation: null,
                msiEnabled: false
            },
            activeName: false,
            activeDescription: false,
            activeDescriptionEn: false,
            activeLocation: false,
            activeVideo: false,
            autocomplete: {},
            savingEvent: false,
            errorEvent: false,
            errorMessage: ''
        }
    },
    computed: {
        ...mapState(useMainStore, ['writableEvent']),
        eventPublished() {
            return this.writableEvent && this.writableEvent.published;
        },
        eventPublishedText() {
            return (this.writableEvent && this.writableEvent.published) ? this.$t('myEvents.published') : this.$t('myEvents.notPublished');
        },
        publishedIcon() {
            if (this.writableEvent && this.writableEvent.published) {
                return 'check_circle';
            }
            return 'cancel'
        },
        publishedStyle() {
            if (this.writableEvent && this.writableEvent.published) {
                return 'published';
            }
            return 'not-published'
        },
        locationName() {
            if (this.eventData.eventLocation) {
                return this.eventData.eventLocation.name
            }
            return this.$t('commonElements.tbd')
        },
        locationAddress() {
            if (this.eventData.eventLocation) {
                return this.eventData.eventLocation.address
            }
            return ''
        },
        isNewEvent() {
            return this.writableEvent === null
        }
    },
    methods: {
        ...mapActions(useMainStore, ['updateEvent', 'createEvent', 'deleteSelectedEvent', 'requestPublish']),
        prefillEventData() {
            this.eventData.eventId = this.writableEvent ? this.writableEvent.id : ''
            this.eventData.eventStartDate = this.writableEvent ? this.writableEvent.startDate : new Date()
            this.eventData.eventEndDate = this.writableEvent ? this.writableEvent.endDate : new Date()
            this.eventData.eventName = this.writableEvent ? this.writableEvent.name : ''
            this.eventData.eventVideo = (this.writableEvent && this.writableEvent.videoURL) ? `https://www.youtube.com/watch?v=${this.writableEvent.videoURL}` : ''
            this.eventData.eventDescription = this.writableEvent ? this.writableEvent.description : ''
            this.eventData.eventDescriptionEn = this.writableEvent ? this.writableEvent.descriptionEn : ''
            this.eventData.eventLocation = (this.writableEvent && this.writableEvent.location) ? this.writableEvent.location : null
            this.eventData.msiEnabled = this.writableEvent ? this.writableEvent.msiEnabled : false
            this.activeName = this.isNewEvent
            this.activeDescription = this.isNewEvent
            this.activeLocation = this.isNewEvent
            this.activeVideo = this.isNewEvent
        },
        toggleEventName() {
            this.activeName = !this.activeName
        },
        toggleEventVideo() {
            this.activeVideo = !this.activeVideo
        },
        toggleEventDescription() {
            this.activeDescription = !this.activeDescription
        },
        toggleEventDescriptionEn() {
            this.activeDescriptionEn = !this.activeDescriptionEn
        },
        toggleLocation() {
            this.activeLocation = !this.activeLocation
        },
        configurePlacesScript() {
            const placesApiScript = document.createElement("script");
            placesApiScript.setAttribute(
                "src",
                `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_API_KEY}&libraries=places&callback=initAutocomplete`
            );
            placesApiScript.async = true;
            placesApiScript.defer = true;
            document.head.appendChild(placesApiScript);

            window.initAutocomplete = this.initAutocomplete
        },
        removePlacesScript() {
            const allScripts = document.getElementsByTagName('script');
            [].filter.call(
                allScripts,
                (scpt) => scpt.src.indexOf(`key=${process.env.VUE_APP_GOOGLE_API_KEY}`) >= 0
            )[0].remove();
            window.google = {};
        },
        initAutocomplete() {

            this.autocomplete = new window.google.maps.places.Autocomplete(
                document.getElementById('autocomplete'),
                {
                    fields: ['place_id', 'geometry', 'name', 'formatted_address']
                }
            )

            this.autocomplete.addListener('place_changed', this.onPlaceChanged)
        },
        onPlaceChanged() {
            var place = this.autocomplete.getPlace();
            if (!place.geometry) {
                // User did not select a prediction; reset the input field
                document.getElementById('autocomplete').placeholder = this.$t('myEvents.locationField')
            } else {
                // Display details about the valid place
                //console.log(place.place_id)
                //console.log(place.name)
                //console.log(place.formatted_address)
                //console.log(place.geometry.location.lat())
                //console.log(place.geometry.location.lng())

                this.eventData.eventLocation = {
                    placeId: place.place_id,
                    name: place.name,
                    address: place.formatted_address,
                    latitude: place.geometry.location.lat(),
                    longitude: place.geometry.location.lng()
                }

                this.toggleLocation();
            }
        },
        validId() {
            let regex = /^[A-Za-z0-9-]+$/
            if (!regex.test(this.eventData.eventId)) {
                this.errorEvent = true
                this.errorMessage = this.$t('validations.eventLink')

                return false;
            }
            return true;
        },
        validDates() {

            //console.log(this.eventData.eventStartDate)
            //console.log(this.eventData.eventEndDate)

            if (helpers.isEmpty(this.eventData.eventStartDate) || helpers.isEmpty(this.eventData.eventEndDate)) {
                this.errorEvent = true
                this.errorMessage = this.$t('validations.allDates')

                return false;
            }

            let startDate = Date.parse(this.eventData.eventStartDate);
            let endDate = Date.parse(this.eventData.eventEndDate);

            if (startDate > endDate) {
                this.errorEvent = true
                this.errorMessage = this.$t('validations.endDateGreaterThanStartDate')

                return false;
            }

            let currentDate = new Date().getTime()
            //console.log(endDate)
            //console.log(currentDate)

            if (endDate < currentDate) {
                this.errorEvent = true
                this.errorMessage = this.$t('validations.endDatePast')

                return false;
            }

            return true;

        },
        validTextfields() {

            if (helpers.isEmpty(this.eventData.eventName)) {
                this.errorEvent = true
                this.errorMessage = this.$t('validations.eventNameMandatory')

                return false;
            }

            if (helpers.isEmpty(this.eventData.eventDescription)) {
                this.errorEvent = true
                this.errorMessage = this.$t('validations.eventDescriptionMandatory')

                return false;
            }

            if (this.eventData.eventDescription.length >= 5000) {
                this.errorEvent = true
                this.errorMessage = this.$t('validations.eventDescriptionLimit')

                return false
            }

            if (this.eventData.eventDescriptionEn.length >= 5000) {
                this.errorEvent = true
                this.errorMessage = this.$t('validations.eventDescriptionLimitEn')

                return false
            }

            if (helpers.isEmpty(this.eventData.eventLocation)) {
                this.errorEvent = true
                this.errorMessage = this.$t('validations.eventLocationMandatory')

                return false
            }

            return true;
        },
        creaNuevoEvento() {

            this.$confirm({
                title: this.$t('myEvents.createEventConfirm'),
                message: this.$t('myEvents.createEventDisclaimer'),
                state: 'help',
                stateOutlined: true,
                acceptText: this.$t('actions.createEvent'),
                cancelText: this.$t('actions.cancel')
            }).then(async (result) => {

                if (result) {

                    if (this.validDates() && this.validId() && this.validTextfields()) {

                        this.activeName = false
                        this.activeDescription = false
                        this.activeLocation = false
                        this.activeVideo = false
                        this.errorEvent = false
                        this.savingEvent = true

                        try {

                            await this.createEvent({
                                id: this.eventData.eventId,
                                startDate: formatters.dateToTDString(this.eventData.eventStartDate),
                                endDate: formatters.dateToTDString(this.eventData.eventEndDate),
                                name: this.eventData.eventName,
                                videoURL: this.eventData.eventVideo,
                                description: this.eventData.eventDescription,
                                descriptionEn: this.eventData.eventDescriptionEn,
                                location: this.eventData.eventLocation,
                                msiEnabled: this.eventData.msiEnabled
                            })
                            this.savingEvent = false;
                            this.$toast(this.$t('myEvents.successCreateEvent'));

                        } catch (error) {
                            this.savingEvent = false;
                            this.errorMessage = error;
                            this.errorEvent = true;
                        }
                    }
                }
            });
        },
        async actualizarEvento() {

            if (this.validDates() && this.validTextfields()) {

                this.activeName = false
                this.activeDescription = false
                this.activeLocation = false
                this.activeVideo = false
                this.errorEvent = false
                this.savingEvent = true

                try {

                    await this.updateEvent({
                        id: this.eventData.eventId,
                        startDate: formatters.dateToTDString(this.eventData.eventStartDate),
                        endDate: formatters.dateToTDString(this.eventData.eventEndDate),
                        name: this.eventData.eventName,
                        videoURL: this.eventData.eventVideo,
                        description: this.eventData.eventDescription,
                        descriptionEn: this.eventData.eventDescriptionEn,
                        location: this.eventData.eventLocation,
                        msiEnabled: this.eventData.msiEnabled
                    })
                    this.savingEvent = false;
                    this.$toast(this.$t('myEvents.successUpdateEvent'));

                } catch (error) {
                    this.savingEvent = false;
                    this.errorMessage = error;
                    this.errorEvent = true;
                }
            }
        },

        async eliminaEvento() {
            this.$confirm({
                title: this.$t('myEvents.deleteEventConfirm'),
                message: this.$t('myEvents.deleteEventDisclaimer'),
                state: 'warning',
                stateOutlined: true,
                acceptText: this.$t('actions.delete'),
                cancelText: this.$t('actions.cancel')
            }).then(async (result) => {

                if (result) {

                    this.activeName = false
                    this.activeDescription = false
                    this.activeLocation = false
                    this.activeVideo = false
                    this.errorEvent = false
                    this.savingEvent = true

                    try {

                        await this.deleteSelectedEvent()

                        this.savingEvent = false;
                        this.$toast(this.$t('myEvents.successDeleteEvent'));

                    } catch (error) {
                        this.savingEvent = false;
                        this.errorMessage = error;
                        this.errorEvent = true;
                    }

                }

            });
        },

        async solicitarPublicacion() {
            this.$confirm({
                title: this.$t('myEvents.publishConfirm'),
                message: this.$t('myEvents.publishDisclaimer'),
                state: 'help',
                stateOutlined: true,
                acceptText: this.$t('actions.requestPublish'),
                cancelText: this.$t('actions.cancel')
            }).then(async (result) => {

                if (result) {

                    if (this.validDates() && this.validTextfields()) {

                        this.activeName = false
                        this.activeDescription = false
                        this.activeLocation = false
                        this.activeVideo = false
                        this.errorEvent = false
                        this.savingEvent = true

                        try {

                            await this.requestPublish()

                            this.savingEvent = false;
                            this.$toast(this.$t('myEvents.successPublish'));

                        } catch (error) {
                            this.savingEvent = false;
                            this.errorMessage = error.mensaje;
                            this.errorEvent = true;
                        }
                    }
                }

            });
        }
    }
}
</script>
<style scoped>
.info-container {
    margin-top: 20px;
}

.published {
    color: #006600;
    text-align: end;
    margin-bottom: 20px;
}

.not-published {
    color: #2c2342;
    text-align: end;
    margin-bottom: 20px;
}

.input-td {
    height: 42px;
    border: none;
    border-bottom: 1px solid #652d66;
    outline: none;
    font-family: "Nunito", sans-serif;
    font-size: medium;
    background-color: #eee;
    color: #666;
    border-radius: 3px;
    padding: 5px;
}

#autocomplete {
    width: 90%;
}

#locationName {
    font-size: medium;
}

#locationAddress {
    font-size: small;
}

.buttons-content {
    text-align: center;
}

.action-button {
    margin: 40px;
}

.spinner {
    margin-top: 20px;
    margin-bottom: 20px;
}
</style>