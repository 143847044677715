<template>
    <h2>{{ $t('titles.myEvents') }}</h2>
    <h3 v-if="showingNewEvent"> {{ $t('subtitles.newEvent') }}</h3>
    <div v-if="!showingNewEvent" id="event-selector">
        <ui-grid>
            <ui-grid-cell columns="9" align="middle">
                <event-selector @on-selected-event="displaySelectedEvent" />
            </ui-grid-cell>
            <ui-grid-cell columns="3" align="middle">
                <ui-button raised @click="newEvent"> {{ $t('actions.createEvent') }}
                    <template #after="{ iconClass }">
                        <ui-icon :class="iconClass">add</ui-icon>
                    </template>
                </ui-button>
            </ui-grid-cell>
        </ui-grid>
    </div>
    <div class="content">
        <error-component v-show="errorLoading" :message="errorMessage" />
        <ui-skeleton :loading="loadingEvent" :title="false" active :paragraph="{ rows: 30 }">
            <event-details-container v-if="showingNewEvent" :is-new-event="showingNewEvent"
                @cancel-edit-main-details="refreshEvents"></event-details-container>
            <event-details-container v-else :is-new-event="showingNewEvent"
                @cancel-edit-main-details="refreshEvents"></event-details-container>
        </ui-skeleton>
    </div>
</template>

<script>
import { useMainStore } from '@/stores/main';
import { mapState, mapActions } from 'pinia';
import EventSelector from "@/components/common/EventSelector.vue";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import EventDetailsContainer from '@/components/mis-eventos/EventDetailsContainer.vue';
import i18n from '@/i18n';

export default {
    name: 'MisEventosPage',
    components: {
        EventSelector,
        ErrorComponent,
        EventDetailsContainer
    },
    created() {

        const mainStore = useMainStore()
        mainStore.$patch({ pageTitle: i18n.global.t('titles.myEvents') })
        //console.log("ListadoEntradasPage created");
    },
    data() {
        return {
            loadingEvent: false,
            errorLoading: false,
            errorMessage: "",
        }
    },
    computed: {
        ...mapState(useMainStore, ['writableEvent']),
        showingNewEvent() {
            return this.writableEvent === null
        }
    },
    methods: {
        ...mapActions(useMainStore, ['getSelectedEvent', 'refreshOwnerEvents']),
        async displaySelectedEvent() {

            //console.log('displaySelectedEvent')

            this.loadingEvent = true;
            this.errorLoading = false;

            try {
                await this.getSelectedEvent();
                this.loadingEvent = false

            } catch (error) {
                this.loadingEvent = false
                this.errorLoading = true;
                this.errorMessage = error;
            }

        },
        async refreshEvents() {

            this.loadingEvent = true;
            this.errorLoading = false;
            //console.log("Refreshing...")

            try {
                await this.refreshOwnerEvents();
                //console.log(this.writableEvent)
                this.loadingEvent = false

            } catch (error) {
                this.loadingEvent = false
                this.errorLoading = true;
                this.errorMessage = error;
            }
        },
        newEvent() {
            const mainStore = useMainStore()
            mainStore.$patch({
                selectedEventId: null,
                writableEvent: null
            })
        },
    }
}
</script>
<style scoped>
h2,
h3,
h5 {
    text-align: center;
    margin-top: 2em;
}

#event-selector {
    width: 90%;
    margin: 0 auto;
}

.content {
    margin: 0 auto;
    width: 95%;
}
</style>