export default {
  "titles": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Ticket Dancer for Business"])},
    "mainSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The app where you can manage your events in Ticket Dancer"])},
    "logEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage entries"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My profile"])},
    "listOfEntries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of entries"])},
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
    "listOfPurchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of purchases"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
    "myEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage my events"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
    "generalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General information"])},
    "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])},
    "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets"])},
    "otherPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other payments and registrations"])},
    "listOfBankTransfers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of Bank Transfers"])}
  },
  "subtitles": {
    "newEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new event in Ticket Dancer"])},
    "listOfRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check all the records of your Event"])},
    "entryReg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register the entrances of your event"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update your information"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome,"])},
    "listOfBankTransfers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check all bank transfers of your Event"])}
  },
  "commonElements": {
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
    "entryType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of entry"])},
    "selectEntryType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select type of entry"])},
    "ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket"])},
    "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets"])},
    "registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment or registration"])},
    "registries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other payments and registries"])},
    "selectEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select event"])},
    "selectOtherPaymentRegistry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select type of payment or registry"])},
    "fixedPaymentInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* It must be mentioned in the Name of the payment to which ticket this payment corresponds (for instance, FULL PASS), and in the Description how many fixed payments are necessary to settle the full ticket (for instance, 3 payments)."])},
    "lodgingInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* It must be mentioned in the Name of the payment to which type of room it corresponds (for instance, QUADRUPLE ROOM). If it is a BUNDLE that includes both the TICKET to the Event and the ACCOMMODATION, it must be added as a TICKET instead of as Payment or Registration. Provide all additional details, notes, and conditions in the Record Description."])},
    "competitionInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* It must be mentioned in the Name of the payment to which type of registration it corresponds (for instance, SALSA PRO-AM). Provide all additional details, notes, and conditions in the Record Description."])},
    "initialPaymentInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* It must be mentioned in the Name of the payment to which ticket this initial payment corresponds (for instance, FULL PASS). Provide all additional details, notes and conditions in the Payment Description."])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
    "saveOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saver order"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "quantityAdjustment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantities adjustment"])},
    "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices adjustment"])},
    "salePriceMXN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public sale price (MXN)"])},
    "feeMXN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee calculated (MXN)"])},
    "salePriceUSD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public sale price (USD)"])},
    "feeUSD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee calculated (USD)"])},
    "amountReceivedMXN": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Amount received per unit (MXN): ", _interpolate(_list(0)), " *"])},
    "amountReceivedUSD": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Amount received per unit (USD): ", _interpolate(_list(0)), " *"])},
    "netMXN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producer's net total"])},
    "feeNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees automatically calculated"])},
    "mxnRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price range in Mexican pesos (MXN)"])},
    "usdRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price range in American dollars (USD)"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "noInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not available"])},
    "tbd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be defined"])},
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found"])}
  },
  "filters": {
    "filterBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by"])},
    "searchBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search criteria"])},
    "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order #"])},
    "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
    "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card digits"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
    "ticketType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket type"])},
    "registerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration type"])},
    "groupCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dance Group or Company"])},
    "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount code"])},
    "tdUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Dancer user email"])},
    "sn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# Serial Number"])},
    "attendee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendee's name"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
    "genre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
  },
  "headers": {
    "paymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment date (year-month-day)"])},
    "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment order #"])},
    "ticketNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# of tickets"])},
    "registerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# of registrations"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total amount"])},
    "netAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net amount received"])},
    "couponName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount code"])},
    "couponAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount code amount"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question 1"])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question 2"])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question 3"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer 1"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer 2"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer 3"])},
    "purchaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase date (year-month-day)"])},
    "log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log"])},
    "logDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log date (year-month-day)"])},
    "logType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log type"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "enteredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entered by"])},
    "withMSI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FII enabled"])},
    "transferDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer date (year-month-day)"])},
    "bankTransferComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is total amount settled?"])},
    "depositedAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposited amount"])},
    "bankReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank reference"])}
  },
  "home": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your account"])},
    "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Dancer® is your ideal partner"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing"])},
    "quote1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I had never worked so comfortably at an event!"])},
    "quote2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ease of seeing how sales are going at any time allows me to make timely decisions"])},
    "subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start selling in three easy steps"])},
    "step1title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your account"])},
    "step1message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You only need a valid email or a Gmail or Apple ID account to begin"])},
    "step2title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your information"])},
    "step2message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up your bank account in Stripe® where you will directly receive the money from your sales"])},
    "step3title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your event"])},
    "step3message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your event information and start selling it on our platform"])},
    "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your experience as a Producer"])},
    "realTimeReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales and transaction reports in real time"])},
    "discountCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount code generation"])},
    "customTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customized tickets to your event"])},
    "qrScan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access control by QR scanner"])},
    "competitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration of competitions and other concepts"])},
    "diffusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diffusion of your event in our community"])},
    "paymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept cards, installments, wallets and more"])},
    "focus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You focus on your event and we focus on Customer Service"])},
    "subtitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are specialists in dance events!"])},
    "unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique"])},
    "uniqueMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no other specialized system in the management of a dance congress"])},
    "tested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tested"])},
    "testedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have been operating in the dance industry for years without setbacks. Our clients can vouch for it"])},
    "reliable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reliable"])},
    "reliableMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure commercial transactions with the support of our ally Stripe®, with capacity for any event"])},
    "authentic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentic"])},
    "authenticMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designed by passionate dancers, producers and professionals specialized in their field with global experience"])},
    "successStories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success stories"])}
  },
  "pricing": {
    "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We made it simple for you"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A service plan -commission percentage- is applied based on the ticket or registration sales price. This plan is applied individually to each ticket or registration."])},
    "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic"])},
    "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
    "preference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preference"])},
    "calulatorHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales simulator"])},
    "publicPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale price"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "feePerTicket": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Plan applied: ", _interpolate(_list(0)), " (", _interpolate(_list(1)), ")"])},
    "amountReceived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Amount received per unit: ", _interpolate(_list(0)), " ", _interpolate(_list(1))])},
    "totalReceived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Total received: ", _interpolate(_list(0)), " ", _interpolate(_list(1))])},
    "stripeDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* It does not include the fee by"])},
    "ally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Business ally - Stripe ™"])},
    "stripeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To ensure that online purchases through Ticket Dancer™ meet security and reliability requirements, we use the world-renowned tool called Stripe™."])},
    "stripeDescription2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks to this you will be able to manage many payment methods for your clients easily and safely:"])}
  },
  "myEvents": {
    "dateField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of the event (once published it cannot be edited):"])},
    "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date:"])},
    "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date:"])},
    "linkField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to the event on Ticket Dancer. Only letters, numbers and middle dashes are allowed. Accents and punctuation marks are not allowed. (Example: my-great-event-2023). Once the Event is created it cannot be modified"])},
    "eventName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event name"])},
    "youtubeLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YouTube link"])},
    "eventDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event description (SPANISH)"])},
    "eventDescriptionEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event description (ENGLISH)"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "locationField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a location"])},
    "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published"])},
    "notPublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOT published"])},
    "mainImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main image (Recommended size: 800px * 500px)"])},
    "bannerImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner of the event (Recommended size: 800px * 400px)"])},
    "logoImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producer or Event Logo (Recommended size: 500px * 400px)"])},
    "successImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The image has been successfully updated"])},
    "createEventConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to create the Event?"])},
    "createEventDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep in mind that there will be fields that you will no longer be able to modify later"])},
    "successCreateEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The event has been successfully created"])},
    "successUpdateEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The event has been successfully updated"])},
    "deleteEventConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete this Event?"])},
    "deleteEventDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Event information will be lost and you will no longer be able to recover it."])},
    "successDeleteEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The event has been successfully deleted"])},
    "publishConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to publish your Event?"])},
    "publishDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Dancer will validate the information and notify you once the Event is published"])},
    "successPublish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The publication of your Event has been requested. We will keep you informed"])},
    "enableMSI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Free-Interests Installments in all the event (applies to all Tickets and Other Payments and Registrations)"])}
  },
  "tickets": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new ticket"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket name"])},
    "maxLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max limit of tickets"])},
    "userTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket limit per user"])},
    "attendeesPerTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# of attendees per ticket"])},
    "mandatoryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandatory name"])},
    "ticketsPurchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets purchased"])},
    "mandatoryNameForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is attendee's name mandatory?"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active ticket"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive ticket"])},
    "ticketDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket description (SPANISH)"])},
    "ticketDescriptionEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket description (ENGLISH)"])},
    "editDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update your ticket information (some fields may be disabled for security reasons)"])},
    "purchasedDeletionError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The ticket '", _interpolate(_list(0)), "' can't be deleted because it already has purchases registered"])},
    "confirmDelete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you really want to DELETE the ticket '", _interpolate(_list(0)), "'?"])},
    "deleteConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ticket has been successfully deleted"])},
    "confirmDisable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you really want to DISABLE the ticket '", _interpolate(_list(0)), "'?"])},
    "disableDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ticket will NOT be shown as an option for users"])},
    "disableConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ticket was successfully disabled. It will no longer be shown to users"])},
    "confirmEnable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you really want to ENABLE the ticket '", _interpolate(_list(0)), "'?"])},
    "enableDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ticket WILL be shown as an option for users"])},
    "enableConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ticket was successfully enabled. It will now be shown to users"])},
    "duplicateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate a ticket for your event"])},
    "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new ticket for your event"])},
    "addConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The new ticket has been successfully created"])},
    "updateConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the ticket has been successfully updated"])},
    "enableMSI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Free-Interests Installments for this ticket"])}
  },
  "otherPayments": {
    "fixedPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIXED PAYMENT"])},
    "accommodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACCOMMODATION"])},
    "competition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPETITION"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTHER"])},
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crate new payment or registration"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the payment or registration"])},
    "question1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question 1 (optional)"])},
    "question2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question 2 (optional)"])},
    "question3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question 3 (optional)"])},
    "maxLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max limit of payments or registrations"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of payment or registry"])},
    "optionalQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional questions"])},
    "registries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments or registrations purchased"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active payment or registration"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive payment or registration"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of payment or registration (SPANISH)"])},
    "descriptionEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of payment or registration (ENGLISH)"])},
    "editDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update your payment information (some fields may be disabled for security reasons)"])},
    "duplicateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate a payment or registration for your event"])},
    "confirmDisable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you really want to DISABLE the payment '", _interpolate(_list(0)), "'?"])},
    "disableDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment or registration will NOT be shown as an option for users"])},
    "disableConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment or registration was successfully disabled. It will no longer be shown to users"])},
    "confirmEnable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you really want to ENABLE the payment or registration '", _interpolate(_list(0)), "'?"])},
    "enableDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment WILL be shown as an option for users"])},
    "enableConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment or registration was successfully enabled. It will now be shown to users"])},
    "purchasedDeletionError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The payment '", _interpolate(_list(0)), "' can't be deleted because it already has purchases registered"])},
    "confirmDelete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you really want to DELETE the payment '", _interpolate(_list(0)), "'?"])},
    "deleteConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment or registration has been successfully deleted"])},
    "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new payment or registration for your event"])},
    "addConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The new payment or registration has been successfully created"])},
    "updateConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the payment or registration has been successfully updated"])},
    "enableMSI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Free-Interests Installments for this payment"])}
  },
  "reports": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General report of your events"])},
    "otherPaymentsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report of other Payments and Registrations"])},
    "ticketsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket report"])},
    "filterEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by event"])},
    "filterRegistry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by payment or registration"])},
    "filterTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by ticket"])},
    "eventName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event name"])},
    "ticketTypeQty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket type: Quantity"])},
    "registrationTypeQty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment or registration type: Quantity"])}
  },
  "purchases": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review all purchases made for your event"])},
    "ticketsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of completed ticket purchases"])},
    "registriesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of other Payments and Registrations completed"])},
    "stripeLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to your Stripe account"])},
    "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card"])},
    "oxxo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OXXO (Convenience store in Mexico)"])},
    "bankTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Transfer"])},
    "listOfTicketsPurchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of purchased tickets"])},
    "listOfOtherPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of other purchased registrations"])},
    "hasEntered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has entered?"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])}
  },
  "entryReg": {
    "logTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log history"])},
    "entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTRANCE"])},
    "cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CANCEL ENTRANCE"])},
    "entryDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry to the event is registered"])},
    "registerEntranceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the Ticket or Registration data"])},
    "typeEntrySelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select type of entry:"])},
    "searchSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search entry by:"])},
    "qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Code"])},
    "searchResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search results"])},
    "entered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* MARKED AS ENTERED"])},
    "notEntered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* NOT MARKED"])},
    "entranceDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Carefully validate all data before proceeding"])},
    "cancelEntranceConfirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Cancel the entrance of ", _interpolate(_list(0)), ": ", _interpolate(_list(1)), "?"])},
    "cancelEntranceDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe the reason for Cancellation"])},
    "reader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR reader connected"])},
    "camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device camera"])},
    "selectInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select QR code input reader"])},
    "loadingCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading camera..."])}
  },
  "user": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in using account of"])},
    "producer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producer"])},
    "staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "rights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["All rights reserved, Ticket Dancer™ ", _interpolate(_list(0))])},
    "names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name(s)"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "passwordUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update your password"])},
    "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])},
    "updateConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your information has been successfully updated"])}
  },
  "actions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
    "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
    "moveDown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move down"])},
    "moveUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move up"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "createEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new event"])},
    "requestPublish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request to publish"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "markEntrance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark entrance"])},
    "cancelEntrance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel entrance"])},
    "confirmCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm cancellation"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])}
  },
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is required"])},
    "fieldRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
    "greaterThanZero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["must be greater than 0"])},
    "tooBig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" is too big"])},
    "lessThan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" must be less than "])},
    "cannotUndo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action cannot be undone"])},
    "imageSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The image size must not be larger than 5 MB"])},
    "eventLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Event Link is invalid. Only letters, numbers and dashes are allowed. Neither accents nor spaces are allowed"])},
    "allDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both event dates are mandatory (Start and End date)"])},
    "endDateGreaterThanStartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check that the Event End date occurs after the Event Start date"])},
    "endDatePast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check that the Event End date does not occur in the past"])},
    "eventNameMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The event name is mandatory"])},
    "eventDescriptionMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The event description is mandatory"])},
    "eventDescriptionLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The event description (SPANISH) must be less than 4000 characteres"])},
    "eventDescriptionLimitEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The event description (ENGLISH) must be less than 4000 characteres"])},
    "eventLocationMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The event location is mandatory"])},
    "passwordRequirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" must be at least 6 characters long, one uppercase letter, one lowercase letter, and one number"])},
    "passwordMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password confirm failed"])}
  },
  "currencies": {
    "mxn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🇲🇽 - Mexican pesos"])},
    "usd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🇺🇸 - US dollars"])}
  },
  "errors": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! Page not found :("])},
    "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong. Try again later"])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error ocurred while uploading the image"])}
  }
}