import { URLS } from "@/utils/constants";
import commonsApi from "./CommonsApi";
import i18n from "@/i18n";
import { useMainStore } from '@/stores/main';
import { helpers } from "balm-ui";

export default {

    async getBankTransfersByEvent(userId, page, size) {

        const mainStore = useMainStore();
        //console.log(mainStore.selectedEventId)
        //console.log(mainStore.selectedType)

        if (helpers.isEmpty(mainStore.selectedEventId) || helpers.isEmpty(mainStore.selectedType) || mainStore.selectedType === 'none') {
            return
        }

        let capcha = await commonsApi.getCaptcha();
        let url;

        if (mainStore.selectedType === 'boletos') {
            url = URLS.GET_PAYMENT_BANK_TRANSFERS_BY_EVENT
        } else if (mainStore.selectedType === 'registros') {
            url = URLS.GET_REGISTRY_PAYMENT_BANK_TRANSFERS_BY_EVENT
        }

        if (helpers.isEmpty(userId)) {
            url = url + "?eventId=" + mainStore.selectedEventId + "&page=" + page + "&size=" + size
        } else {
            url = url + "?eventId=" + mainStore.selectedEventId + "&userId=" + userId + "&page=" + page + "&size=" + size
        }

        let response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: mainStore.userLogged.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let bankTransfersData = await response.json();
            return bankTransfersData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    },
}