<template>
  <ui-top-app-bar content-selector="#main-content" type="fixed" :title="pageTitle" :navIcon="topBarIcon"
    @nav="checkDrawer">

    <template #toolbar="{ toolbarItemClass }">
      <ui-icon-button :class="toolbarItemClass" icon="home" @click.prevent="goHome"></ui-icon-button>
      <ui-menu-anchor :class="toolbarItemClass">
        <ui-icon-button icon="language" @click="openMenu = true">
        </ui-icon-button>
        <ui-menu v-model="openMenu" :items="languages" @selected="changeLanguage($event.value)"></ui-menu>
      </ui-menu-anchor>
    </template>

  </ui-top-app-bar>

  <ui-drawer v-model="openDrawer" type="modal">
    <ui-drawer-header>
      <ui-drawer-title> {{ $t('subtitles.welcome') }}</ui-drawer-title>
      <ui-drawer-subtitle> {{ name }}</ui-drawer-subtitle>
    </ui-drawer-header>
    <ui-drawer-content>
      <ui-list-divider></ui-list-divider>
      <ui-nav>
        <template v-if="onlyOwner">
          <router-link v-slot="{ href, navigate, isActive }" to="/reportes" custom>
            <ui-nav-item :href="href" :active="isActive" @click="navigate" @click.prevent="toggleDrawer">
              <ui-item-first-content>
                <ui-icon>summarize</ui-icon>
              </ui-item-first-content>
              <ui-item-text-content>{{ $t('titles.reports') }}</ui-item-text-content>
            </ui-nav-item>
          </router-link>
          <router-link v-slot="{ href, navigate, isActive }" to="/mis-eventos" custom>
            <ui-nav-item :href="href" :active="isActive" @click="navigate" @click.prevent="toggleDrawer">
              <ui-item-first-content>
                <ui-icon>event</ui-icon>
              </ui-item-first-content>
              <ui-item-text-content>{{ $t('titles.myEvents') }}</ui-item-text-content>
            </ui-nav-item>
          </router-link>
          <router-link v-slot="{ href, navigate, isActive }" to="/listado-transferencias" custom>
            <ui-nav-item :href="href" :active="isActive" @click="navigate" @click.prevent="toggleDrawer">
              <ui-item-first-content>
                <ui-icon>account_balance</ui-icon>
              </ui-item-first-content>
              <ui-item-text-content>{{ $t('titles.listOfBankTransfers') }}</ui-item-text-content>
            </ui-nav-item>
          </router-link>
          <router-link v-slot="{ href, navigate, isActive }" to="/listado-pagos" custom>
            <ui-nav-item :href="href" :active="isActive" @click="navigate" @click.prevent="toggleDrawer">
              <ui-item-first-content>
                <ui-icon>payments</ui-icon>
              </ui-item-first-content>
              <ui-item-text-content>{{ $t('titles.listOfPurchases') }}</ui-item-text-content>
            </ui-nav-item>
          </router-link>
        </template>
        <router-link v-slot="{ href, navigate, isActive }" to="/listado-entradas" custom>
          <ui-nav-item :href="href" :active="isActive" @click="navigate" @click.prevent="toggleDrawer">
            <ui-item-first-content>
              <ui-icon>list_alt</ui-icon>
            </ui-item-first-content>
            <ui-item-text-content>{{
    $t('titles.listOfEntries')
  }}</ui-item-text-content>
          </ui-nav-item>
        </router-link>
        <router-link v-slot="{ href, navigate, isActive }" to="/registrar" custom>
          <ui-nav-item :href="href" :active="isActive" @click="navigate" @click.prevent="toggleDrawer">
            <ui-item-first-content>
              <ui-icon>app_registration</ui-icon>
            </ui-item-first-content>
            <ui-item-text-content>{{ $t('titles.logEntry') }}</ui-item-text-content>
          </ui-nav-item>
        </router-link>
        <router-link v-slot="{ href, navigate, isActive }" to="/perfil" custom>
          <ui-nav-item :href="href" :active="isActive" @click="navigate" @click.prevent="toggleDrawer">
            <ui-item-first-content>
              <ui-icon>badge</ui-icon>
            </ui-item-first-content>
            <ui-item-text-content>{{ $t('titles.profile') }}</ui-item-text-content>
          </ui-nav-item>
        </router-link>
        <router-link v-slot="{ href, navigate }" :to="{ name: 'home' }" custom>
          <ui-nav-item :href="href" @click="navigate" @click.prevent="logoutFromTD">
            <ui-item-first-content>
              <ui-icon>logout</ui-icon>
            </ui-item-first-content>
            <ui-item-text-content>{{ $t('titles.logout') }}</ui-item-text-content>
          </ui-nav-item>
        </router-link>
      </ui-nav>
    </ui-drawer-content>
  </ui-drawer>

  <div id="main-content">
    <slot></slot>
  </div>
</template>

<script>
import { useMainStore } from '@/stores/main';
import { mapState, mapActions } from 'pinia';

const languages = [
  {
    text: 'Español',
    value: 'es'
  },
  {
    text: 'English',
    value: 'en'
  }
];


export default {
  name: "TopBar",
  data() {
    return {
      openDrawer: false,
      languages: languages,
      openMenu: false,
    };
  },
  computed: {
    ...mapState(useMainStore, ["userLogged", "pageTitle"]),
    name() {
      return this.userLogged ? this.userLogged.nombre : "";
    },
    topBarIcon() {
      return this.userLogged ? "menu" : "stars";
    },
    onlyOwner() {
      return this.userLogged && this.userLogged.sessionType === "OWNER";
    },
  },
  methods: {
    ...mapActions(useMainStore, ['logout', 'updateDefaultLanguage']),
    checkDrawer() {
      if (this.userLogged) {
        this.toggleDrawer();
      } else {
        this.openDrawer = false;
      }
    },
    toggleDrawer() {
      //console.log("toggle");
      this.openDrawer = !this.openDrawer;
    },
    goHome() {
      this.$router.push({ name: 'home' });
    },
    logoutFromTD() {
      this.toggleDrawer();
      this.logout();
      this.$router.push({ name: 'home' });
    },
    changeLanguage(value) {
      this.$i18n.locale = value
      this.updateDefaultLanguage(value)
    }
  },
};
</script>