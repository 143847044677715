import { URLS } from "@/utils/constants";
import commonsApi from "./CommonsApi";
import i18n from "@/i18n";

export default class EventsAPI {

    async getEvents(payload) {

        let capcha = await commonsApi.getCaptcha();

        const getEventsUrl = payload.sessionType === "OWNER" ? URLS.OWNER_GET_EVENTS_DATA : URLS.STAFF_GET_EVENTS_DATA;

        let response = await fetch(getEventsUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let userEventsData = await response.json();
            return userEventsData;
        }
        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async searchTicketQR(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.SEARCH_TICKET_QR, {
            method: "POST",
            body: JSON.stringify({
                qrString: payload.qrString,
                eventId: payload.eventId,

            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });
        if (response.status >= 200 && response.status < 300) { // Success
            let ticketData = await response.json();
            return ticketData;
        }
        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async searchTicketManual(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.SEARCH_TICKET_MANUAL, {
            method: "POST",
            body: JSON.stringify({
                ticketPurchasedId: payload.ticketPurchasedId,
                eventId: payload.eventId,

            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });
        if (response.status >= 200 && response.status < 300) { // Success
            let ticketData = await response.json();
            return ticketData;
        }
        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async searchRegistroQR(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.SEARCH_REGISTRY_QR, {
            method: "POST",
            body: JSON.stringify({
                qrString: payload.qrString,
                eventId: payload.eventId,

            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let registryData = await response.json();
            return registryData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async searchRegistroManual(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.SEARCH_REGISTRY_MANUAL, {
            method: "POST",
            body: JSON.stringify({
                registryId: payload.registryId,
                eventId: payload.eventId,

            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let registryData = await response.json();
            return registryData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async marcaIngresoTicket(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.MARK_TICKET, {
            method: "POST",
            body: JSON.stringify({
                ticketPurchasedId: payload.ticketPurchasedId,
                eventId: payload.eventId,

            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let ticketData = await response.json();
            return ticketData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async marcaIngresoRegistro(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.MARK_REGISTRY, {
            method: "POST",
            body: JSON.stringify({
                registryId: payload.registryId,
                eventId: payload.eventId,

            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let registryData = await response.json();
            return registryData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async cancelaIngresoTicket(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.CANCEL_TICKET, {
            method: "POST",
            body: JSON.stringify({
                ticketPurchasedId: payload.ticketPurchasedId,
                eventId: payload.eventId,
                type: "CANCELACION",
                detalle: payload.detalle,
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let ticketData = await response.json();
            return ticketData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async cancelaIngresoRegistro(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.CANCEL_REGISTRY, {
            method: "POST",
            body: JSON.stringify({
                registryId: payload.registryId,
                eventId: payload.eventId,
                type: "CANCELACION",
                detalle: payload.detalle,
            }),
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let registryData = await response.json();
            return registryData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async getTicketsPurchased(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_TICKETS_PURCHASED + "/" + payload.eventId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let ticketsPurchasedData = await response.json();
            return ticketsPurchasedData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async getRegistriesPurchased(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_REGISTRIES_PURCHASED + "/" + payload.eventId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let registriesPurchasedData = await response.json();
            return registriesPurchasedData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async getTicketPurchasedLog(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_TICKET_PURCHASED_LOG + "/" + payload.eventId + "/" + payload.ticketId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let ticketPurchasedData = await response.json();
            return ticketPurchasedData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async getRegistriyPurchasedLog(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_REGISTRY_PURCHASED_LOG + "/" + payload.eventId + "/" + payload.registryId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let registryPurchasedData = await response.json();
            return registryPurchasedData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async getEventById(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_EVENT_BY_ID + '/' + payload.eventId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let eventData = await response.json();
            return eventData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async createEvent(token, eventData) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.CREATE_EVENT, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
            body: JSON.stringify(eventData)
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let eventDataResponse = await response.json();
            return eventDataResponse;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async updateEvent(token, eventData) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.UPDATE_EVENT, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
            body: JSON.stringify(eventData)
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let eventDataResponse = await response.json();
            return eventDataResponse;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async deleteEvent(token, eventId) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.DELETE_EVENT + '/' + eventId, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            return null;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async uploadEventImage(payload) {

        let capcha = await commonsApi.getCaptcha();

        const data = new FormData();
        data.append("eventId", payload.eventId)
        data.append("imageType", payload.imageType)
        data.append("image", payload.image)

        let response = await fetch(URLS.UPLOAD_EVENT_IMAGE, {
            method: "POST",
            headers: {
                key: capcha,
                vyliwi: payload.token
            },
            body: data
        })

        if (response.status >= 200 && response.status < 300) {
            return await response.text();
        } else if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje
        } else {
            throw i18n.global.t('errors.image')
        }
    }

    async requestPublish(token, eventId) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.REQUEST_EVENT_PUBLISH, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
            body: JSON.stringify({
                id: eventId
            })
        });

        if (response.status === 200) {
            return null
        }
        else if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async updateTicketsOrder(token, orderUpdateModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.UPDATE_TICKETS_ORDER, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
            body: JSON.stringify(orderUpdateModel)
        });

        if (response.status >= 200 && response.status < 300) {
            let ticketsResponseData = await response.json();
            return ticketsResponseData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async createTicket(token, eventId, ticketModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.CREATE_TICKET + "/" + eventId, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
            body: JSON.stringify(ticketModel)
        });

        if (response.status >= 200 && response.status < 300) {
            let ticketsResponseData = await response.json();
            return ticketsResponseData;
        } else if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async updateTicket(token, eventId, ticketModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.UPDATE_TICKET + "/" + eventId, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
            body: JSON.stringify(ticketModel)
        });

        if (response.status >= 200 && response.status < 300) {
            let ticketsResponseData = await response.json();
            return ticketsResponseData;
        } else if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async activateTicket(token, eventId, ticketId) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.ACTIVATE_TICKET + "/" + eventId + "/" + ticketId, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            }
        });

        if (response.status >= 200 && response.status < 300) {
            let ticketsResponseData = await response.json();
            return ticketsResponseData;
        } else if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async deactivateTicket(token, eventId, ticketId) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.DEACTIVATE_TICKET + "/" + eventId + "/" + ticketId, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            }
        });

        if (response.status >= 200 && response.status < 300) {
            let ticketsResponseData = await response.json();
            return ticketsResponseData;
        } else if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async deleteTicket(token, eventId, ticketId) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.DELETE_TICKET + "/" + eventId + "/" + ticketId, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            }
        });

        if (response.status >= 200 && response.status < 300) {
            return null;
        } else if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async updateRegistrationOrder(token, orderUpdateModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.UPDATE_REGISTRATION_ORDER, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
            body: JSON.stringify(orderUpdateModel)
        });

        if (response.status >= 200 && response.status < 300) {
            let registerTypesResponse = await response.json();
            return registerTypesResponse;
        }
        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async createRegisterType(token, eventId, registerTypeModel) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.CREATE_REGISTER_TYPE + "/" + eventId, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
            body: JSON.stringify(registerTypeModel)
        });

        if (response.status >= 200 && response.status < 300) {
            let registerTypeResponseData = await response.json();
            return registerTypeResponseData;
        } else if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async updateRegisterType(token, eventId, registerTypeModel) {

        //console.log(registerTypeModel)

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.UPDATE_REGISTER_TYPE + "/" + eventId, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
            body: JSON.stringify(registerTypeModel)
        });

        if (response.status >= 200 && response.status < 300) {
            let registerTypeResponseData = await response.json();
            return registerTypeResponseData;
        } else if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async activateRegisterType(token, eventId, registerTypeId) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.ACTIVATE_REGISTER_TYPE + "/" + eventId + "/" + registerTypeId, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            }
        });

        if (response.status >= 200 && response.status < 300) {
            let registerTypeResponseData = await response.json();
            return registerTypeResponseData;
        } else if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async deactivateRegisterType(token, eventId, registerTypeId) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.DEACTIVATE_REGISTER_TYPE + "/" + eventId + "/" + registerTypeId, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            }
        });

        if (response.status >= 200 && response.status < 300) {
            let registerTypeResponseData = await response.json();
            return registerTypeResponseData;
        } else if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async deleteRegisterType(token, eventId, registerTypeId) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.DELETE_REGISTER_TYPE + "/" + eventId + "/" + registerTypeId, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            }
        });

        if (response.status >= 200 && response.status < 300) {
            return null;
        } else if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async getAllRegisterTypeClassifications(token) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_ALL_REGISTER_TYPE_CLASSIFICATIONS, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let classificationsData = await response.json();
            return classificationsData;
        }
        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }
}