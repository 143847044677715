<template>
  <div>
    <h2> {{ $t('errors.notFound') }}</h2>
  </div>
</template>
<script>
import i18n from '@/i18n';
import { useMainStore } from '@/stores/main';

export default {
  name: "NoContentPage",
  created() {
    const mainStore = useMainStore()
    mainStore.$patch({ pageTitle: i18n.global.t('titles.main') })
  },
};
</script>
<style scoped>
h2 {
  text-align: center;
  margin-top: 2em;
}
</style>