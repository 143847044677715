<template>
  <h2>{{ $t('subtitles.entryReg') }}</h2>
  <div id="event-selector">
    <event-selector />
  </div>
  <ui-grid>
    <ui-grid-cell columns="6">
      <registrar-entrada-form @ticket-found="saveTicket" @registry-found="saveRegistry" />
    </ui-grid-cell>
    <ui-grid-cell columns="6">
      <registrar-entrada-result :ticket-found="ticketFound" :registry-found="registryFound" @refresh-ticket="saveTicket"
        @refresh-registry="saveRegistry" />
    </ui-grid-cell>
  </ui-grid>
  <ui-grid>
    <ui-grid-cell columns="12">
      <log-boletos-registros :ticket-found="ticketFound" :registry-found="registryFound" />
    </ui-grid-cell>
  </ui-grid>
</template>
<script>
import { useMainStore } from '@/stores/main';
import RegistrarEntradaForm from "@/components/registro/RegistrarEntradaForm.vue";
import RegistrarEntradaResult from "@/components/registro/RegistrarEntradaResult.vue";
import LogBoletosRegistros from "@/components/registro/LogBoletosRegistros.vue";
import EventSelector from "@/components/common/EventSelector.vue";
import i18n from '@/i18n';

export default {
  name: "RegistrarBoletoPage",
  components: {
    EventSelector,
    RegistrarEntradaForm,
    RegistrarEntradaResult,
    LogBoletosRegistros,
  },
  created() {
    const mainStore = useMainStore()
    mainStore.$patch({ pageTitle: i18n.global.t('titles.logEntry') })
    //console.log("RegistrarBoletoPage created");
  },
  data() {
    return {
      ticketFound: null,
      registryFound: null,
    };
  },
  methods: {
    saveTicket(ticketFound) {
      this.ticketFound = ticketFound;
      //console.log("Ticket found: " + this.ticketFound);
    },
    saveRegistry(registryFound) {
      this.registryFound = registryFound;
      //console.log("Registry found: " + this.registryFound);
    },
  },
};
</script>
<style scoped>
h2,
h5 {
  text-align: center;
  margin-top: 2em;
}

#event-selector {
  margin: auto;
  width: 50%;
}
</style>