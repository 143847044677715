<template>
  <div class="container">
    <h3> {{ imgTitle }}</h3>
    <div class="item" :style="imageStyle">
      <span class="preview" :style="backgroundImage"></span>
    </div>
    <ui-progress class="upload-action" v-show="imageUploading" indeterminate active></ui-progress>
    <error-component v-show="!imageUploading && errorImage" :message="errorMessage" />
    <h4>Max. 5 MB</h4>
    <ui-file accept="image/*" @change="onFilesChanged" :text="this.$t('actions.upload')" class="upload-action"></ui-file>
  </div>
</template>

<script>
import { useMainStore } from '@/stores/main';
import { mapState } from 'pinia';
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import EventsAPI from "@/api/EventsAPI.js";

export default {
  components: {
    ErrorComponent,
  },
  props: {
    imgWidth: Number,
    imgHeight: Number,
    imgTitle: String,
    imgUrl: String,
    imgType: String,
  },
  data() {
    return {
      files: [],
      bgImageUrl: this.imgUrl,
      imageUploading: false,
      errorImage: false,
      errorMessage: ""
    };
  },
  computed: {
    ...mapState(useMainStore, ['userLogged', 'selectedEventId']),
    backgroundImage() {
      return this.bgImageUrl ? { backgroundImage: `url(${this.bgImageUrl})` } : {};
    },
    imageStyle() {
      if (this.imgWidth && this.imgHeight) {
        return `width: ${this.imgWidth}px; height: ${this.imgHeight}px;`
      } else {
        return 'width: 300px; height: 300px;'
      }
    }
  },
  methods: {
    async onFilesChanged(files) {
      this.files = [...files]
      if (this.files.length > 0) {
        if (this.files[0].size / (1024 * 1024) > 5) {
          this.errorImage = true;
          this.errorMessage = this.$t('validations.imageSize')
        } else {
          this.errorImage = false;
          this.imageUploading = true;
          try {
            if (this.imgType && this.imgType.includes("EVENT")) {
              const eventsAPI = new EventsAPI();
              this.bgImageUrl = await eventsAPI.uploadEventImage({
                token: this.userLogged.token,
                eventId: this.selectedEventId,
                imageType: this.imgType,
                image: this.files[0].sourceFile
              })
            }

            this.imageUploading = false;
            this.$toast(this.$t('myEvents.successImage'));

          } catch (error) {
            this.imageUploading = false;
            this.errorMessage = error;
            this.errorImage = true;
          }
        }
      }
    }
  }
};
</script>

<style scoped>
.container {
  padding: 10px;
  text-align: center;
}

.item {
  margin: 0 auto;
}

.preview {
  display: block;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 75%;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.upload-action {
  margin-top: 20px;
}
</style>