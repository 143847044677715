<template>
  <h2>{{ $t('subtitles.listOfRecords') }}</h2>
  <div id="event-selector">
    <ui-grid>
      <ui-grid-cell columns="8">
        <event-selector @on-selected-event="updateTable" />
      </ui-grid-cell>
      <ui-grid-cell columns="4">
        <entry-type-selector @on-selected-entry-type="updateTable" />
      </ui-grid-cell>
    </ui-grid>
  </div>
  <div class="content">
    <error-component v-show="errorTable" :message="errorMessage" />
    <ui-skeleton :loading="loadingTable" :title="false" active :paragraph="{ rows: 20 }">
      <tabla-boletos-comprados :tickets-purchased="ticketsPurchased" @refresh-tickets="updateTable"
        @selected-ticket-id="showTicketLog" v-if="showTickets" />
      <tabla-registros-comprados :registries-purchased="registriesPurchased" @refresh-registries="updateTable"
        @selected-registry-id="showRegistryLog" v-else-if="showRegistries" />
    </ui-skeleton>
  </div>
  <ui-grid>
    <ui-grid-cell columns="12">
      <ui-skeleton :loading="loadingLog" :title="false" active :paragraph="{ rows: 5 }">
        <log-boletos-registros :ticket-found="ticketFound" :registry-found="registryFound" />
      </ui-skeleton>
    </ui-grid-cell>
  </ui-grid>
  <error-component v-show="errorLog" :message="errorMessageLog" />
</template>
<script>
import { useMainStore } from '@/stores/main';
import { mapState } from 'pinia';
import EventSelector from "@/components/common/EventSelector.vue";
import EntryTypeSelector from "@/components/common/EntryTypeSelector.vue";
import TablaBoletosComprados from "@/components/entradas/TablaBoletosComprados.vue";
import TablaRegistrosComprados from "@/components/entradas/TablaRegistrosComprados.vue";
import LogBoletosRegistros from "@/components/registro/LogBoletosRegistros.vue";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import EventsAPI from "@/api/EventsAPI";
import i18n from "@/i18n"

export default {
  name: "ListadoEntradasPage",
  components: {
    EventSelector,
    EntryTypeSelector,
    ErrorComponent,
    TablaBoletosComprados,
    TablaRegistrosComprados,
    LogBoletosRegistros,
  },
  created() {

    const mainStore = useMainStore()
    mainStore.$patch({ pageTitle: i18n.global.t('titles.listOfEntries') })
    //console.log("ListadoEntradasPage created");
  },
  data() {
    return {
      ticketsPurchased: [],
      registriesPurchased: [],
      loadingTable: false,
      errorTable: false,
      errorMessage: "",
      showTickets: false,
      showRegistries: false,
      ticketFound: null,
      registryFound: null,
      loadingLog: false,
      errorLog: false,
      errorMessageLog: "",
    };
  },
  computed: {
    ...mapState(useMainStore, ['userLogged', 'selectedType', 'selectedEventId']),
  },
  methods: {
    async updateTable() {
      this.ticketFound = null;
      this.registryFound = null;
      if (this.selectedEventId) {
        const eventsAPI = new EventsAPI();
        this.loadingTable = true;
        this.errorTable = false;
        try {
          if (this.selectedType === "boletos") {
            this.ticketsPurchased = await eventsAPI.getTicketsPurchased({
              eventId: this.selectedEventId,
              token: this.userLogged.token,
            });
            this.showTickets = true;
            this.showRegistries = false;
          } else if (this.selectedType === "registros") {
            this.registriesPurchased = await eventsAPI.getRegistriesPurchased({
              eventId: this.selectedEventId,
              token: this.userLogged.token,
            });
            this.showTickets = false;
            this.showRegistries = true;
          }
        } catch (error) {
          this.errorTable = true;
          this.errorMessage = error;
          this.showTickets = false;
          this.showRegistries = false;
        }
        this.loadingTable = false;
      } else {
        this.errorTable = false;
        this.showTickets = false;
        this.showRegistries = false;
      }
    },
    async showTicketLog(ticketId) {
      //console.log(ticketId);
      this.registryFound = null;
      const eventsAPI = new EventsAPI();
      this.loadingLog = true;
      this.errorLog = false;
      try {
        this.ticketFound = await eventsAPI.getTicketPurchasedLog({
          eventId: this.selectedEventId,
          token: this.userLogged.token,
          ticketId: ticketId,
        });
      } catch (error) {
        this.errorLog = true;
        this.errorMessageLog = error;
      }
      this.loadingLog = false;
    },
    async showRegistryLog(registryId) {
      //console.log(ticketId);
      this.ticketFound = null;
      const eventsAPI = new EventsAPI();
      this.loadingLog = true;
      this.errorLog = false;
      try {
        this.registryFound = await eventsAPI.getRegistriyPurchasedLog({
          eventId: this.selectedEventId,
          token: this.userLogged.token,
          registryId: registryId,
        });
      } catch (error) {
        this.errorLog = true;
        this.errorMessageLog = error;
      }
      this.loadingLog = false;
    },
  },
};
</script>
<style scoped>
h2,
h5 {
  text-align: center;
  margin-top: 2em;
}

#event-selector {
  margin: auto;
  width: 75%;
}

.content {
  margin: auto;
  width: 90%;
}
</style>