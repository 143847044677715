import { URLS } from "@/utils/constants";
import i18n from "@/i18n";

export default {

    async getCaptcha() {
        return await (await fetch(URLS.CAPTCHA)).text();
    },

    async checkReCaptcha(action, token, recaptchaSiteKey) {

        let response = await fetch(URLS.RE_CAPTCHA_ASSESSMENT, {
            method: "POST",
            body: JSON.stringify({
                event: {
                    expectedAction: action,
                    token: token,
                    siteKey: recaptchaSiteKey
                }
            }),
            headers: {
                "Content-Type": "application/json"
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            return;
        } else if (response.status === 409) { // 409 = API Error
            let error = await response.json();
            throw error
        }
        else { // Generic Error
            throw {
                code: response.status, mensaje: i18n.global.t('errors.generic')
            }
        }
    }
}