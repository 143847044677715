import { createRouter, createWebHistory } from 'vue-router'
import { useMainStore } from '@/stores/main';
/*import HomePage from '@/views/HomePage.vue'*/
import LoginPage from '@/views/LoginPage.vue'
import RegistrarBoletoPage from '@/views/RegistrarBoletoPage.vue'
import ReportesPage from '@/views/ReportesPage.vue'
import ListadoEntradasPage from '@/views/ListadoEntradasPage.vue'
import ListadoPagosPage from '@/views/ListadoPagosPage.vue'
import PerfilUsuarioPage from '@/views/PerfilUsuarioPage.vue'
import NoContentPage from '@/views/NoContentPage.vue'
import MisEventosPage from '@/views/MisEventosPage.vue'
import ListadoTransferenciasPage from '@/views/ListadoTransferenciasPage.vue'
import i18n from "@/i18n"

const routes = [
  /*{
    path: '/',
    name: 'home',
    component: HomePage,
  },*/
  {
    path: '/',
    name: 'home',
    component: LoginPage,
  },
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/reportes',
    component: ReportesPage,
    meta: {
      requiresOwner: true,
    }
  },
  {
    path: '/mis-eventos',
    component: MisEventosPage,
    meta: {
      requiresOwner: true,
    }
  },
  {
    path: '/listado-pagos',
    component: ListadoPagosPage,
    meta: {
      requiresOwner: true,
    }
  },
  {
    path: '/listado-transferencias',
    component: ListadoTransferenciasPage,
    meta: {
      requiresOwner: true,
    }
  },
  {
    path: '/listado-entradas',
    component: ListadoEntradasPage,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/registrar',
    component: RegistrarBoletoPage,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/perfil',
    component: PerfilUsuarioPage,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/:catchAll(.*)*',
    component: NoContentPage,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  const mainStore = useMainStore();

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth (either Staff or Owner), check if logged in
    // if not, redirect to login page.
    if (mainStore.userLogged) {
      next()
    } else {
      next("/login")
    }
  } else if (to.matched.some(record => record.meta.requiresOwner)) {
    // this route requires Owner auth only, check if logged in
    // if not, redirect to a not-found page.
    if (mainStore.userLogged && mainStore.userLogged.sessionType === 'OWNER') {
      next()
    } else {
      next("/not-found")
    }
  } else {
    mainStore.$patch({ pageTitle: i18n.global.t('titles.main') })
    next() // make sure to always call next()!
  }
})

export default router
