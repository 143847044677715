<template>
  <h3> {{ $t('entryReg.searchResults') }}</h3>
  <ui-card id="card-container" v-shadow.transition="[2, 8]">
    <div id="found-content" v-if="showData">
      <div class="header">
        <h4>{{ entryClassification }}</h4>
        <ui-icon-button @click="actualizaInformacion" class="icon">refresh</ui-icon-button>
      </div>
      <ui-divider></ui-divider>
      <ui-form item-margin-bottom="10" action-align="right">
        <template #default="{ itemClass, actionClass }">
          <ui-form-field class="row" :class="itemClass">
            <label class="cell-key"> {{ $t('filters.sn') }}:</label>
            <label class="cell-value">{{ folio }}</label>
          </ui-form-field>
          <ui-form-field class="row" :class="itemClass">
            <label class="cell-key"> {{ entryTypeLbl }}:</label>
            <label class="cell-value"> {{ entryType }}</label>
          </ui-form-field>
          <ui-form-field class="row" :class="itemClass">
            <label class="cell-key"> {{ $t('filters.attendee') }}:</label>
            <label class="cell-value"> {{ entryName }}</label>
          </ui-form-field>
          <ui-form-field class="row" :class="itemClass">
            <label class="cell-key">{{ $t('filters.groupCompany') }}:</label>
            <label class="cell-value"> {{ grupoCompania }}</label>
          </ui-form-field>
          <ui-form-field class="row" :class="itemClass">
            <label class="cell-key">{{ $t('filters.status') }}:</label>
            <label class="cell-value" :class="entryRegisteredClass">{{ entryRegisteredTitle }}
            </label>
          </ui-form-field>
          <!-- Botones -->
          <ui-form-field class="row" :class="itemClass">
            <ui-divider></ui-divider>
          </ui-form-field>
          <ui-form-field class="row" :class="itemClass">
            <label class="cell-disclaimer">{{ $t('entryReg.entranceDisclaimer') }}</label>
          </ui-form-field>
          <ui-form-field class="buttons" :class="actionClass">
            <ui-button native-type="submit" :disabled="formLoading || canBeMarkedAsEntered"
              @click.prevent="onCancelClicked">{{ $t('actions.cancelEntrance') }}
              <template #after="{ iconClass }">
                <ui-icon :class="iconClass">cancel</ui-icon>
              </template>
            </ui-button>
            <ui-button raised native-type="submit" :disabled="formLoading || !canBeMarkedAsEntered"
              @click.prevent="marcarIngreso"> {{ $t('actions.markEntrance') }}
              <template #after="{ iconClass }">
                <ui-icon :class="iconClass">check</ui-icon>
              </template>
            </ui-button>
          </ui-form-field>
        </template>
      </ui-form>
      <ui-progress v-show="formLoading" indeterminate active></ui-progress>
      <error-component v-show="errorResultado" :message="errorMessage" />
    </div>
    <div id="no-content-found" v-else>
      <p> {{ $t('commonElements.noResults') }}</p>
    </div>
  </ui-card>
  <ui-dialog v-model="openDialog" fullscreen>
    <ui-dialog-title> {{ $t('entryReg.cancelEntranceConfirm', [entryClassification, entryType]) }}
    </ui-dialog-title>
    <ui-dialog-content>
      <p> {{ $t('entryReg.cancelEntranceDetails') }}</p>
      <ui-textfield outlined input-type="textarea" required maxlength="300" rows="3" cols="100" with-counter fullwidth
        v-model="razonCancelacion" @keydown="errorValidacion = false" />
      <error-component v-show="errorValidacion" :message="this.$t('validations.fieldRequired')" />
      <ui-progress v-show="formLoading" indeterminate active></ui-progress>
    </ui-dialog-content>
    <ui-dialog-actions>
      <ui-button @click.prevent="onConfirmCancelaRegistro"> {{ $t('actions.confirmCancellation') }}</ui-button>
    </ui-dialog-actions>
  </ui-dialog>
</template>
<script>
import EventsAPI from "@/api/EventsAPI.js";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import { useMainStore } from '@/stores/main';
import { mapState } from 'pinia';
import { helpers } from "balm-ui";

export default {
  name: "RegistrarEntradaResult",
  props: {
    ticketFound: Object,
    registryFound: Object,
  },
  components: {
    ErrorComponent,
  },
  emits: ["refreshTicket", "refreshRegistry"],
  data() {
    return {
      formLoading: false,
      errorResultado: false,
      errorMessage: "",
      openDialog: false,
      razonCancelacion: "",
      errorValidacion: false,
    };
  },
  computed: {
    ...mapState(useMainStore, ["userLogged", "selectedEventId", "defaultLanguage"]),
    showData() {
      return this.ticketFound || this.registryFound;
    },
    entryClassification() {
      if (this.ticketFound) {
        return this.$t('commonElements.ticket');
      } else if (this.registryFound) {
        return this.$t('commonElements.registry');
      } else {
        return "";
      }
    },
    entryTypeLbl() {
      if (this.ticketFound) {
        return this.$t('filters.ticketType');
      } else if (this.registryFound) {
        return this.$t('filters.registerType');
      } else {
        return "";
      }
    },
    folio() {
      if (this.ticketFound) {
        return this.ticketFound.ticketId;
      } else if (this.registryFound) {
        return this.registryFound.registryId;
      } else {
        return "";
      }
    },
    entryType() {
      if (this.ticketFound) {
        return this.ticketFound.ticketType;
      } else if (this.registryFound) {
        return this.defaultLanguage === 'es' ? this.registryFound.fullRegisterTypeName : this.registryFound.fullRegisterTypeNameEn
      } else {
        return "";
      }
    },
    entryName() {
      if (this.ticketFound) {
        return this.ticketFound.ticketName;
      } else if (this.registryFound) {
        return this.registryFound.participantName;
      } else {
        return "";
      }
    },
    grupoCompania() {
      if (this.ticketFound) {
        return this.ticketFound.companyGroup;
      } else if (this.registryFound) {
        return this.registryFound.companyGroup;
      } else {
        return "";
      }
    },
    entryRegisteredClass() {
      if (this.ticketFound) {
        return this.ticketFound.hasBeenRegistered
          ? "registered"
          : "notregistered";
      } else if (this.registryFound) {
        return this.registryFound.hasBeenRegistered
          ? "registered"
          : "notregistered";
      } else {
        return "";
      }
    },
    entryRegisteredTitle() {
      if (this.ticketFound) {
        return this.ticketFound.hasBeenRegistered
          ? this.$t('entryReg.entered')
          : this.$t('entryReg.notEntered');
      } else if (this.registryFound) {
        return this.registryFound.hasBeenRegistered
          ? this.$t('entryReg.entered')
          : this.$t('entryReg.notEntered');
      } else {
        return "";
      }
    },
    canBeMarkedAsEntered() {
      if (this.ticketFound) {
        return !this.ticketFound.hasBeenRegistered;
      } else if (this.registryFound) {
        return !this.registryFound.hasBeenRegistered;
      } else {
        return false;
      }
    },
  },
  methods: {
    onCancelClicked() {
      this.openDialog = true;
      this.razonCancelacion = "";
    },
    async actualizaInformacion() {
      if (this.showData) {
        this.formLoading = true;
        this.errorResultado = false;
        const eventsAPI = new EventsAPI();

        try {
          if (this.ticketFound) {
            const ticketData = await eventsAPI.searchTicketManual({
              token: this.userLogged.token,
              ticketPurchasedId: this.ticketFound.ticketId,
              eventId: this.selectedEventId,
            });
            //console.log(ticketData);
            this.$emit("refreshTicket", ticketData);
            this.$emit("refreshRegistry", null);
          } else if (this.registryFound) {
            const registryData = await eventsAPI.searchRegistroManual({
              token: this.userLogged.token,
              registryId: this.registryFound.registryId,
              eventId: this.selectedEventId,
            });
            //console.log(registryData);
            this.$emit("refreshRegistry", registryData);
            this.$emit("refreshTicket", null);
          }

          this.formLoading = false;
        } catch (error) {
          this.formLoading = false;
          this.errorMessage = error;
          this.errorResultado = true;
        }
      }
    },
    async marcarIngreso() {
      if (this.showData) {
        this.formLoading = true;
        this.errorResultado = false;
        const eventsAPI = new EventsAPI();

        try {
          if (this.ticketFound) {
            const ticketData = await eventsAPI.marcaIngresoTicket({
              token: this.userLogged.token,
              ticketPurchasedId: this.ticketFound.ticketId,
              eventId: this.selectedEventId,
            });
            //console.log(ticketData);
            this.$emit("refreshTicket", ticketData);
            this.$emit("refreshRegistry", null);
          } else if (this.registryFound) {
            const registryData = await eventsAPI.marcaIngresoRegistro({
              token: this.userLogged.token,
              registryId: this.registryFound.registryId,
              eventId: this.selectedEventId,
            });
            //console.log(registryData);
            this.$emit("refreshRegistry", registryData);
            this.$emit("refreshTicket", null);
          }

          this.formLoading = false;
        } catch (error) {
          this.formLoading = false;
          this.errorMessage = error;
          this.errorResultado = true;
        }
      }
    },
    async onConfirmCancelaRegistro() {
      //console.log(this.razonCancelacion);
      if (helpers.isEmpty(this.razonCancelacion)) {
        this.errorValidacion = true;
      } else {
        this.formLoading = true;
        this.errorResultado = false;
        const eventsAPI = new EventsAPI();

        try {
          if (this.ticketFound) {
            const ticketData = await eventsAPI.cancelaIngresoTicket({
              token: this.userLogged.token,
              ticketPurchasedId: this.ticketFound.ticketId,
              eventId: this.selectedEventId,
              detalle: this.razonCancelacion,
            });
            //console.log(ticketData);
            this.$emit("refreshTicket", ticketData);
            this.$emit("refreshRegistry", null);
          } else if (this.registryFound) {
            const registryData = await eventsAPI.cancelaIngresoRegistro({
              token: this.userLogged.token,
              registryId: this.registryFound.registryId,
              eventId: this.selectedEventId,
              detalle: this.razonCancelacion,
            });
            //console.log(registryData);
            this.$emit("refreshRegistry", registryData);
            this.$emit("refreshTicket", null);
          }

          this.formLoading = false;
          this.openDialog = false;
        } catch (error) {
          this.formLoading = false;
          this.errorMessage = error;
          this.errorResultado = true;
        }
      }
    },
  },
};
</script>
<style scoped>
h3 {
  text-align: center;
  margin-top: 1.5em;
}

#card-container {
  padding: 1.2em;
  margin: 1em;
  min-height: 455px;
}

#no-content-found {
  margin: 0 auto;
  margin-top: 170px;
}

.header {
  position: relative;
}

.header h4 {
  text-align: center;
}

.header .icon {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
}

.row {
  margin-top: 20px;
}

.cell-key {
  font-weight: bold;
  font-size: 1.2em;
  width: 30%;
}

.cell-value {
  font-size: 1.2em;
  width: 70%;
}

.cell-value.notregistered {
  font-weight: bold;
  color: #ccc;
}

.cell-value.registered {
  font-weight: bold;
  color: #006600;
}

.cell-disclaimer {
  font-weight: bold;
}

.buttons {
  margin-top: 40px;
}
</style>