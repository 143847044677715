const localeString = 'es-MX'
const currency = 'MXN'

export default {


    dateToTDString(stringDate) {
        const date = new Date(stringDate)
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        const hours = date.getHours()
        const minutes = date.getMinutes()

        return year + '-' +
            month.toString().padStart(2, '0') + '-' +
            day.toString().padStart(2, '0') + ' ' +
            hours.toString().padStart(2, '0') + ':' +
            minutes.toString().padStart(2, '0') +
            ':00'
    },

    formatCurrency(amount) {
        return amount.toLocaleString(localeString, { style: 'currency', currency: currency, maximumFractionDigits: 2, minimumFractionDigits: 2 })

    },

    formatPct(amount) {
        return amount.toLocaleString(localeString, { maximumFractionDigits: 2, minimumFractionDigits: 2 })

    },

    formatPctFee(amount) {
        return amount.toLocaleString(localeString, { maximumFractionDigits: 1, minimumFractionDigits: 1 })

    }
}