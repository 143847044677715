<template>
    <top-bar>
        <router-view />
    </top-bar>
</template>

<script>
// @ is an alias to /src
import TopBar from "@/components/common/TopBar.vue";

export default {
    name: 'SidebarContentLayout',
    components: {
        TopBar,
    },
    mounted() {
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', `https://www.google.com/recaptcha/enterprise.js?render=${process.env.VUE_APP_RECAPTCHA_KEY}`)
        document.head.appendChild(recaptchaScript)
    },
}

</script>

<style>
aside.mdc-drawer {
    width: 320px;
}
</style>