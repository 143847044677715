const BASE_URL = process.env.VUE_APP_BASE_API_URL;
//const BASE_URL = 'https://ticketdancer-api.com/';
const OWNER_RELATIVE_PATH = "ownerservices/";
const STAFF_RELATIVE_PATH = "staffservices/";
const TICKETS_RELATIVE_PATH = "ticketservices/"
const TICKETS_PURCHASED_RELATIVE_PATH = "tickets_purchased_service/"
const REGISTRY_RELATIVE_PATH = "promoter_registry_services/"
const PAYMENT_TICKETS_RELATIVE_PATH = "payservices/"
const PAYMENT_REGISTRY_RELATIVE_PATH = "registryservices/"
const EVENTS_RELATIVE_PATH = "eventservices/"
const REGISTER_TYPE_RELATIVE_PATH = "register_type_services/"
const REGISTER_TYPE_CLASSIFICATIONS_RELATIVE_PATH = "register_type_classification_services/"
const PAYMENT_BANK_TRANSFERS_RELATIVE_PATH = "payment_bank_transfer/"
const REGISTRY_PAYMENT_BANK_TRANSFERS_RELATIVE_PATH = "registry_payment_bank_transfer/"

export const URLS = Object.freeze({
    BASE_URL: BASE_URL,
    CAPTCHA: BASE_URL + "commons/capcha",
    OWNER_LOGIN: BASE_URL + OWNER_RELATIVE_PATH + "login",
    STAFF_LOGIN: BASE_URL + STAFF_RELATIVE_PATH + "login",
    OWNER_GET_USER_DATA: BASE_URL + OWNER_RELATIVE_PATH + "get_owner_data",
    STAFF_GET_USER_DATA: BASE_URL + STAFF_RELATIVE_PATH + "get_staff_data",
    OWNER_PASS_VALIDATION: BASE_URL + OWNER_RELATIVE_PATH + "password_validation",
    STAFF_PASS_VALIDATION: BASE_URL + STAFF_RELATIVE_PATH + "password_validation",
    OWNER_UPDATE_USER_DATA: BASE_URL + OWNER_RELATIVE_PATH + "update",
    STAFF_UPDATE_USER_DATA: BASE_URL + STAFF_RELATIVE_PATH + "update",
    OWNER_GET_EVENTS_DATA: BASE_URL + OWNER_RELATIVE_PATH + "get_events_owner_data",
    STAFF_GET_EVENTS_DATA: BASE_URL + STAFF_RELATIVE_PATH + "get_events_staff_data",
    SEARCH_TICKET_QR: BASE_URL + TICKETS_PURCHASED_RELATIVE_PATH + "search_ticket_qr_code",
    SEARCH_REGISTRY_QR: BASE_URL + REGISTRY_RELATIVE_PATH + "search_registry_qr_code",
    SEARCH_TICKET_MANUAL: BASE_URL + TICKETS_PURCHASED_RELATIVE_PATH + "search_ticket_manual",
    SEARCH_REGISTRY_MANUAL: BASE_URL + REGISTRY_RELATIVE_PATH + "search_registry_manual",
    MARK_TICKET: BASE_URL + TICKETS_PURCHASED_RELATIVE_PATH + "mark_ticket",
    MARK_REGISTRY: BASE_URL + REGISTRY_RELATIVE_PATH + "mark_registry",
    CANCEL_TICKET: BASE_URL + TICKETS_PURCHASED_RELATIVE_PATH + "update_mark_ticket",
    CANCEL_REGISTRY: BASE_URL + REGISTRY_RELATIVE_PATH + "update_mark_registry",
    GET_TICKETS_PURCHASED: BASE_URL + TICKETS_PURCHASED_RELATIVE_PATH + "list_tickets_purchased",
    GET_REGISTRIES_PURCHASED: BASE_URL + REGISTRY_RELATIVE_PATH + "list_registries_purchased",
    GET_TICKET_PURCHASED_LOG: BASE_URL + TICKETS_PURCHASED_RELATIVE_PATH + "list_log_ticket_purchased",
    GET_REGISTRY_PURCHASED_LOG: BASE_URL + REGISTRY_RELATIVE_PATH + "list_log_registry",
    GET_TICKETS_REPORT: BASE_URL + TICKETS_PURCHASED_RELATIVE_PATH + "reporteVentas",
    GET_REGISTRIES_REPORT: BASE_URL + REGISTRY_RELATIVE_PATH + "reporteVentas",
    GET_PAYMENT_TICKETS: BASE_URL + PAYMENT_TICKETS_RELATIVE_PATH + "get_payments_by_event",
    DOWNLOAD_PAYMENT_TICKETS: BASE_URL + PAYMENT_TICKETS_RELATIVE_PATH + "download_payments_by_event",
    GET_PAYMENT_REGISTRIES: BASE_URL + PAYMENT_REGISTRY_RELATIVE_PATH + "get_registry_payments_by_event",
    DOWNLOAD_PAYMENT_REGISTRIES: BASE_URL + PAYMENT_REGISTRY_RELATIVE_PATH + "download_registry_payments_by_event",
    RE_CAPTCHA_ASSESSMENT: BASE_URL + "commons/re-captcha-assessment",
    DOWNLOAD_TICKETS_REPORT: BASE_URL + TICKETS_PURCHASED_RELATIVE_PATH + "downloadReporteVentas",
    DOWNLOAD_REGISTRIES_REPORT: BASE_URL + REGISTRY_RELATIVE_PATH + "downloadReporteVentas",
    DOWNLOAD_TICKETS_PURCHASED: BASE_URL + TICKETS_PURCHASED_RELATIVE_PATH + "download_tickets_purchased",
    DOWNLOAD_REGISTRIES_PURCHASED: BASE_URL + REGISTRY_RELATIVE_PATH + "download_registries_purchased",
    GET_EVENT_BY_ID: BASE_URL + OWNER_RELATIVE_PATH + "get_event_by_id",
    CREATE_EVENT: BASE_URL + EVENTS_RELATIVE_PATH + "create",
    UPDATE_EVENT: BASE_URL + EVENTS_RELATIVE_PATH + "update",
    DELETE_EVENT: BASE_URL + EVENTS_RELATIVE_PATH + "delete",
    UPLOAD_EVENT_IMAGE: BASE_URL + EVENTS_RELATIVE_PATH + "upload_image",
    REQUEST_EVENT_PUBLISH: BASE_URL + EVENTS_RELATIVE_PATH + "request_publish",
    UPDATE_TICKETS_ORDER: BASE_URL + TICKETS_RELATIVE_PATH + "update_order",
    CREATE_TICKET: BASE_URL + TICKETS_RELATIVE_PATH + "create",
    UPDATE_TICKET: BASE_URL + TICKETS_RELATIVE_PATH + "update",
    ACTIVATE_TICKET: BASE_URL + TICKETS_RELATIVE_PATH + "activate",
    DEACTIVATE_TICKET: BASE_URL + TICKETS_RELATIVE_PATH + "deactivate",
    DELETE_TICKET: BASE_URL + TICKETS_RELATIVE_PATH + "delete",
    UPDATE_REGISTRATION_ORDER: BASE_URL + REGISTER_TYPE_RELATIVE_PATH + "update_order",
    CREATE_REGISTER_TYPE: BASE_URL + REGISTER_TYPE_RELATIVE_PATH + "create",
    UPDATE_REGISTER_TYPE: BASE_URL + REGISTER_TYPE_RELATIVE_PATH + "update",
    ACTIVATE_REGISTER_TYPE: BASE_URL + REGISTER_TYPE_RELATIVE_PATH + "activate",
    DEACTIVATE_REGISTER_TYPE: BASE_URL + REGISTER_TYPE_RELATIVE_PATH + "deactivate",
    DELETE_REGISTER_TYPE: BASE_URL + REGISTER_TYPE_RELATIVE_PATH + "delete",
    GET_ALL_REGISTER_TYPE_CLASSIFICATIONS: BASE_URL + REGISTER_TYPE_CLASSIFICATIONS_RELATIVE_PATH + "list_all",
    GET_PAYMENT_BANK_TRANSFERS_BY_EVENT: BASE_URL + PAYMENT_BANK_TRANSFERS_RELATIVE_PATH + "get_payment_bank_transfers_by_event",
    GET_REGISTRY_PAYMENT_BANK_TRANSFERS_BY_EVENT: BASE_URL + REGISTRY_PAYMENT_BANK_TRANSFERS_RELATIVE_PATH + "get_registry_payment_bank_transfers_by_event"
})

export const RECAPTCHA_ACTIONS = Object.freeze({
    PAGE_LOAD_LOGIN_CREATE: "PAGE_LOAD_LOGIN_CREATE",
    LOGIN: "LOGIN"
})

export const LS_KEYS = Object.freeze({
    USER_LOGIN_KEY: "ticketdancer_user_key",
    USER_DEFAULT_LOCALE: "ticketdancer_producer_def_locale"
})