export default {
  "titles": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido a Ticket Dancer for Business"])},
    "mainSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El sitio donde podrás administrar tu evento de Ticket Dancer"])},
    "logEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar entrada"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustes de perfil"])},
    "listOfEntries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listado de entradas"])},
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reportes"])},
    "listOfPurchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listado de compras"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salir de la sesión"])},
    "myEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrar mis eventos"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenid", "@"])},
    "generalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información general"])},
    "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imágenes"])},
    "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boletos"])},
    "otherPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otros pagos y registros"])},
    "listOfBankTransfers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listado de Transferencias Bancarias"])}
  },
  "subtitles": {
    "newEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un nuevo evento en Ticket Dancer"])},
    "listOfRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisa todas las entradas de tu evento"])},
    "entryReg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registra las entradas al evento"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiza tus datos"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenid", "@", ","])},
    "listOfBankTransfers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisa todas las transferencias bancarias de tu evento"])}
  },
  "commonElements": {
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento"])},
    "entryType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de entrada"])},
    "selectEntryType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona tipo de entrada"])},
    "ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boleto"])},
    "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boletos"])},
    "registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago o registro"])},
    "registries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otros pagos y registros"])},
    "selectEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona evento"])},
    "selectOtherPaymentRegistry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona tipo de pago o registro"])},
    "fixedPaymentInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Se debe mencionar en el Nombre del pago a qué boleto corresponde dicho pago (ejemplo FULL PASS), y en la Descripción cuántos pagos fijos son necesarios para liquidar el boleto completo (ejemplo 3 pagos)."])},
    "lodgingInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Se debe mencionar en el Nombre del pago a qué tipo de habitación corresponde (ejemplo HABITACIÓN CUÁDRUPLE). Si se trata de un PAQUETE que incluye tanto el BOLETO al Evento como el HOSPEDAJE, se debe agregar como BOLETO en vez de como Pago o Registro. Proporcionar todos los detalles adicionales, notas y condiciones en la Descripción del registro."])},
    "competitionInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Se debe mencionar en el Nombre del pago a qué tipo de inscripción corresponde (ejemplo SALSA PRO-AM). Proporcionar todos los detalles adicionales, notas y condiciones en la Descripción del registro."])},
    "initialPaymentInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Se debe mencionar en el Nombre del pago a qué boleto corresponde dicho apartado (ejemplo FULL PASS). Proporcionar todos los detalles adicionales, notas y condiciones en la Descripción del pago."])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activos"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivos"])},
    "saveOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar orden"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
    "quantityAdjustment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajuste de cantidades"])},
    "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajuste de precios"])},
    "salePriceMXN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio de Venta al Público (MXN)"])},
    "feeMXN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión calculada (MXN)"])},
    "salePriceUSD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio de Venta al Público (USD)"])},
    "feeUSD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión calculada (USD)"])},
    "amountReceivedMXN": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Monto que recibes por unidad (MXN): ", _interpolate(_list(0)), " *"])},
    "amountReceivedUSD": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Monto que recibes por unidad (USD): ", _interpolate(_list(0)), " *"])},
    "netMXN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total neto del Productor"])},
    "feeNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisiones calculadas automáticamente"])},
    "mxnRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango de precios en pesos mexicanos (MXN)"])},
    "usdRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango de precios en dólares estadounidenses (USD)"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "noInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin información"])},
    "tbd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por definir"])},
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron resultados"])}
  },
  "filters": {
    "filterBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar por"])},
    "searchBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criterio de búsqueda"])},
    "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# de Orden"])},
    "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método de pago"])},
    "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de tarjeta"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moneda de pago"])},
    "ticketType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de boleto"])},
    "registerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de registro"])},
    "groupCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo o Compañía de baile"])},
    "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de descuento"])},
    "tdUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo de usuario de Ticket Dancer"])},
    "sn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folio"])},
    "attendee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del asistente"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estatus"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudad"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edad"])},
    "genre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Género"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])}
  },
  "headers": {
    "paymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de pago (año-mes-día)"])},
    "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# de Orden de pago"])},
    "ticketNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# de boletos"])},
    "registerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# de registros"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de pago"])},
    "netAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad neta recibida"])},
    "couponName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de descuento"])},
    "couponAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad del código de descuento"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregunta 1"])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregunta 2"])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregunta 3"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuesta 1"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuesta 2"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuesta 3"])},
    "purchaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de compra (año-mes-día)"])},
    "log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial"])},
    "logDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha del movimiento (año-mes-día)"])},
    "logType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de movimiento"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles"])},
    "enteredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hecho por"])},
    "withMSI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MSI habilitados"])},
    "transferDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de transferencia (año-mes-día)"])},
    "bankTransferComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Pago total liquidado?"])},
    "depositedAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad depositada"])},
    "bankReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencia bancaria"])}
  },
  "home": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicia sesión"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea tu cuenta"])},
    "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Dancer® es tu pareja ideal"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acerca de"])},
    "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precios"])},
    "quote1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Nunca había trabajado tan cómodo en un evento!"])},
    "quote2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La facilidad de ver cómo van las ventas en cualquier momento me permite tomar decisiones oportunas"])},
    "subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comienza a vender en tres sencillos pasos"])},
    "step1title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea tu cuenta"])},
    "step1message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólo necesitas un correo electrónico válido o una cuenta en Gmail o tu Apple ID para iniciar"])},
    "step2title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa tus datos"])},
    "step2message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configura tu cuenta bancaria en Stripe® en donde recibirás directamente el dinero de tus ventas"])},
    "step3title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea tu evento"])},
    "step3message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa la información de tu evento y comienza a venderlo en nuestra plataforma"])},
    "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu experiencia como Productor"])},
    "realTimeReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reportes de ventas y transacciones en tiempo real"])},
    "discountCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generación de códigos de descuento"])},
    "customTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boletos personalizados a tu evento"])},
    "qrScan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control de accesos por scanner QR"])},
    "competitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro de competencias y otros conceptos"])},
    "diffusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Difusión de tu evento en nuestra comunidad"])},
    "paymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptas tarjetas, parcialidades, transferencias y más"])},
    "focus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te enfocas en tu evento y nosotros en el Servicio al Cliente"])},
    "subtitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Somos especialistas en eventos dancísticos!"])},
    "unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Único"])},
    "uniqueMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No existe otro sistema especializado en la administración de un congreso dancístico"])},
    "tested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probado"])},
    "testedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenemos años operando en el medio dancístico, sin contratiempos. Nuestros clientes lo pueden avalar"])},
    "reliable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confiable"])},
    "reliableMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacciones comerciales seguras con el soporte de nuestro aliado Stripe®, con capacidad para cualquier evento"])},
    "authentic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auténtico"])},
    "authenticMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseñado por bailarines, productores y profesionistas apasionados especialistas en su área con experiencia global"])},
    "successStories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historias de éxito"])}
  },
  "pricing": {
    "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo hicimos simple para ti"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se aplica un plan de servicio basado en un porcentaje de comisión según el precio de venta del boleto o del registro. Dicho plan se aplica individualmente a cada boleto o registro"])},
    "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Básico"])},
    "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estándar"])},
    "preference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferencial"])},
    "calulatorHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulador de ventas"])},
    "publicPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio de venta"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
    "feePerTicket": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Plan aplicado: ", _interpolate(_list(0)), " (", _interpolate(_list(1)), ")"])},
    "amountReceived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Monto que recibes por unidad: ", _interpolate(_list(0)), " ", _interpolate(_list(1))])},
    "totalReceived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Total recibido: ", _interpolate(_list(0)), " ", _interpolate(_list(1))])},
    "stripeDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* No se incluye la comisión de"])},
    "ally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestro aliado de Negocios - Stripe™"])},
    "stripeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para garantizar que las compras en línea a través de Ticket Dancer™ cumplan con los requisitos de seguridad y de confianza, usamos la herramienta mundialmente reconocida Stripe™."])},
    "stripeDescription2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias a eso podrás manejar muchísimas formas de pago para tus clientes de manera fácil y segura:"])}
  },
  "myEvents": {
    "dateField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha del evento (una vez publicado no se puede editar):"])},
    "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de inicio:"])},
    "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de fin:"])},
    "linkField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link del evento en Ticket Dancer. Sólo se permiten letras, números y guiones medios. No se permiten acentos ni signos de puntuación. (Ejemplo: mi-gran-evento-2023). Una vez creado el Evento no se puede modificar"])},
    "eventName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del evento"])},
    "youtubeLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link de video en YouTube"])},
    "eventDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción del evento (ESPAÑOL)"])},
    "eventDescriptionEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción del evento (INGLÉS)"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubicación"])},
    "locationField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa un lugar"])},
    "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicado"])},
    "notPublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO publicado"])},
    "mainImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagen principal (Tamaño recomendado: 800px * 500px)"])},
    "bannerImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner del evento (Tamaño recomendado: 800px * 400px)"])},
    "logoImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo del Productor o del Evento (Tamaño recomendado: 500px * 400px)"])},
    "successImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La imagen se ha actualizado exitosamente"])},
    "createEventConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Deseas crear el Evento?"])},
    "createEventDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ten en cuenta que habrá campos que ya no podrás modificar después"])},
    "successCreateEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El evento se ha creado exitosamente"])},
    "successUpdateEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El evento se ha actualizado exitosamente"])},
    "deleteEventConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Deseas eliminar el Evento?"])},
    "deleteEventDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toda la información del Evento se perderá y ya no la podrás recuperar"])},
    "successDeleteEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El evento se ha eliminado exitosamente"])},
    "publishConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Deseas solicitar la publicación de tu Evento?"])},
    "publishDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Dancer validará la información y te notificará cuando se publique el Evento"])},
    "successPublish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha solicitado la publicación de tu Evento. Te mantendremos informado."])},
    "enableMSI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habilitar Meses Sin Intereses en todo el evento (aplica a todos los Boletos y Otros Pagos y Registros)"])}
  },
  "tickets": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear nuevo boleto"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del boleto"])},
    "maxLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Límite máximo de boletos"])},
    "userTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Límite de boletos por usuario"])},
    "attendeesPerTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# de asistentes por boleto"])},
    "mandatoryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre obligatorio"])},
    "ticketsPurchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boletos comprados"])},
    "mandatoryNameForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Nombre del asistente obligatorio?"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boleto activo"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boleto inactivo"])},
    "ticketDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción del boleto (ESPAÑOL)"])},
    "ticketDescriptionEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción del boleto (INGLÉS)"])},
    "editDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiza la información de tu boleto (algunos campos pueden estar deshabilitados por seguridad)"])},
    "purchasedDeletionError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["El boleto '", _interpolate(_list(0)), "' no puede ser eliminado debido a que ya tiene compras registradas"])},
    "confirmDelete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["¿Deseas ELIMINAR el boleto '", _interpolate(_list(0)), "'?"])},
    "deleteConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El boleto se ha eliminado exitosamente"])},
    "confirmDisable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["¿Deseas DESHABILITAR el boleto '", _interpolate(_list(0)), "'?"])},
    "disableDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El boleto NO se mostrará como opción para los usuarios"])},
    "disableConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El boleto se deshabilitó exitosamente. Ya no se mostrará a los usuarios"])},
    "confirmEnable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["¿Deseas HABILITAR el boleto '", _interpolate(_list(0)), "'?"])},
    "enableDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El boleto se mostrará como opción para los usuarios"])},
    "enableConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El boleto se habilitó exitosamente. Ahora se mostrará a los usuarios"])},
    "duplicateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplica un boleto de tu evento"])},
    "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrega un nuevo boleto a tu evento"])},
    "addConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El boleto se ha creado exitosamente"])},
    "updateConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El boleto se ha actualizado exitosamente"])},
    "enableMSI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habilitar Meses Sin Intereses para este boleto"])}
  },
  "otherPayments": {
    "fixedPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAGOS FIJO"])},
    "accommodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOSPEDAJE"])},
    "competition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPETENCIA"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTRO"])},
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear nuevo pago o registro"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del pago o registro"])},
    "question1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregunta 1 (opcional)"])},
    "question2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregunta 2 (opcional)"])},
    "question3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregunta 3 (opcional)"])},
    "maxLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Límite máximo de pagos o registros"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de pago registro"])},
    "optionalQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas opcionales"])},
    "registries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagos o registros comprados"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago o registro activo"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago o registro inactivo"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción del pago o registro (ESPAÑOL)"])},
    "descriptionEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción del pago o registro (INGLÉS)"])},
    "editDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiza la información de tu pago o registro (algunos campos pueden estar deshabilitados por seguridad)"])},
    "duplicateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplica un pago o registro de tu evento"])},
    "confirmDisable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["¿Deseas DESHABILITAR el pago o registro '", _interpolate(_list(0)), "'?"])},
    "disableDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El pago o registro NO se mostrará como opción para los usuarios"])},
    "disableConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El pago o registro se deshabilitó exitosamente. Ya no se mostrará a los usuarios"])},
    "confirmEnable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["¿Deseas HABILITAR el pago o registro '", _interpolate(_list(0)), "'?"])},
    "enableDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El pago o registro se mostrará como opción para los usuarios"])},
    "enableConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El pago o registro se habilitó exitosamente. Ahora se mostrará a los usuarios"])},
    "purchasedDeletionError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["El pago o registro '", _interpolate(_list(0)), "' no puede ser eliminado debido a que ya tiene compras registradas"])},
    "confirmDelete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["¿Deseas ELIMINAR el pago o regustro '", _interpolate(_list(0)), "'?"])},
    "deleteConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El pago o registro se ha eliminado exitosamente"])},
    "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrega un nuevo pago o registro a tu evento"])},
    "addConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El pago o registro se ha creado exitosamente"])},
    "updateConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El pago o registro se ha actualizado exitosamente"])},
    "enableMSI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habilitar Meses Sin Intereses para este registro"])}
  },
  "reports": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporte general de tus eventos"])},
    "otherPaymentsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporte de otros Pagos y Registros"])},
    "ticketsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporte de boletos"])},
    "filterEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar por evento"])},
    "filterRegistry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar por pago o registro"])},
    "filterTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar por boleto"])},
    "eventName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del evento"])},
    "ticketTypeQty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de boleto: Cantidad"])},
    "registrationTypeQty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de registro: Cantidad"])}
  },
  "purchases": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisa todas las compras realizadas de tu evento"])},
    "ticketsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listado de compras realizadas de boletos"])},
    "registriesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listado de otros Pagos y Registros realizados"])},
    "stripeLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a cuenta de Stripe"])},
    "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta"])},
    "oxxo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OXXO"])},
    "bankTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia Bancaria"])},
    "listOfTicketsPurchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listado de boletos comprados"])},
    "listOfOtherPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listado de otros pagos y registros comprados"])},
    "hasEntered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Ha ingresado?"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masculino"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femenino"])}
  },
  "entryReg": {
    "logTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de movimientos"])},
    "entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTRADA"])},
    "cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CANCELACIÓN DE ENTRADA"])},
    "entryDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se registra el ingreso al evento"])},
    "registerEntranceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa los datos del Boleto o del Registro"])},
    "typeEntrySelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona el tipo de entrada:"])},
    "searchSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar entrada por:"])},
    "qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código QR"])},
    "searchResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados de la búsqueda"])},
    "entered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* MARCADO COMO INGRESADO"])},
    "notEntered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* NO INGRESADO"])},
    "entranceDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Valida cuidadosamente todos los datos antes de proceder"])},
    "cancelEntranceConfirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["¿Cancelar el ingreso del ", _interpolate(_list(0)), ": ", _interpolate(_list(1)), "?"])},
    "cancelEntranceDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe la razón de la Cancelación"])},
    "reader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lector de QR conectado"])},
    "camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cámara del dispositivo"])},
    "selectInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione el método de lectura de QR"])},
    "loadingCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando cámara..."])}
  },
  "user": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa con cuenta de"])},
    "producer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productor"])},
    "staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "rights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Derechos reservados de Ticket Dancer® ", _interpolate(_list(0))])},
    "names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre(s)"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellidos"])},
    "passwordUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar contraseña"])},
    "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña actual"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña"])},
    "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar nueva contraseña"])},
    "updateConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu datos se han guardado exitosamente"])}
  },
  "actions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicar"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habilitar"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deshabilitar"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar"])},
    "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar"])},
    "moveDown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover abajo"])},
    "moveUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover arriba"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "createEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear nuevo evento"])},
    "requestPublish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar publicación"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subir"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpiar"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
    "markEntrance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar ingreso"])},
    "cancelEntrance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar ingreso"])},
    "confirmCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar cancelación"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])}
  },
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["es requerido"])},
    "fieldRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo es requerido"])},
    "greaterThanZero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["debe ser mayor a 0"])},
    "tooBig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" es muy grande"])},
    "lessThan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" debe ser menor que "])},
    "cannotUndo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta acción no se puede revertir"])},
    "imageSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tamaño de la imagen no debe ser mayor a 5 MB"])},
    "eventLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Link del Evento no es válido. Sólo se permiten letras, números y guiones medios. No se permiten acentos ni espacios"])},
    "allDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisa que hayas ingresado ambas Fechas del evento (inicio y fin)"])},
    "endDateGreaterThanStartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisa que la Fecha de término ocurra después de la Fecha de inicio del evento"])},
    "endDatePast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisa que la Fecha de Fin del Evento no ocurra en el pasado"])},
    "eventNameMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre del evento es obligatorio"])},
    "eventDescriptionMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La descripción del evento es obligatoria"])},
    "eventDescriptionLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La descripción del Evento (ESPAÑOL) debe ser menor a 4000 caracteres"])},
    "eventDescriptionLimitEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La descripción del Evento (INGLÉS) debe ser menor a 4000 caracteres"])},
    "eventLocationMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La ubicación del evento es obligatoria"])},
    "passwordRequirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" debe tener al menos 6 caracteres, una letra mayúscula, una letra minúscula y un número"])},
    "passwordMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falló la confirmación de la contraseña"])}
  },
  "currencies": {
    "mxn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🇲🇽 - Pesos mexicanos"])},
    "usd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🇺🇸 - Dólares estadounidenses"])}
  },
  "errors": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Ups! Página no encontrada :("])},
    "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo salió mal. Favor de intentar más tarde"])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubo un error al subir la imagen"])}
  }
}