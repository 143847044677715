<template>
  <h3> {{ $t('purchases.listOfOtherPayments') }}</h3>
  <ui-grid>
    <ui-grid-cell columns="3">
      <ui-select outlined fullwidth :options="filtros" v-model="filtroSeleccionado">{{ $t('filters.filterBy')
      }}</ui-select>
    </ui-grid-cell>
    <ui-grid-cell columns="3" v-if="filtroSeleccionado === 'estatus'">
      <ui-form-field>
        <ui-checkbox v-model="ingresado" input-id="checkbox"></ui-checkbox>
        <label for="checkbox">{{ $t('purchases.hasEntered') }}</label>
      </ui-form-field>
    </ui-grid-cell>
    <ui-grid-cell columns="3" v-else-if="filtroSeleccionado === 'genero'">
      <ui-form-field>
        <ui-radio v-model="genero" value="hombre" />
        <label>{{ $t('purchases.male') }}</label>
        <ui-radio v-model="genero" value="mujer" />
        <label>{{ $t('purchases.female') }}</label>
      </ui-form-field>
    </ui-grid-cell>
    <ui-grid-cell columns="4" v-else>
      <ui-textfield outlined fullwidth v-model="criterioBusqueda" @keyup.enter="buscar">{{ $t('filters.searchBy')
      }}</ui-textfield>
    </ui-grid-cell>
    <ui-grid-cell columns="1">
      <ui-icon-button icon="search" @click="buscar"> </ui-icon-button>
    </ui-grid-cell>
    <ui-grid-cell columns="1">
      <ui-icon-button icon="undo" @click="undo"> </ui-icon-button>
    </ui-grid-cell>
    <ui-grid-cell columns="1">
      <ui-icon-button icon="refresh" @click="updateRegistries">
      </ui-icon-button>
    </ui-grid-cell>
    <ui-grid-cell columns="1" v-show="isOwner">
      <ui-icon-button icon="sim_card_download" @click="downloadReport"> </ui-icon-button>
    </ui-grid-cell>
  </ui-grid>
  <ui-progress v-show="fileDownloading" indeterminate active></ui-progress>
  <error-component v-show="errorLoad" :message="errorMessage" />
  <ui-table fullwidth :page-size="itemsPerPage" :data="paginatedRegistriesPurchased" :thead="thead" :tbody="tbody"
    selected-key="registryId">
    <template #actions="{ data }">
      <ui-icon-button icon="checklist" @click="showLog(data)"></ui-icon-button>
    </template>
    <ui-pagination v-model="page" :page-size="itemsPerPage" :total="total" show-total></ui-pagination>
  </ui-table>
</template>
<script>
import { helpers } from "balm-ui";
import { useMainStore } from '@/stores/main';
import { mapState } from 'pinia';
import PaymentsAPI from "@/api/PaymentsAPI";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import i18n from "@/i18n";

const tiposFiltro = [
  {
    label: i18n.global.t('filters.sn'),
    value: "folio",
  },
  {
    label: i18n.global.t('filters.registerType'),
    value: "tipo",
  },
  {
    label: i18n.global.t('filters.attendee'),
    value: "nombre",
  },
  {
    label: i18n.global.t('filters.state'),
    value: "estado",
  },
  {
    label: i18n.global.t('filters.city'),
    value: "ciudad",
  },
  {
    label: i18n.global.t('filters.age'),
    value: "edad",
  },
  {
    label: i18n.global.t('filters.genre'),
    value: "genero",
  },
  {
    label: i18n.global.t('filters.email'),
    value: "email",
  },
  {
    label: i18n.global.t('filters.groupCompany'),
    value: "grupo",
  },
  {
    label: i18n.global.t('filters.status'),
    value: "estatus",
  },
  {
    label: i18n.global.t('filters.orderNumber'),
    value: "orden",
  },
  {
    label: i18n.global.t('filters.tdUser'),
    value: "userId",
  },
];
export default {
  name: "TablaRegistrosComprados",
  components: {
    ErrorComponent
  },
  props: {
    registriesPurchased: Array,
  },
  emits: ["refreshRegistries", "selectedRegistryId"],
  data() {
    return {
      thead: [
        this.$t('headers.purchaseDate'),
        this.$t('filters.sn'),
        this.$t('filters.registerType'),
        this.$t('filters.attendee'),
        this.$t('filters.state'),
        this.$t('filters.city'),
        this.$t('filters.genre'),
        this.$t('filters.age'),
        this.$t('filters.email'),
        this.$t('filters.groupCompany'),
        this.$t('purchases.hasEntered'),
        this.$t('headers.log'),
        this.$t('headers.orderNumber'),
        this.$t('filters.tdUser'),
      ],
      tbody: [
        "registryDate",
        "registryId",
        {
          field: "fullRegisterTypeName",
          fn: (data) => {
            const mainStore = useMainStore();
            return mainStore.defaultLanguage === 'es' ? data.fullRegisterTypeName : data.fullRegisterTypeNameEn
          },
        },
        "participantName",
        "state",
        "city",
        {
          field: "genre",
          fn: (data) => {
            return data.genre === 'Hombre' ? this.$t('purchases.male') : this.$t('purchases.female')
          },
        },
        "age",
        "email",
        "companyGroup",
        {
          field: "hasBeenRegistered",
          fn: (data) => {
            return data.hasBeenRegistered ? this.$t('commonElements.yes') : this.$t('commonElements.no');
          },
        },
        {
          slot: "actions",
        },
        "registryPaymentId",
        "userId",
      ],
      page: 1,
      itemsPerPage: 20,
      filtros: tiposFiltro,
      filtroSeleccionado: "folio",
      ingresado: false,
      genero: "hombre",
      filteredRegistriesPurchased: [...this.registriesPurchased],
      criterioBusqueda: "",
      errorLoad: false,
      fileDownloading: false,
      errorMessage: ""
    };
  },
  computed: {
    ...mapState(useMainStore, ['userLogged', 'selectedEventId']),
    paginatedRegistriesPurchased() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      //console.log("start: " + start + " end: " + end);
      return this.filteredRegistriesPurchased.slice(start, end);
    },
    total() {
      return this.filteredRegistriesPurchased.length;
    },
    isOwner() {
      return this.userLogged && this.userLogged.sessionType === "OWNER";
    }
  },
  methods: {
    showLog(registrySelected) {
      this.$emit("selectedRegistryId", registrySelected.registryId);
    },
    updateRegistries() {
      this.$emit("refreshRegistries", "registros");
    },
    undo() {
      this.filteredRegistriesPurchased = [...this.registriesPurchased];
      this.criterioBusqueda = "";
      this.page = 1;
    },
    buscar() {
      if (this.filtroSeleccionado === "estatus") {
        this.filteredRegistriesPurchased = [...this.registriesPurchased].filter(
          (registryPurchased) =>
            registryPurchased.hasBeenRegistered === this.ingresado
        );
        this.page = 1;
      } else if (this.filtroSeleccionado === "genero") {
        if (this.genero === "hombre") {
          this.filteredRegistriesPurchased = [
            ...this.registriesPurchased,
          ].filter((registryPurchased) => registryPurchased.genre === "Hombre");
        } else {
          this.filteredRegistriesPurchased = [
            ...this.registriesPurchased,
          ].filter((registryPurchased) => registryPurchased.genre === "Mujer");
        }
        this.page = 1;
      } else if (!helpers.isEmpty(this.criterioBusqueda.trim())) {
        var criterioBusquedaLowerCase = this.criterioBusqueda.trim().toLowerCase();
        switch (this.filtroSeleccionado) {
          case "folio":
            this.filteredRegistriesPurchased = [
              ...this.registriesPurchased,
            ].filter((registryPurchased) =>
              registryPurchased.registryId.toLowerCase().includes(criterioBusquedaLowerCase)
            );
            break;
          case "tipo":
            this.filteredRegistriesPurchased = [
              ...this.registriesPurchased,
            ].filter((registryPurchased) =>
              registryPurchased.fullRegisterTypeName.toLowerCase().includes(criterioBusquedaLowerCase) ||
              registryPurchased.fullRegisterTypeNameEn.toLowerCase().includes(criterioBusquedaLowerCase)
            );
            break;
          case "nombre":
            this.filteredRegistriesPurchased = [
              ...this.registriesPurchased,
            ].filter((registryPurchased) =>
              registryPurchased.participantName.toLowerCase().includes(criterioBusquedaLowerCase)
            );
            break;
          case "estado":
            this.filteredRegistriesPurchased = [
              ...this.registriesPurchased,
            ].filter(
              (registryPurchased) =>
                !helpers.isEmpty(registryPurchased.state) &&
                registryPurchased.state.toLowerCase().includes(criterioBusquedaLowerCase)
            );
            break;
          case "ciudad":
            this.filteredRegistriesPurchased = [
              ...this.registriesPurchased,
            ].filter(
              (registryPurchased) =>
                !helpers.isEmpty(registryPurchased.city) &&
                registryPurchased.city.toLowerCase().includes(criterioBusquedaLowerCase)
            );
            break;
          case "edad":
            //console.log(Number(this.criterioBusqueda));
            this.filteredRegistriesPurchased = [
              ...this.registriesPurchased,
            ].filter(
              (registryPurchased) =>
                registryPurchased.age === Number(this.criterioBusqueda)
            );
            break;
          case "email":
            this.filteredRegistriesPurchased = [
              ...this.registriesPurchased,
            ].filter(
              (registryPurchased) =>
                !helpers.isEmpty(registryPurchased.email) &&
                registryPurchased.email.toLowerCase().includes(criterioBusquedaLowerCase)
            );
            break;
          case "orden":
            this.filteredRegistriesPurchased = [
              ...this.registriesPurchased,
            ].filter((registryPurchased) =>
              registryPurchased.registryPaymentId.toLowerCase().includes(
                criterioBusquedaLowerCase
              )
            );
            break;
          case "grupo":
            this.filteredRegistriesPurchased = [
              ...this.registriesPurchased,
            ].filter(
              (registryPurchased) =>
                !helpers.isEmpty(registryPurchased.companyGroup) &&
                registryPurchased.companyGroup.toLowerCase().includes(criterioBusquedaLowerCase)
            );
            break;

          case "userId":
            this.filteredRegistriesPurchased = [
              ...this.registriesPurchased,
            ].filter((registryPurchased) =>
              registryPurchased.userId.toLowerCase().includes(criterioBusquedaLowerCase)
            );
            break;
        }
        this.page = 1;
      }
    },
    async downloadReport() {
      try {

        this.fileDownloading = true;
        let fileName = "reporte_registros_comprados_" + new Date().toLocaleDateString() + ".xlsx"

        const paymentsApi = new PaymentsAPI();

        const fileData = await paymentsApi.downloadRegistriesPurchasedReport({
          token: this.userLogged.token,
          eventId: this.selectedEventId
        });
        const link = document.createElement('a')
        link.href = URL.createObjectURL(fileData)
        link.target = "_blank"
        link.download = fileName
        link.click()
        URL.revokeObjectURL(link.href)
        this.fileDownloading = false;

      } catch (error) {
        this.errorLoad = true;
        this.fileDownloading = false;
        this.errorMessage = error;
      }
    }
  },
};
</script>
<style scoped>
h3 {
  text-align: center;
  margin-top: 2em;
}
</style>