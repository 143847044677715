<template>
    <div class="content">
        <ui-skeleton :loading="loadingTickets" :title="false" active :paragraph="{ rows: 30 }">
            <div class="create-ticket">
                <ui-button raised @click="newTicket"> {{ $t('tickets.create') }}
                    <template #after="{ iconClass }">
                        <ui-icon :class="iconClass">add</ui-icon>
                    </template>
                </ui-button>
            </div>
            <error-component v-show="errorTickets" :message="errorMessage" />
            <h3 class="subtitle">{{ $t('commonElements.active') }}</h3>
            <ui-table fullwidth :data="boletosActivos" :thead="theadBoletos" :tbody="tbodyBoletos"
                selected-key="ticketId">
                <template #viewDescription="{ data }">
                    <ui-button icon="launch" @click="showDescription(data.ticketDescription, data.ticketDescriptionEn)">
                        {{
            $t('actions.show') }}
                    </ui-button>
                </template>
                <template #up="{ data }">
                    <ui-icon-button v-show="data.order !== boletosActivos.at(0).order" icon="arrow_upward"
                        @click="moveUpActivos(data)"></ui-icon-button>
                </template>
                <template #down="{ data }">
                    <ui-icon-button v-show="data.order !== boletosActivos.at(boletosActivos.length - 1).order"
                        icon="arrow_downward" @click="moveDownActivos(data)"></ui-icon-button>
                </template>
                <template #actions="{ data }">
                    <ui-button icon="edit" @click="editTicket(data)"> {{ $t('actions.edit') }} </ui-button>
                    <ui-button icon="content_copy" @click="duplicateTicket(data)"> {{ $t('actions.duplicate') }}
                    </ui-button>
                    <ui-button icon="visibility_off" @click="disableTicket(data)"> {{ $t('actions.disable') }}
                    </ui-button>
                    <ui-button icon="delete" @click="removeTicket(data)"> {{ $t('actions.delete') }} </ui-button>
                </template>
            </ui-table>
            <div v-show="showSaveOrderActive" class="save-order">
                <ui-button @click="saveOrder">{{ $t('commonElements.saveOrder') }}
                </ui-button>
            </div>
            <ui-divider></ui-divider>
            <h3 class="subtitle">{{ $t('commonElements.inactive') }}</h3>
            <ui-table fullwidth :data="boletosInactivos" :thead="theadBoletos" :tbody="tbodyBoletos"
                selected-key="ticketId">
                <template #viewDescription="{ data }">
                    <ui-button icon="launch" @click="showDescription(data.ticketDescription, data.ticketDescriptionEn)">
                        {{
            $t('actions.show') }}
                    </ui-button>
                </template>
                <template #up="{ data }">
                    <ui-icon-button v-show="data.order !== boletosInactivos.at(0).order" icon="arrow_upward"
                        @click="moveUpInactivos(data)"></ui-icon-button>
                </template>
                <template #down="{ data }">
                    <ui-icon-button v-show="data.order !== boletosInactivos.at(boletosInactivos.length - 1).order"
                        icon="arrow_downward" @click="moveDownInactivos(data)"></ui-icon-button>
                </template>
                <template #actions="{ data }">
                    <ui-button icon="edit" @click="editTicket(data)"> {{ $t('actions.edit') }} </ui-button>
                    <ui-button icon="content_copy" @click="duplicateTicket(data)"> {{ $t('actions.duplicate') }}
                    </ui-button>
                    <ui-button icon="visibility" @click="enableTicket(data)"> {{ $t('actions.enable') }} </ui-button>
                    <ui-button icon="delete" @click="removeTicket(data)"> {{ $t('actions.delete') }} </ui-button>
                </template>
            </ui-table>
            <div v-show="showSaveOrderInactive" class="save-order">
                <ui-button @click="saveOrder">{{ $t('commonElements.saveOrder') }}
                </ui-button>
            </div>
            <error-component v-show="errorTickets" :message="errorMessage" />

            <!-- Descripcion dialog -->
            <ui-dialog v-model="openDescription" fullscreen>
                <ui-dialog-title> {{ $t('commonElements.description') }}</ui-dialog-title>
                <ui-dialog-content>
                    <h4>{{ $t('tickets.ticketDescription') }}</h4>
                    <div v-html="description"></div>
                    <h4>{{ $t('tickets.ticketDescriptionEn') }}</h4>
                    <div v-html="descriptionEn"></div>
                </ui-dialog-content>
                <ui-dialog-actions>
                    <ui-button @click="openDescription = false">OK</ui-button>
                </ui-dialog-actions>
            </ui-dialog>

            <!-- New/Update Ticket dialog -->
            <ui-dialog v-model="openTicketDialog" fullscreen>
                <ui-dialog-title> {{ ticketDialogTitle }}</ui-dialog-title>
                <ui-dialog-content>
                    <ui-progress v-show="loadingPopup" indeterminate active></ui-progress>
                    <ui-form item-margin-bottom="20" type="|">
                        <template #default="{ subitemClass }">
                            <ui-form-field :class="subitemClass">
                                <label class="active-text" :class="activeClass">{{ activeText }}</label>
                            </ui-form-field>
                            <ui-form-field class="required" :class="subitemClass">
                                <label> {{ $t('tickets.name') }}</label>
                                <ui-textfield v-model="form.name" helper-text-id="name-helper-text"
                                    fullwidth></ui-textfield>
                                <ui-textfield-helper id="name-helper-text"
                                    v-model:validMsg="validMsg.name"></ui-textfield-helper>
                            </ui-form-field>
                            <ui-form-field class="required" :class="subitemClass">
                                <label> {{ $t('tickets.ticketDescription') }}</label>
                                <ui-editor v-model="form.description" fullwidth with-counter
                                    helper-text-id="description-helper-text"></ui-editor>
                                <ui-textfield-helper id="description-helper-text"
                                    v-model:validMsg="validMsg.description"></ui-textfield-helper>
                            </ui-form-field>
                            <ui-form-field class="required" :class="subitemClass">
                                <label> {{ $t('tickets.ticketDescriptionEn') }}</label>
                                <ui-editor v-model="form.descriptionEn" fullwidth with-counter
                                    helper-text-id="description-en-helper-text"></ui-editor>
                                <ui-textfield-helper id="description-en-helper-text"
                                    v-model:validMsg="validMsg.descriptionEn"></ui-textfield-helper>
                            </ui-form-field>
                            <ui-form-field class="required">
                                <label> {{ $t('tickets.mandatoryNameForm') }}</label>
                                <div :class="subitemClass">
                                    <ui-form-field>
                                        <ui-switch v-model="form.isMandatoryName"></ui-switch>
                                        <label> {{ mandatoryNameText }}</label>
                                    </ui-form-field>
                                </div>
                            </ui-form-field>
                            <br>
                            <ui-divider></ui-divider>
                            <br>
                            <ui-form-field class="required">
                                <label> {{ $t('commonElements.quantityAdjustment') }} </label>
                                <div :class="subitemClass">
                                    <ui-form-field>
                                        <label> {{ $t('tickets.maxLimit') }}</label>
                                        <ui-textfield class="number-field" v-model="form.maxTickets" input-type="number"
                                            helper-text-id="maxTickets-helper-text"></ui-textfield>
                                        <ui-textfield-helper id="maxTickets-helper-text"
                                            v-model:validMsg="validMsg.maxTickets"></ui-textfield-helper>
                                    </ui-form-field>
                                    <ui-form-field>
                                        <label> {{ $t('tickets.userTickets') }}</label>
                                        <ui-textfield class="number-field" v-model="form.maxTicketsUser"
                                            input-type="number"
                                            helper-text-id="maxTicketsUser-helper-text"></ui-textfield>
                                        <ui-textfield-helper id="maxTicketsUser-helper-text"
                                            v-model:validMsg="validMsg.maxTicketsUser"></ui-textfield-helper>
                                    </ui-form-field>
                                    <ui-form-field>
                                        <label> {{ $t('tickets.attendeesPerTicket') }}</label>
                                        <ui-textfield :disabled="!canBeEdited" class="number-field"
                                            v-model="form.numNamesPerTicket" input-type="number"
                                            helper-text-id="numNamesPerTicket-helper-text"></ui-textfield>
                                        <ui-textfield-helper id="numNamesPerTicket-helper-text"
                                            v-model:validMsg="validMsg.numNamesPerTicket"></ui-textfield-helper>
                                    </ui-form-field>
                                </div>
                            </ui-form-field>
                            <ui-form-field class="required">
                                <label> {{ $t('tickets.enableMSI') }}</label>
                                <div :class="subitemClass">
                                    <ui-form-field>
                                        <ui-switch v-model="form.msiEnabled"></ui-switch>
                                        <label> {{ enabledMSIText }}</label>
                                    </ui-form-field>
                                </div>
                            </ui-form-field>
                            <br>
                            <ui-divider></ui-divider>
                            <br>
                            <ui-form-field class="required">
                                <div :class="subitemClass">
                                    <ui-form-field>
                                        <label class="amount-text">{{ $t('commonElements.salePriceMXN') }} $</label>
                                        <ui-textfield :disabled="!canBeEdited" class="number-field" v-model="form.price"
                                            input-type="number" helper-text-id="price-helper-text"></ui-textfield>
                                        <ui-textfield-helper id="price-helper-text"
                                            v-model:validMsg="validMsg.price"></ui-textfield-helper>
                                        <ui-form-field>
                                            <label> {{ $t('commonElements.feeMXN') }}: </label>
                                            <label class="amount-text"> {{ feeText }}</label>
                                        </ui-form-field>
                                    </ui-form-field>
                                </div>
                            </ui-form-field>
                            <ui-form-field>
                                <label class="received-text"> {{ $t('commonElements.amountReceivedMXN', [receivedMXN])
                                    }}</label>
                            </ui-form-field>
                            <ui-form-field class="required">
                                <div :class="subitemClass">
                                    <ui-form-field>
                                        <label class="amount-text">{{ $t('commonElements.salePriceUSD') }} $</label>
                                        <ui-textfield :disabled="!canBeEdited" class="number-field"
                                            v-model="form.priceUSD" input-type="number"
                                            helper-text-id="priceUSD-helper-text"></ui-textfield>
                                        <ui-textfield-helper id="priceUSD-helper-text"
                                            v-model:validMsg="validMsg.priceUSD"></ui-textfield-helper>
                                        <ui-form-field>
                                            <label> {{ $t('commonElements.feeUSD') }}: </label>
                                            <label class="amount-text"> {{ feeUSDText }}</label>
                                        </ui-form-field>
                                    </ui-form-field>
                                </div>
                            </ui-form-field>
                            <ui-form-field>
                                <label class="received-text">{{ $t('commonElements.amountReceivedUSD', [receivedUSD])
                                    }}</label>
                            </ui-form-field>
                            <ui-form-field v-if="accountType === 'standard'">
                                <label>{{ $t('pricing.stripeDisclaimer') }} <a href="https://stripe.com/pricing"
                                        target="_blank"> Stripe
                                        &#8482;</a></label>
                            </ui-form-field>
                            <br>
                            <ui-divider></ui-divider>
                            <br>
                            <ui-form-field>
                                <label>{{ $t('commonElements.feeNotice') }} </label>
                            </ui-form-field>
                            <ui-form-field>
                                <ui-button @click="togglePlans"> {{ plansButtonText }}</ui-button>
                                <fees-information :account-type="accountType" v-if="showPlans"></fees-information>
                            </ui-form-field>
                        </template>
                    </ui-form>
                    <ui-progress v-show="loadingPopup" indeterminate active></ui-progress>
                    <error-component v-show="errorPopup" :message="errorPopupMessage" />
                </ui-dialog-content>
                <ui-dialog-actions>
                    <ui-button class="action-button" :disabled="loadingPopup" @click.prevent="openTicketDialog = false">
                        {{
            $t('actions.cancel') }}</ui-button>
                    <ui-button class="action-button" raised :disabled="loadingPopup" @click.prevent="saveTicket"> {{
            $t('actions.save') }}</ui-button>
                </ui-dialog-actions>
            </ui-dialog>
        </ui-skeleton>
    </div>
</template>

<script>
import { useMainStore } from '@/stores/main';
import { mapState, mapActions } from "pinia";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import { useValidator, helpers } from "balm-ui";
import formatters from "@/utils/formatters";
import amounts from "@/utils/amounts";
import i18n from '@/i18n';
import FeesInformation from "@/components/common/FeesInformation.vue";

const theadBoletos = [
    i18n.global.t('tickets.name'),
    i18n.global.t('commonElements.description'),
    i18n.global.t('tickets.maxLimit'),
    i18n.global.t('tickets.userTickets'),
    i18n.global.t('tickets.attendeesPerTicket'),
    i18n.global.t('tickets.mandatoryName'),
    { value: i18n.global.t('commonElements.salePriceMXN'), align: "right" },
    { value: i18n.global.t('commonElements.feeMXN'), align: "right" },
    { value: i18n.global.t('commonElements.salePriceUSD'), align: "right" },
    { value: i18n.global.t('commonElements.feeUSD'), align: "right" },
    i18n.global.t('headers.withMSI'),
    i18n.global.t('tickets.ticketsPurchased'),
    i18n.global.t('actions.moveDown'),
    i18n.global.t('actions.moveUp'),
    { value: i18n.global.t('actions.title'), align: "center" },
]

const tbodyBoletos = [
    "ticketName",
    {
        slot: "viewDescription"
    },
    "maxTickets",
    "maxTicketsUser",
    "numNamesPerTicket",
    {
        field: "isMandatoryName",
        fn: (data) => {
            return data.isMandatoryName ? i18n.global.t('commonElements.yes') : i18n.global.t('commonElements.no');
        },
    },
    {
        field: "ticketPrice",
        fn: (data) => {
            return formatters.formatCurrency(data.ticketPrice);
        },
        align: "right",
    },
    {
        field: "ticketPctFee",
        fn: (data) => {
            return formatters.formatPct((data.ticketPctFee) * 100) + "%";
        },
        align: "right",
    },
    {
        field: "ticketPriceUSD",
        fn: (data) => {
            return formatters.formatCurrency(data.ticketPriceUSD);
        },
        align: "right",
    },
    {
        field: "ticketPctFeeUSD",
        fn: (data) => {
            return formatters.formatPct((data.ticketPctFeeUSD) * 100) + "%";
        },
        align: "right",
    },
    {
        field: "msiEnabled",
        fn: (data) => {
            return data.msiEnabled ? i18n.global.t('commonElements.yes') : i18n.global.t('commonElements.no');
        },
    },
    "ticketsPurchased",
    {
        slot: "down",
    },
    {
        slot: "up",
    },
    {
        slot: "actions",
    },
]

const validations = [
    {
        key: "name",
        label: i18n.global.t('tickets.name'),
        validator: "required",
    },
    {
        key: "description",
        label: i18n.global.t('tickets.ticketDescription'),
        validator: "required, lessThanAllowed",
        lessThanAllowed: {
            validate(value) {
                return value.length < 5000
            },
            message: "%s" + i18n.global.t('validations.tooBig'),
        },
    },
    {
        key: "descriptionEn",
        label: i18n.global.t('tickets.ticketDescriptionEn'),
        validator: "required, lessThanAllowed",
        lessThanAllowed: {
            validate(value) {
                return value.length < 5000
            },
            message: "%s" + i18n.global.t('validations.tooBig'),
        },
    },
    {
        key: "maxTickets",
        label: i18n.global.t('tickets.maxLimit'),
        validator: "required, positive",
    },
    {
        key: "maxTicketsUser",
        label: i18n.global.t('tickets.userTickets'),
        validator: "required, positive, lessThanMax",
        lessThanMax: {
            validate(value, data) {
                return Number(data.maxTickets) > Number(value)
            },
            message: "%s" + i18n.global.t('validations.lessThan') + i18n.global.t('tickets.maxLimit'),
        },
    },
    {
        key: "numNamesPerTicket",
        label: i18n.global.t('tickets.attendeesPerTicket'),
        validator: "required, positive",
    },
    {
        key: "price",
        label: i18n.global.t('commonElements.salePriceMXN'),
        validator: "required, positive",
    },
    {
        key: "priceUSD",
        label: i18n.global.t('commonElements.salePriceUSD'),
        validator: "required, positive",
    }
]

export default {
    components: {
        ErrorComponent,
        FeesInformation
    },
    data() {
        return {
            openDescription: false,
            theadBoletos: theadBoletos,
            tbodyBoletos: tbodyBoletos,
            description: "",
            descriptionEn: "",
            ticketDialogTitle: "",
            showSaveOrderActive: false,
            showSaveOrderInactive: false,
            openTicketDialog: false,
            isNewTicket: true,
            balmUI: useValidator(),
            validations,
            validMsg: {},
            form: {
                name: "",
                description: "",
                descriptionEn: "",
                maxTickets: 0,
                maxTicketsUser: 0,
                numNamesPerTicket: 0,
                isMandatoryName: false,
                active: false,
                price: 0,
                priceUSD: 0,
                msiEnabled: false
            },
            selectedTicketId: 0,
            canBeEdited: true,
            ticketActive: false,
            loadingTickets: false,
            errorTickets: false,
            errorMessage: "",
            loadingPopup: false,
            errorPopup: false,
            errorPopupMessage: "",
            showPlans: false
        }
    },
    computed: {
        ...mapState(useMainStore, ['writableEvent', 'accountType']),
        boletosActivos() {
            return helpers.isEmpty(this.writableEvent.tickets) ? [] : [...this.writableEvent.tickets.filter(x => x.active).sort((a, b) => a.order - b.order)]
        },
        boletosInactivos() {
            return helpers.isEmpty(this.writableEvent.tickets) ? [] : [...this.writableEvent.tickets.filter(x => !x.active).sort((a, b) => a.order - b.order)]
        },
        mandatoryNameText() {
            return this.form.isMandatoryName ? this.$t('commonElements.yes') : this.$t('commonElements.no')
        },
        fee() {
            if (this.accountType === 'standard') {
                return amounts.getStandardFeeForMXN(this.form.price)
            } else {
                return amounts.getExpressFeeForMXN(this.form.price)
            }
        },
        feeUSD() {
            if (this.accountType === 'standard') {
                return amounts.getStandardFeeForUSD(this.form.priceUSD)
            } else {
                return amounts.getExpressFeeForUSD(this.form.priceUSD)
            }
        },
        feeText() {
            return formatters.formatPct((this.fee) * 100) + "%";
        },
        feeUSDText() {
            return formatters.formatPct((this.feeUSD) * 100) + "%";
        },
        receivedMXN() {
            const price = isNaN(this.form.price) ? 0 : Number(this.form.price)
            const comision = price * this.fee
            const total = price - comision
            return formatters.formatCurrency(total);
        },
        receivedUSD() {
            const price = isNaN(this.form.priceUSD) ? 0 : Number(this.form.priceUSD)
            const comision = price * this.feeUSD
            const total = price - comision
            return formatters.formatCurrency(total);
        },
        activeText() {
            return this.ticketActive ? this.$t('tickets.active') : this.$t('tickets.inactive')
        },
        activeClass() {
            return this.ticketActive ? 'active-color' : 'inactive-color'
        },
        enabledMSIText() {
            return this.form.msiEnabled ? this.$t('commonElements.yes') : this.$t('commonElements.no')
        },
        plansButtonText() {
            return this.showPlans ? this.$t('actions.hide') : this.$t('actions.show')
        }
    },
    methods: {
        ...mapActions(useMainStore, ['updateTicketsOrder', 'createTicket', 'updateTicket', 'activateTicket', 'deactivateTicket', 'deleteTicket']),
        showDescription(description, descriptionEn) {
            this.description = description
            this.descriptionEn = descriptionEn
            //console.log(description)
            //console.log(descriptionEn)
            this.openDescription = true;
        },
        moveUpActivos(ticketSelected) {
            const currentIndex = this.boletosActivos.findIndex(x => x.ticketId === ticketSelected.ticketId);
            const targetTicket = this.boletosActivos.at(currentIndex - 1);

            const originalOrder = ticketSelected.order;
            const targetOrder = targetTicket.order;

            this.swapOrder(ticketSelected.ticketId, targetTicket.ticketId, originalOrder, targetOrder)

            this.showSaveOrderActive = true
        },
        moveDownActivos(ticketSelected) {

            const currentIndex = this.boletosActivos.findIndex(x => x.ticketId === ticketSelected.ticketId);
            const targetTicket = this.boletosActivos.at(currentIndex + 1);

            const originalOrder = ticketSelected.order;
            const targetOrder = targetTicket.order;

            this.swapOrder(ticketSelected.ticketId, targetTicket.ticketId, originalOrder, targetOrder)

            this.showSaveOrderActive = true
        },
        moveUpInactivos(ticketSelected) {

            const currentIndex = this.boletosInactivos.findIndex(x => x.ticketId === ticketSelected.ticketId);
            const targetTicket = this.boletosInactivos.at(currentIndex - 1);

            const originalOrder = ticketSelected.order;
            const targetOrder = targetTicket.order;

            this.swapOrder(ticketSelected.ticketId, targetTicket.ticketId, originalOrder, targetOrder)

            this.showSaveOrderInactive = true

        },
        moveDownInactivos(ticketSelected) {
            const currentIndex = this.boletosInactivos.findIndex(x => x.ticketId === ticketSelected.ticketId);
            const targetTicket = this.boletosInactivos.at(currentIndex + 1);

            const originalOrder = ticketSelected.order;
            const targetOrder = targetTicket.order;

            this.swapOrder(ticketSelected.ticketId, targetTicket.ticketId, originalOrder, targetOrder)

            this.showSaveOrderInactive = true
        },
        editTicket(ticketSelected) {
            //console.log(ticketSelected)
            this.isNewTicket = false;
            this.ticketActive = ticketSelected.active
            this.selectedTicketId = ticketSelected.ticketId
            this.canBeEdited = ticketSelected.ticketsPurchased < 1
            this.ticketDialogTitle = this.$t('tickets.editDisclaimer')
            this.form.name = ticketSelected.ticketName
            this.form.description = ticketSelected.ticketDescription
            this.form.descriptionEn = ticketSelected.ticketDescriptionEn
            this.form.isMandatoryName = ticketSelected.isMandatoryName
            this.form.maxTickets = ticketSelected.maxTickets
            this.form.maxTicketsUser = ticketSelected.maxTicketsUser
            this.form.numNamesPerTicket = ticketSelected.numNamesPerTicket
            this.form.price = ticketSelected.ticketPrice
            this.form.priceUSD = ticketSelected.ticketPriceUSD
            this.form.msiEnabled = ticketSelected.msiEnabled;
            this.openTicketDialog = true;
        },
        removeTicket(ticketSelected) {

            if (ticketSelected.ticketsPurchased > 0) {

                this.$alert({
                    message: this.$t('tickets.purchasedDeletionError', [ticketSelected.ticketName]),
                    state: 'error',
                    stateOutlined: true
                });

            } else {

                this.$confirm({
                    title: this.$t('tickets.confirmDelete', [ticketSelected.ticketName]),
                    message: this.$t('validations.cannotUndo'),
                    state: 'warning',
                    stateOutlined: true,
                    acceptText: this.$t('actions.delete'),
                    cancelText: this.$t('actions.cancel'),
                }).then(async (result) => {

                    if (result) {
                        this.loadingTickets = true
                        this.errorTickets = false
                        this.errorMessage = ""

                        try {

                            await this.deleteTicket(ticketSelected.ticketId)
                            this.$toast(this.$t('tickets.deleteConfirmation'));
                            this.loadingTickets = false

                        } catch (error) {
                            this.loadingTickets = false
                            this.errorTickets = true
                            this.errorMessage = error
                        }
                    }
                })
            }
        },
        disableTicket(ticketSelected) {
            this.$confirm({
                title: this.$t('tickets.confirmDisable', [ticketSelected.ticketName]),
                message: this.$t('tickets.disableDisclaimer'),
                state: 'help',
                stateOutlined: true,
                acceptText: this.$t('actions.disable'),
                cancelText: this.$t('actions.cancel'),
            }).then(async (result) => {

                if (result) {

                    this.loadingTickets = true
                    this.errorTickets = false
                    this.errorMessage = ""

                    try {

                        await this.deactivateTicket(ticketSelected.ticketId)
                        this.$toast(this.$t('tickets.disableConfirmation'));
                        this.loadingTickets = false

                    } catch (error) {
                        this.loadingTickets = false
                        this.errorTickets = true
                        this.errorMessage = error
                    }
                }
            })
        },
        enableTicket(ticketSelected) {

            this.$confirm({
                title: this.$t('tickets.confirmEnable', [ticketSelected.ticketName]),
                message: this.$t('tickets.enableDisclaimer'),
                state: 'help',
                stateOutlined: true,
                acceptText: this.$t('actions.enable'),
                cancelText: this.$t('actions.cancel'),
            }).then(async (result) => {

                if (result) {

                    this.loadingTickets = true
                    this.errorTickets = false
                    this.errorMessage = ""

                    try {

                        await this.activateTicket(ticketSelected.ticketId)
                        this.$toast(this.$t('tickets.enableConfirmation'));
                        this.loadingTickets = false

                    } catch (error) {
                        this.loadingTickets = false
                        this.errorTickets = true
                        this.errorMessage = error
                    }
                }
            })
        },
        duplicateTicket(ticketSelected) {
            this.isNewTicket = true
            this.canBeEdited = true
            this.ticketActive = false
            this.ticketDialogTitle = this.$t('tickets.duplicateTitle')
            this.form.name = ticketSelected.ticketName
            this.form.description = ticketSelected.ticketDescription
            this.form.descriptionEn = ticketSelected.ticketDescriptionEn
            this.form.isMandatoryName = ticketSelected.isMandatoryName
            this.form.maxTickets = ticketSelected.maxTickets
            this.form.maxTicketsUser = ticketSelected.maxTicketsUser
            this.form.numNamesPerTicket = ticketSelected.numNamesPerTicket
            this.form.price = ticketSelected.ticketPrice
            this.form.priceUSD = ticketSelected.ticketPriceUSD
            this.form.msiEnabled = ticketSelected.msiEnabled;
            this.openTicketDialog = true;
        },
        newTicket() {
            this.isNewTicket = true
            this.canBeEdited = true
            this.ticketActive = false
            this.ticketDialogTitle = this.$t('tickets.addTitle')
            this.form.name = ''
            this.form.description = ''
            this.form.descriptionEn = ''
            this.form.isMandatoryName = false
            this.form.maxTickets = 0
            this.form.maxTicketsUser = 0
            this.form.numNamesPerTicket = 1
            this.form.price = 0
            this.form.priceUSD = 0
            this.form.msiEnabled = false;
            this.openTicketDialog = true
        },
        swapOrder(originalId, targetId, originalOrder, targetOrder) {
            const mainStore = useMainStore()
            mainStore.$patch((state) => {
                state.writableEvent.tickets = state.writableEvent.tickets.map(ticket => ticket.ticketId === originalId ? { ...ticket, order: targetOrder } : ticket)
                state.writableEvent.tickets = state.writableEvent.tickets.map(ticket => ticket.ticketId === targetId ? { ...ticket, order: originalOrder } : ticket)

                state.hasChanged = true
            })
        },
        async saveOrder() {

            this.showSaveOrderActive = false;
            this.showSaveOrderInactive = false;
            this.loadingTickets = true;
            this.errorMessage = "";
            this.errorTickets = false;

            try {

                await this.updateTicketsOrder();
                this.loadingTickets = false

            } catch (error) {
                this.showSaveOrderActive = true;
                this.showSaveOrderInactive = true;
                this.loadingTickets = false
                this.errorTickets = true;
                this.errorMessage = error;
            }
        },

        async saveTicket() {

            let result = this.balmUI.validate(this.form);
            let { valid, validMsg } = result;
            this.validMsg = validMsg;

            //console.log(valid)
            //console.log(validMsg)

            if (valid) {

                try {

                    this.loadingPopup = true
                    this.errorPopup = false
                    this.errorPopupMessage = ""

                    const ticketData = {
                        ticketName: this.form.name,
                        ticketDescription: this.form.description,
                        ticketDescriptionEn: this.form.descriptionEn,
                        isMandatoryName: this.form.isMandatoryName,
                        ticketPrice: Number(this.form.price),
                        ticketPriceUSD: Number(this.form.priceUSD),
                        maxTickets: Number(this.form.maxTickets),
                        maxTicketsUser: Number(this.form.maxTicketsUser),
                        numNamesPerTicket: Number(this.form.numNamesPerTicket),
                        msiEnabled: this.form.msiEnabled
                    }

                    if (this.isNewTicket) {

                        await this.createTicket(ticketData)
                        this.$toast(this.$t('tickets.addConfirmation'));
                    } else {
                        ticketData.ticketId = this.selectedTicketId
                        await this.updateTicket(ticketData)
                        this.$toast(this.$t('tickets.updateConfirmation'));
                    }

                    this.loadingPopup = false
                    this.openTicketDialog = false;

                } catch (error) {
                    this.loadingPopup = false
                    this.errorPopup = true
                    this.errorPopupMessage = error
                }
            }
        },

        togglePlans() {
            this.showPlans = !this.showPlans
        }
    }
}
</script>

<style scoped>
.subtitle {
    text-align: center;
}

.content {
    margin-top: 40px;
}

.create-ticket {
    text-align: left;
    margin-bottom: 20px;
}

.save-order {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.number-field {
    width: 125px;
}

.amount-text {
    font-size: large;
    font-weight: bold;
}

.received-text {
    font-size: medium;
    font-weight: bold;
}

.active-text {
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
    text-align: right;
    font-weight: bold;
    font-size: large;
}

.active-color {
    color: #006600;
}

.inactive-color {
    color: gray;
}


.action-button {
    margin-left: 20px;
}
</style>