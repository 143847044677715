<template>
    <h4> {{ $t('commonElements.mxnRange') }}</h4>
    <ui-grid style="width: 960px;" position="left">
        <ui-grid-cell columns="4">
            <p> {{ price1MXN }}</p>
            <p style="font-weight: bold;"> {{ $t('pricing.basic') }}: {{ fee1 }} </p>
        </ui-grid-cell>
        <ui-grid-cell columns="4">
            <p> {{ price2MXN }}</p>
            <p style="font-weight: bold;"> {{ $t('pricing.standard') }}: {{ fee2 }} </p>
        </ui-grid-cell>
        <ui-grid-cell columns="4">
            <p> {{ price3MXN }}</p>
            <p style="font-weight: bold;"> {{ $t('pricing.preference') }}: {{ fee3 }} </p>
        </ui-grid-cell>
    </ui-grid>
    <h4> {{ $t('commonElements.usdRange') }}</h4>
    <ui-grid style="width: 960px;" position="left">
        <ui-grid-cell columns="4">
            <p> {{ price1USD }}</p>
            <p style="font-weight: bold;"> {{ $t('pricing.basic') }}: {{ fee1 }} </p>
        </ui-grid-cell>
        <ui-grid-cell columns="4">
            <p> {{ price2USD }}</p>
            <p style="font-weight: bold;"> {{ $t('pricing.standard') }}: {{ fee2 }} </p>
        </ui-grid-cell>
        <ui-grid-cell columns="4">
            <p> {{ price3USD }}</p>
            <p style="font-weight: bold;"> {{ $t('pricing.preference') }}: {{ fee3 }} </p>
        </ui-grid-cell>
    </ui-grid>
</template>

<script>

export default {
    name: "FeesInformation",
    props: {
        accountType: String
    },
    data() {
        return {
            price1MXN: "< $ 999.99 mxn",
            price2MXN: "$ 1000.00 - $ 2999.99 mxn",
            price3MXN: "> $ 3000.00 mxn",
            price1USD: "< $ 49.99 usd",
            price2USD: "$ 50.00 - $ 149.99 usd",
            price3USD: "> $ 150.00 usd",
        }
    },
    computed: {
        fee1() { return this.accountType === 'standard' ? "3.9 %" : "7.9 %" },
        fee2() { return this.accountType === 'standard' ? "2.9 %" : "6.9 %" },
        fee3() { return this.accountType === 'standard' ? "1.9 %" : "5.9 %" },
    }

}
</script>