import { URLS } from "@/utils/constants";
import commonsApi from "./CommonsApi";
import i18n from "@/i18n";
import { useMainStore } from '@/stores/main';

export default class PaymentsAPI {

    async getTicketsReport(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_TICKETS_REPORT, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let ticketsReportData = await response.json();
            return ticketsReportData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async getRegistriesReport(payload) {

        const mainStore = useMainStore();
        const language = mainStore.defaultLanguage;

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_REGISTRIES_REPORT + "?language=" + language, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let registriesReportData = await response.json();
            return registriesReportData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async getTicketPayments(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_PAYMENT_TICKETS + "/" + payload.eventId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let ticketPaymentsData = await response.json();
            //console.log(ticketPaymentsData);
            return ticketPaymentsData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async downloadTicketPayments(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.DOWNLOAD_PAYMENT_TICKETS + "/" + payload.eventId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let ticketPaymentsData = await response.blob();
            //console.log(ticketPaymentsData);
            return ticketPaymentsData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async getRegistryPayments(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.GET_PAYMENT_REGISTRIES + "/" + payload.eventId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let registryPaymentsData = await response.json();
            //console.log(registryPaymentsData);
            return registryPaymentsData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async downloadRegistryPayments(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.DOWNLOAD_PAYMENT_REGISTRIES + "/" + payload.eventId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let registryPaymentsData = await response.blob();
            //console.log(registryPaymentsData);
            return registryPaymentsData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async downloadTicketsReport(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.DOWNLOAD_TICKETS_REPORT, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let ticketsReportData = await response.blob();
            return ticketsReportData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async downloadRegistriesReport(payload) {

        const mainStore = useMainStore();
        const language = mainStore.defaultLanguage;

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.DOWNLOAD_REGISTRIES_REPORT + "?language=" + language, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let ticketsReportData = await response.blob();
            return ticketsReportData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async downloadTicketsPurchasedReport(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.DOWNLOAD_TICKETS_PURCHASED + "/" + payload.eventId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token,
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let ticketsPurchasedReportData = await response.blob();
            return ticketsPurchasedReportData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }

    async downloadRegistriesPurchasedReport(payload) {

        let capcha = await commonsApi.getCaptcha();

        let response = await fetch(URLS.DOWNLOAD_REGISTRIES_PURCHASED + "/" + payload.eventId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                key: capcha,
                vyliwi: payload.token
            },
        });

        if (response.status >= 200 && response.status < 300) { // Success
            let registriesPurchasedReportData = await response.blob();
            return registriesPurchasedReportData;
        }

        if (response.status === 409) {
            let errorMessage = await response.json();
            throw errorMessage.mensaje

        } else {
            throw i18n.global.t('errors.generic')
        }
    }
}