<template>
  <h2> {{ $t('titles.mainSubtitle') }}</h2>
  <login-form />
</template>
<script>
import LoginForm from "@/components/user/LoginForm.vue";
import { useMainStore } from '@/stores/main';
import { RECAPTCHA_ACTIONS } from "@/utils/constants";
import commonsApi from "@/api/CommonsApi";
import i18n from "@/i18n"

export default {
  name: "LoginPage",
  components: {
    LoginForm,
  },
  async beforeRouteEnter(to, from, next) {

    if (window.grecaptcha) {

      await window.grecaptcha.enterprise.ready(async () => {

        try {
          const token = await window.grecaptcha.enterprise.execute(process.env.VUE_APP_RECAPTCHA_KEY, { action: RECAPTCHA_ACTIONS.PAGE_LOAD_LOGIN_CREATE });
          await commonsApi.checkReCaptcha(RECAPTCHA_ACTIONS.PAGE_LOAD_LOGIN_CREATE, token, process.env.VUE_APP_RECAPTCHA_KEY)
        }
        catch (error) {
          console.error(error)
          this.$router.push("/not-found")
        }
      });
    }

    const mainStore = useMainStore()

    if (mainStore.userLogged) {
      next("/registrar");
    } else {
      mainStore.$patch({ pageTitle: i18n.global.t('titles.main') })
      next();
    }
  },
};
</script>
<style scoped>
h2,
h5 {
  text-align: center;
  margin-top: 2em;
}
</style>