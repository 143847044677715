<template>
  <ui-grid>
    <ui-grid-cell columns="12">
      <ui-select outlined fullwidth v-model="selectedType" :options="entryTypes" @selected="onSelected($event)"> {{
        $t('commonElements.entryType') }}
      </ui-select>
    </ui-grid-cell>
  </ui-grid>
</template>
<script>

import { useMainStore } from '@/stores/main'

export default {
  name: "EntryTypeSelector",
  emits: ["onSelectedEntryType"],
  created() {
    this.selectedType = "none";
    const mainStore = useMainStore()
    mainStore.$patch({ selectedType: this.selectedType })
  },
  data() {
    return {
      entryTypes: [
        {
          label: this.$t('commonElements.selectEntryType'),
          value: "none",
          disabled: true,
        },
        {
          label: this.$t('commonElements.tickets'),
          value: "boletos",
        },
        {
          label: this.$t('commonElements.registries'),
          value: "registros",
        },
      ],
      selectedType: "none",
    };
  },
  methods: {
    onSelected(selected) {
      //console.log("selected: " + selected.value);
      const mainStore = useMainStore()
      mainStore.$patch({ selectedType: selected.value })
      this.$emit("onSelectedEntryType");
    },
  },
};
</script>