<template>
  <h2> {{ $t('reports.title') }}</h2>
  <ui-grid class="grid max-width">
    <ui-grid-cell columns="12">
      <entry-type-selector @on-selected-entry-type="updateReport" />
    </ui-grid-cell>
  </ui-grid>
  <div class="content">
    <error-component v-show="errorTable" :message="errorMessage" />
    <ui-skeleton :loading="loadingTable" :title="false" active :paragraph="{ rows: 20 }">
      <tabla-reporte-boletos :tickets-report="ticketsReport" @refresh-report-tickets="updateReport" v-if="showTickets" />
      <tabla-reporte-registros :registries-report="registriesReport" @refresh-report-registries="updateReport"
        v-else-if="showRegistries" />
    </ui-skeleton>
  </div>
</template>
<script>
import { useMainStore } from '@/stores/main';
import { mapState } from 'pinia';
import EntryTypeSelector from "@/components/common/EntryTypeSelector.vue";
import TablaReporteBoletos from "@/components/reportes/TablaReporteBoletos.vue";
import TablaReporteRegistros from "@/components/reportes/TablaReporteRegistros.vue";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import PaymentsAPI from "@/api/PaymentsAPI";
import i18n from '@/i18n';

export default {
  name: "ReportesPage",
  created() {
    const mainStore = useMainStore()
    mainStore.$patch({ pageTitle: i18n.global.t('titles.reports') })
    //console.log("RegistrarBoletoPage created");
  },
  components: {
    EntryTypeSelector,
    ErrorComponent,
    TablaReporteBoletos,
    TablaReporteRegistros,
  },
  data() {
    return {
      ticketsReport: [],
      registriesReport: [],
      showTickets: false,
      showRegistries: false,
      loadingTable: false,
      errorTable: false,
      errorMessage: "",
    };
  },
  computed: {
    ...mapState(useMainStore, ['userLogged', 'selectedType']),
  },
  methods: {
    async updateReport() {
      this.loadingTable = true;
      this.errorTable = false;
      const paymentsAPI = new PaymentsAPI();
      try {
        if (this.selectedType === "boletos") {
          this.ticketsReport = await paymentsAPI.getTicketsReport({
            token: this.userLogged.token,
          });
          //console.log(this.ticketsReport);
          this.showTickets = true;
          this.showRegistries = false;
        } else if (this.selectedType === "registros") {
          this.registriesReport = await paymentsAPI.getRegistriesReport({
            token: this.userLogged.token,
          });
          //console.log(this.registriesReport);
          this.showRegistries = true;
          this.showTickets = false;
        }
      } catch (error) {
        this.errorTable = true;
        this.errorMessage = error;
        this.showTickets = false;
        this.showRegistries = false;
      }
      this.loadingTable = false;
    },
  },
};
</script>
<style scoped>
h2,
h5 {
  text-align: center;
  margin-top: 2em;
}

.grid.max-width {
  max-width: 480px;
}

.content {
  margin: auto;
  width: 90%;
}
</style>