<template>
    <div class="content">
        <ui-skeleton :loading="loadingRegisterTypes" :title="false" active :paragraph="{ rows: 30 }">
            <div class="create-register-type">
                <ui-button raised @click="newRegisterType"> {{ $t('otherPayments.createNew') }}
                    <template #after="{ iconClass }">
                        <ui-icon :class="iconClass">add</ui-icon>
                    </template>
                </ui-button>
            </div>
            <error-component v-show="errorRegisterType" :message="errorMessage" />
            <h3 class="subtitle"> {{ $t('commonElements.active') }} </h3>
            <ui-table fullwidth :data="otrosPagosActivos" :thead="theadOtrosPagos" :tbody="tbodyOtrosPagos"
                selected-key="registerTypeId">
                <template #viewDescription="{ data }">
                    <ui-button icon="launch"
                        @click="showDescription(data.registerTypeDescription, data.registerTypeDescriptionEn)"> {{
            $t('actions.show') }} </ui-button>
                </template>
                <template #viewQuestions="{ data }">
                    <ui-button icon="launch"
                        @click="showQuestions(data.question1Form, data.question2Form, data.question3Form)"> {{
            $t('actions.show') }} </ui-button>
                </template>
                <template #up="{ data }">
                    <ui-icon-button v-show="data.order !== otrosPagosActivos.at(0).order" icon="arrow_upward"
                        @click="moveUpActivos(data)"></ui-icon-button>
                </template>
                <template #down="{ data }">
                    <ui-icon-button v-show="data.order !== otrosPagosActivos.at(otrosPagosActivos.length - 1).order"
                        icon="arrow_downward" @click="moveDownActivos(data)"></ui-icon-button>
                </template>
                <template #actions="{ data }">
                    <ui-button icon="edit" @click="editRegister(data)"> {{ $t('actions.edit') }} </ui-button>
                    <ui-button icon="content_copy" @click="duplicateRegister(data)"> {{ $t('actions.duplicate') }}
                    </ui-button>
                    <ui-button icon="visibility_off" @click="disableRegister(data)"> {{ $t('actions.disable') }}
                    </ui-button>
                    <ui-button icon="delete" @click="removeRegister(data)"> {{ $t('actions.delete') }} </ui-button>
                </template>
            </ui-table>
            <div v-show="showSaveOrderActive" class="save-order">
                <ui-button @click="saveOrder"> {{ $t('commonElements.saveOrder') }}
                </ui-button>
            </div>
            <ui-divider></ui-divider>
            <h3 class="subtitle">{{ $t('commonElements.inactive') }}</h3>
            <ui-table fullwidth :data="otrosPagosInactivos" :thead="theadOtrosPagos" :tbody="tbodyOtrosPagos"
                selected-key="registerTypeId">
                <template #viewDescription="{ data }">
                    <ui-button icon="launch"
                        @click="showDescription(data.registerTypeDescription, data.registerTypeDescriptionEn)"> {{
            $t('actions.show') }}
                    </ui-button>
                </template>
                <template #viewQuestions="{ data }">
                    <ui-button icon="launch"
                        @click="showQuestions(data.question1Form, data.question2Form, data.question3Form)"> {{
            $t('actions.show') }} </ui-button>
                </template>
                <template #up="{ data }">
                    <ui-icon-button v-show="data.order !== otrosPagosInactivos.at(0).order" icon="arrow_upward"
                        @click="moveUpInactivos(data)"></ui-icon-button>
                </template>
                <template #down="{ data }">
                    <ui-icon-button v-show="data.order !== otrosPagosInactivos.at(otrosPagosInactivos.length - 1).order"
                        icon="arrow_downward" @click="moveDownInactivos(data)"></ui-icon-button>
                </template>
                <template #actions="{ data }">
                    <ui-button icon="edit" @click="editRegister(data)"> {{ $t('actions.edit') }} </ui-button>
                    <ui-button icon="content_copy" @click="duplicateRegister(data)"> {{ $t('actions.duplicate') }}
                    </ui-button>
                    <ui-button icon="visibility" @click="enableRegister(data)"> {{ $t('actions.enable') }} </ui-button>
                    <ui-button icon="delete" @click="removeRegister(data)"> {{ $t('actions.delete') }} </ui-button>
                </template>
            </ui-table>
            <div v-show="showSaveOrderInactive" class="save-order">
                <ui-button @click="saveOrder">{{ $t('commonElements.saveOrder') }}
                </ui-button>
            </div>
            <error-component v-show="errorRegisterType" :message="errorMessage" />

            <!-- Descripcion dialog -->
            <ui-dialog v-model="openDescription" fullscreen>
                <ui-dialog-title> {{ dialogTitle }}</ui-dialog-title>
                <ui-dialog-content>
                    <h4 v-show="descriptionEn !== ''">{{ $t('otherPayments.description') }}</h4>
                    <div v-html="description"></div>
                    <h4 v-show="descriptionEn !== ''">{{ $t('otherPayments.descriptionEn') }}</h4>
                    <div v-html="descriptionEn"></div>
                </ui-dialog-content>
                <ui-dialog-actions>
                    <ui-button @click="openDescription = false">OK</ui-button>
                </ui-dialog-actions>
            </ui-dialog>

            <!-- New/Update Registration dialog -->
            <ui-dialog v-model="openRegistrationDialog" fullscreen>
                <ui-dialog-title> {{ registrationDialogTitle }}</ui-dialog-title>
                <ui-dialog-content>
                    <ui-progress v-show="loadingPopup" indeterminate active></ui-progress>
                    <ui-form item-margin-bottom="20" type="|">
                        <template #default="{ subitemClass }">
                            <ui-form-field :class="subitemClass">
                                <label class="active-text" :class="activeClass">{{ activeText }}</label>
                            </ui-form-field>
                            <ui-form-field :class="subitemClass">
                                <register-type-classification-selector :disable-select="!canBeEdited"
                                    @on-selected-classification-id="onSelectedRegisterTypeTitle"> {{
            $t('commonElements.selectOtherPaymentRegistry') }}
                                </register-type-classification-selector>
                            </ui-form-field>
                            <ui-form-field :class="subitemClass">
                                <label class="label-opcion">{{ labelOpcionSeleccionada }}</label>
                            </ui-form-field>
                            <ui-form-field class="required" :class="subitemClass">
                                <label> {{ $t('otherPayments.name') }}</label>
                                <ui-textfield v-model="form.name" helper-text-id="name-helper-text"
                                    fullwidth></ui-textfield>
                                <ui-textfield-helper id="name-helper-text"
                                    v-model:validMsg="validMsg.name"></ui-textfield-helper>
                            </ui-form-field>
                            <ui-form-field class="required" :class="subitemClass">
                                <label> {{ $t('otherPayments.description') }}</label>
                                <ui-editor v-model="form.description" fullwidth with-counter
                                    helper-text-id="description-helper-text"></ui-editor>
                                <ui-textfield-helper id="description-helper-text"
                                    v-model:validMsg="validMsg.description"></ui-textfield-helper>
                            </ui-form-field>
                            <ui-form-field class="required" :class="subitemClass">
                                <label> {{ $t('otherPayments.descriptionEn') }}</label>
                                <ui-editor v-model="form.descriptionEn" fullwidth with-counter
                                    helper-text-id="description-en-helper-text"></ui-editor>
                                <ui-textfield-helper id="description-en-helper-text"
                                    v-model:validMsg="validMsg.descriptionEn"></ui-textfield-helper>
                            </ui-form-field>
                            <ui-form-field :class="subitemClass">
                                <label> {{ $t('otherPayments.question1') }} </label>
                                <ui-textfield v-model="form.question1" fullwidth></ui-textfield>
                            </ui-form-field>
                            <ui-form-field :class="subitemClass">
                                <label>{{ $t('otherPayments.question2') }}</label>
                                <ui-textfield v-model="form.question2" fullwidth></ui-textfield>
                            </ui-form-field>
                            <ui-form-field :class="subitemClass">
                                <label>{{ $t('otherPayments.question3') }}</label>
                                <ui-textfield v-model="form.question3" fullwidth></ui-textfield>
                            </ui-form-field>
                            <br>
                            <ui-divider></ui-divider>
                            <br>
                            <ui-form-field class="required">
                                <label> {{ $t('commonElements.quantityAdjustment') }} </label>
                                <div :class="subitemClass">
                                    <ui-form-field>
                                        <label> {{ $t('otherPayments.maxLimit') }}</label>
                                        <ui-textfield class="number-field" v-model="form.maxRegisters"
                                            input-type="number"
                                            helper-text-id="maxRegisters-helper-text"></ui-textfield>
                                        <ui-textfield-helper id="maxRegisters-helper-text"
                                            v-model:validMsg="validMsg.maxRegisters"></ui-textfield-helper>
                                    </ui-form-field>
                                </div>
                            </ui-form-field>
                            <ui-form-field class="required">
                                <label> {{ $t('otherPayments.enableMSI') }}</label>
                                <div :class="subitemClass">
                                    <ui-form-field>
                                        <ui-switch v-model="form.msiEnabled"></ui-switch>
                                        <label> {{ enabledMSIText }}</label>
                                    </ui-form-field>
                                </div>
                            </ui-form-field>
                            <br>
                            <ui-divider></ui-divider>
                            <br>
                            <ui-form-field class="required">
                                <div :class="subitemClass">
                                    <ui-form-field>
                                        <label class="amount-text">{{ $t('commonElements.salePriceMXN') }} $</label>
                                        <ui-textfield :disabled="!canBeEdited" class="number-field" v-model="form.price"
                                            input-type="number" helper-text-id="price-helper-text"></ui-textfield>
                                        <ui-textfield-helper id="price-helper-text"
                                            v-model:validMsg="validMsg.price"></ui-textfield-helper>
                                        <ui-form-field>
                                            <label> {{ $t('commonElements.feeMXN') }}: </label>
                                            <label class="amount-text"> {{ feeText }}</label>
                                        </ui-form-field>
                                    </ui-form-field>
                                </div>
                            </ui-form-field>
                            <ui-form-field>
                                <label class="received-text"> {{ $t('commonElements.amountReceivedMXN', [receivedMXN])
                                    }}</label>
                            </ui-form-field>
                            <ui-form-field class="required">
                                <div :class="subitemClass">
                                    <ui-form-field>
                                        <label class="amount-text">{{ $t('commonElements.salePriceUSD') }} $</label>
                                        <ui-textfield :disabled="!canBeEdited" class="number-field"
                                            v-model="form.priceUSD" input-type="number"
                                            helper-text-id="priceUSD-helper-text"></ui-textfield>
                                        <ui-textfield-helper id="priceUSD-helper-text"
                                            v-model:validMsg="validMsg.priceUSD"></ui-textfield-helper>
                                        <ui-form-field>
                                            <label> {{ $t('commonElements.feeUSD') }}: </label>
                                            <label class="amount-text"> {{ feeUSDText }}</label>
                                        </ui-form-field>
                                    </ui-form-field>
                                </div>
                            </ui-form-field>
                            <ui-form-field>
                                <label class="received-text">{{ $t('commonElements.amountReceivedUSD', [receivedUSD])
                                    }}</label>
                            </ui-form-field>
                            <ui-form-field v-if="accountType === 'standard'">
                                <label>{{ $t('pricing.stripeDisclaimer') }} <a href="https://stripe.com/pricing"
                                        target="_blank"> Stripe
                                        &#8482;</a></label>
                            </ui-form-field>
                            <br>
                            <ui-divider></ui-divider>
                            <br>
                            <ui-form-field>
                                <label>{{ $t('commonElements.feeNotice') }} </label>
                            </ui-form-field>
                            <ui-form-field>
                                <ui-button @click="togglePlans"> {{ plansButtonText }}</ui-button>
                                <fees-information :account-type="accountType" v-if="showPlans"></fees-information>
                            </ui-form-field>
                        </template>
                    </ui-form>
                    <ui-progress v-show="loadingPopup" indeterminate active></ui-progress>
                    <error-component v-show="errorPopup" :message="errorPopupMessage" />
                </ui-dialog-content>
                <ui-dialog-actions>
                    <ui-button class="action-button" :disabled="loadingPopup"
                        @click.prevent="openRegistrationDialog = false">{{
            $t('actions.cancel') }}</ui-button>
                    <ui-button class="action-button" raised :disabled="loadingPopup"
                        @click.prevent="saveRegisterType">{{
                        $t('actions.save') }}</ui-button>
                </ui-dialog-actions>
            </ui-dialog>
        </ui-skeleton>
    </div>
</template>

<script>
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import RegisterTypeClassificationSelector from "@/components/common/RegisterTypeClassificationSelector.vue";
import formatters from "@/utils/formatters";
import amounts from "@/utils/amounts";
import { useMainStore } from '@/stores/main';
import { mapState, mapActions } from "pinia";
import { useValidator, helpers } from "balm-ui";
import i18n from "@/i18n";
import FeesInformation from "@/components/common/FeesInformation.vue";

const theadOtrosPagos = [
    i18n.global.t('otherPayments.type'),
    i18n.global.t('otherPayments.name'),
    i18n.global.t('commonElements.description'),
    i18n.global.t('otherPayments.optionalQuestions'),
    i18n.global.t('otherPayments.maxLimit'),
    { value: i18n.global.t('commonElements.salePriceMXN'), align: "right" },
    { value: i18n.global.t('commonElements.feeMXN'), align: "right" },
    { value: i18n.global.t('commonElements.salePriceUSD'), align: "right" },
    { value: i18n.global.t('commonElements.feeUSD'), align: "right" },
    i18n.global.t('headers.withMSI'),
    i18n.global.t('otherPayments.registries'),
    i18n.global.t('actions.moveDown'),
    i18n.global.t('actions.moveUp'),
    { value: i18n.global.t('actions.title'), align: "center" },
]

const tbodyOtrosPagos = [
    {
        field: "registerTypeTitle",
        fn: (data) => {
            const mainStore = useMainStore();
            return mainStore.defaultLanguage === 'es' ? data.registerTypeTitle.nameEs : data.registerTypeTitle.nameEn
        },
    },
    "registerTypeName",
    {
        slot: "viewDescription"
    },
    {
        slot: "viewQuestions"
    },
    "maxRegisters",
    {
        field: "registerTypePrice",
        fn: (data) => {
            return formatters.formatCurrency(data.registerTypePrice);
        },
        align: "right",
    },
    {
        field: "registerPctFee",
        fn: (data) => {
            return formatters.formatPct((data.registerPctFee) * 100) + "%";
        },
        align: "right",
    },
    {
        field: "registerTypePriceUSD",
        fn: (data) => {
            return formatters.formatCurrency(data.registerTypePriceUSD);
        },
        align: "right",
    },
    {
        field: "registerPctFeeUSD",
        fn: (data) => {
            return formatters.formatPct((data.registerPctFeeUSD) * 100) + "%";
        },
        align: "right",
    },
    {
        field: "msiEnabled",
        fn: (data) => {
            return data.msiEnabled ? i18n.global.t('commonElements.yes') : i18n.global.t('commonElements.no');
        },
    },
    "submissions",
    {
        slot: "down",
    },
    {
        slot: "up",
    },
    {
        slot: "actions",
    },
]

const validations = [
    {
        key: "name",
        label: i18n.global.t('otherPayments.name'),
        validator: "required",
    },
    {
        key: "description",
        label: i18n.global.t('otherPayments.description'),
        validator: "required, lessThanAllowed",
        lessThanAllowed: {
            validate(value) {
                return value.length < 5000
            },
            message: "%s" + i18n.global.t('validations.tooBig'),
        },
    },
    {
        key: "descriptionEn",
        label: i18n.global.t('otherPayments.descriptionEn'),
        validator: "required, lessThanAllowed",
        lessThanAllowed: {
            validate(value) {
                return value.length < 5000
            },
            message: "%s" + i18n.global.t('validations.tooBig'),
        },
    },
    {
        key: "maxRegisters",
        label: i18n.global.t('otherPayments.maxLimit'),
        validator: "required, positive",
    },
    {
        key: "price",
        label: i18n.global.t('commonElements.salePriceMXN'),
        validator: "required, positive",
    },
    {
        key: "priceUSD",
        label: i18n.global.t('commonElements.salePriceUSD'),
        validator: "required, positive",
    }
]

export default {
    components: {
        ErrorComponent,
        RegisterTypeClassificationSelector,
        FeesInformation
    },
    data() {
        return {
            openDescription: false,
            theadOtrosPagos: theadOtrosPagos,
            tbodyOtrosPagos: tbodyOtrosPagos,
            dialogTitle: "",
            description: "",
            descriptionEn: "",
            showSaveOrderActive: false,
            showSaveOrderInactive: false,
            registrationDialogTitle: "",
            openRegistrationDialog: false,
            openTicketDialog: false,
            isNewRegisterType: true,
            balmUI: useValidator(),
            validations,
            validMsg: {},
            labelOpcionSeleccionada: '',
            form: {
                name: "",
                selectedTitle: "",
                description: "",
                descriptionEn: "",
                maxRegisters: 0,
                active: false,
                question1: "",
                question2: "",
                question3: "",
                price: 0,
                priceUSD: 0,
                msiEnabled: false
            },
            selectedRegisterTypeId: 0,
            canBeEdited: true,
            registerTypeActive: false,
            loadingRegisterTypes: false,
            errorRegisterType: false,
            errorMessage: '',
            loadingPopup: false,
            errorPopup: false,
            errorPopupMessage: "",
            showPlans: false
        }
    },
    computed: {
        ...mapState(useMainStore, ['writableEvent', 'selectedClassificationId', 'defaultLanguage', 'accountType']),
        otrosPagosActivos() {
            return helpers.isEmpty(this.writableEvent.registerTypes) ? [] : [...this.writableEvent.registerTypes.filter(x => x.active).sort((a, b) => a.order - b.order)]
        },
        otrosPagosInactivos() {
            return helpers.isEmpty(this.writableEvent.registerTypes) ? [] : [...this.writableEvent.registerTypes.filter(x => !x.active).sort((a, b) => a.order - b.order)]
        },
        activeText() {
            return this.registerTypeActive ? this.$t('otherPayments.active') : this.$t('otherPayments.inactive')
        },
        activeClass() {
            return this.registerTypeActive ? 'active-color' : 'inactive-color'
        },
        fee() {
            if (this.accountType === 'standard') {
                return amounts.getStandardFeeForMXN(this.form.price)
            } else {
                return amounts.getExpressFeeForMXN(this.form.price)
            }
        },
        feeUSD() {
            if (this.accountType === 'standard') {
                return amounts.getStandardFeeForUSD(this.form.priceUSD)
            } else {
                return amounts.getExpressFeeForUSD(this.form.priceUSD)
            }
        },
        feeText() {
            return formatters.formatPct((this.fee) * 100) + "%";
        },
        feeUSDText() {
            return formatters.formatPct((this.feeUSD) * 100) + "%";
        },
        receivedMXN() {
            const price = isNaN(this.form.price) ? 0 : Number(this.form.price)
            const comision = price * this.fee
            const total = price - comision
            return formatters.formatCurrency(total);
        },
        receivedUSD() {
            const price = isNaN(this.form.priceUSD) ? 0 : Number(this.form.priceUSD)
            const comision = price * this.feeUSD
            const total = price - comision
            return formatters.formatCurrency(total);
        },
        enabledMSIText() {
            return this.form.msiEnabled ? this.$t('commonElements.yes') : this.$t('commonElements.no')
        },
        plansButtonText() {
            return this.showPlans ? this.$t('actions.hide') : this.$t('actions.show')
        }
    },
    methods: {
        ...mapActions(useMainStore, ['updateRegistrationOrder', 'createRegisterType', 'updateRegisterType', 'activateRegisterType', 'deactivateRegisterType', 'deleteRegisterType']),
        showDescription(description, descriptionEn) {
            this.dialogTitle = this.$t('commonElements.description')
            this.description = description
            this.descriptionEn = descriptionEn
            this.openDescription = true;
        },
        showQuestions(q1, q2, q3) {
            this.dialogTitle = this.$t('otherPayments.optionalQuestions')
            this.descriptionEn = ""
            if (helpers.isEmpty(q1) && helpers.isEmpty(q2) && helpers.isEmpty(q3)) {
                this.description = this.$t('commonElements.noInfo')
            } else {
                this.description = (helpers.isEmpty(q1) ? "" : q1) + "<br>" + (helpers.isEmpty(q2) ? "" : q2) + "<br>" + (helpers.isEmpty(q3) ? "" : q3)
            }
            this.openDescription = true;
        },
        onSelectedRegisterTypeTitle() {
            this.form.selectedTitle = this.selectedClassificationId
            switch (this.selectedClassificationId) {
                case 'fixed_payment':
                    this.labelOpcionSeleccionada = this.$t('commonElements.fixedPaymentInstructions')
                    break;

                case 'lodging':
                    this.labelOpcionSeleccionada = this.$t('commonElements.lodgingInstructions')
                    break;

                case 'competition':
                    this.labelOpcionSeleccionada = this.$t('commonElements.competitionInstructions')
                    break;

                case 'initial_payment':
                    this.labelOpcionSeleccionada = this.$t('commonElements.initialPaymentInstructions')
                    break;

                default:
                    break;
            }
        },
        moveUpActivos(registerType) {
            const currentIndex = this.otrosPagosActivos.findIndex(x => x.registerTypeId === registerType.registerTypeId);
            const targetPago = this.otrosPagosActivos.at(currentIndex - 1);

            const originalOrder = registerType.order;
            const targetOrder = targetPago.order;

            this.swapOrder(registerType.registerTypeId, targetPago.registerTypeId, originalOrder, targetOrder)

            this.showSaveOrderActive = true
        },
        moveDownActivos(registerType) {
            const currentIndex = this.otrosPagosActivos.findIndex(x => x.registerTypeId === registerType.registerTypeId);
            const targetPago = this.otrosPagosActivos.at(currentIndex + 1);

            const originalOrder = registerType.order;
            const targetOrder = targetPago.order;

            this.swapOrder(registerType.registerTypeId, targetPago.registerTypeId, originalOrder, targetOrder)

            this.showSaveOrderActive = true
        },
        moveUpInactivos(registerType) {
            const currentIndex = this.otrosPagosInactivos.findIndex(x => x.registerTypeId === registerType.registerTypeId);
            const targetPago = this.otrosPagosInactivos.at(currentIndex - 1);

            const originalOrder = registerType.order;
            const targetOrder = targetPago.order;

            this.swapOrder(registerType.registerTypeId, targetPago.registerTypeId, originalOrder, targetOrder)

            this.showSaveOrderInactive = true
        },
        moveDownInactivos(registerType) {
            const currentIndex = this.otrosPagosInactivos.findIndex(x => x.registerTypeId === registerType.registerTypeId);
            const targetPago = this.otrosPagosInactivos.at(currentIndex + 1);

            const originalOrder = registerType.order;
            const targetOrder = targetPago.order;

            this.swapOrder(registerType.registerTypeId, targetPago.registerTypeId, originalOrder, targetOrder)

            this.showSaveOrderInactive = true
        },
        swapOrder(originalId, targetId, originalOrder, targetOrder) {
            const mainStore = useMainStore()
            mainStore.$patch((state) => {
                state.writableEvent.registerTypes = state.writableEvent.registerTypes.map(registerType => registerType.registerTypeId === originalId ? { ...registerType, order: targetOrder } : registerType)
                state.writableEvent.registerTypes = state.writableEvent.registerTypes.map(registerType => registerType.registerTypeId === targetId ? { ...registerType, order: originalOrder } : registerType)

                state.hasChanged = true
            })
        },
        editRegister(registerType) {
            //console.log(registerType)
            this.isNewRegisterType = false;
            this.registerTypeActive = registerType.active
            this.selectedRegisterTypeId = registerType.registerTypeId
            this.canBeEdited = registerType.submissions < 1
            this.registrationDialogTitle = this.$t('otherPayments.editDisclaimer')
            const mainStore = useMainStore()
            mainStore.$patch({
                selectedClassificationId: registerType.registerTypeTitle.registerTypeClassificationId
            })
            this.form.selectedTitle = this.selectedClassificationId
            this.form.name = registerType.registerTypeName
            this.form.description = registerType.registerTypeDescription
            this.form.descriptionEn = registerType.registerTypeDescriptionEn
            this.form.question1 = registerType.question1Form
            this.form.question2 = registerType.question2Form
            this.form.question3 = registerType.question3Form
            this.form.maxRegisters = registerType.maxRegisters
            this.form.price = registerType.registerTypePrice
            this.form.priceUSD = registerType.registerTypePriceUSD
            this.form.msiEnabled = registerType.msiEnabled
            this.openRegistrationDialog = true;
        },
        duplicateRegister(registerType) {
            //console.log(registerType)
            this.isNewRegisterType = true;
            this.canBeEdited = true
            this.registerTypeActive = false
            this.registrationDialogTitle = this.$t('otherPayments.duplicateTitle')
            const mainStore = useMainStore()
            mainStore.$patch({
                selectedClassificationId: registerType.registerTypeTitle.registerTypeClassificationId
            })
            this.form.selectedTitle = this.selectedClassificationId
            this.form.name = registerType.registerTypeName
            this.form.description = registerType.registerTypeDescription
            this.form.descriptionEn = registerType.registerTypeDescriptionEn
            this.form.question1 = registerType.question1Form
            this.form.question2 = registerType.question2Form
            this.form.question3 = registerType.question3Form
            this.form.maxRegisters = registerType.maxRegisters
            this.form.price = registerType.registerTypePrice
            this.form.priceUSD = registerType.registerTypePriceUSD
            this.form.msiEnabled = registerType.msiEnabled
            this.openRegistrationDialog = true;
        },
        disableRegister(registerType) {
            const regTitle = this.defaultLanguage === 'es' ? registerType.registerTypeTitle.nameEs : registerType.registerTypeTitle.nameEn;
            this.$confirm({
                title: this.$t('otherPayments.confirmDisable', [`${regTitle}: ${registerType.registerTypeName}`]),
                message: this.$t('otherPayments.disableDisclaimer'),
                state: 'help',
                stateOutlined: true,
                acceptText: this.$t('actions.disable'),
                cancelText: this.$t('actions.cancel'),
            }).then(async (result) => {

                if (result) {

                    this.loadingRegisterTypes = true
                    this.errorRegisterType = false
                    this.errorMessage = ""

                    try {

                        await this.deactivateRegisterType(registerType.registerTypeId)
                        this.$toast(this.$t('otherPayments.disableConfirmation'));
                        this.loadingRegisterTypes = false

                    } catch (error) {
                        this.loadingRegisterTypes = false
                        this.errorRegisterType = true
                        this.errorMessage = error
                    }
                }
            })
        },
        enableRegister(registerType) {
            const regTitle = this.defaultLanguage === 'es' ? registerType.registerTypeTitle.nameEs : registerType.registerTypeTitle.nameEn;
            this.$confirm({
                title: this.$t('otherPayments.confirmEnable', [`${regTitle}: ${registerType.registerTypeName}`]),
                message: this.$t('otherPayments.enableDisclaimer'),
                state: 'help',
                stateOutlined: true,
                acceptText: this.$t('actions.enable'),
                cancelText: this.$t('actions.cancel'),
            }).then(async (result) => {

                if (result) {

                    this.loadingRegisterTypes = true
                    this.errorRegisterType = false
                    this.errorMessage = ""

                    try {

                        await this.activateRegisterType(registerType.registerTypeId)
                        this.$toast(this.$t('otherPayments.enableConfirmation'));
                        this.loadingRegisterTypes = false

                    } catch (error) {
                        this.loadingRegisterTypes = false
                        this.errorRegisterType = true
                        this.errorMessage = error
                    }
                }
            })
        },
        removeRegister(registerType) {

            const regTitle = this.defaultLanguage === 'es' ? registerType.registerTypeTitle.nameEs : registerType.registerTypeTitle.nameEn;

            if (registerType.submissions > 0) {

                this.$alert({
                    message: this.$t('otherPayments.purchasedDeletionError', [`${regTitle}: ${registerType.registerTypeName}`]),
                    state: 'error',
                    stateOutlined: true
                });

            } else {

                this.$confirm({
                    title: this.$t('otherPayments.confirmDelete', [`${regTitle}: ${registerType.registerTypeName}`]),
                    message: this.$t('validations.cannotUndo'),
                    state: 'warning',
                    stateOutlined: true,
                    acceptText: this.$t('actions.delete'),
                    cancelText: this.$t('actions.cancel')
                }).then(async (result) => {

                    if (result) {
                        this.loadingRegisterTypes = true
                        this.errorRegisterType = false
                        this.errorMessage = ""

                        try {

                            await this.deleteRegisterType(registerType.registerTypeId)
                            this.$toast(this.$t('otherPayments.deleteConfirmation'));
                            this.loadingRegisterTypes = false

                        } catch (error) {
                            this.loadingRegisterTypes = false
                            this.errorRegisterType = true
                            this.errorMessage = error
                        }
                    }
                })
            }
        },
        async saveOrder() {
            this.showSaveOrderActive = false;
            this.showSaveOrderInactive = false;
            this.loadingRegisterTypes = true;
            this.errorMessage = "";
            this.errorRegisterType = false;

            try {

                await this.updateRegistrationOrder();
                this.loadingRegisterTypes = false

            } catch (error) {
                this.showSaveOrderActive = true;
                this.showSaveOrderInactive = true;
                this.loadingRegisterTypes = false
                this.errorRegisterType = true;
                this.errorMessage = error;
            }
        },
        newRegisterType() {
            this.isNewRegisterType = true;
            this.canBeEdited = true
            this.registerTypeActive = false
            this.registrationDialogTitle = this.$t('otherPayments.addTitle')
            const mainStore = useMainStore()
            mainStore.$patch({
                selectedClassificationId: "lodging"
            })
            this.form.selectedTitle = this.selectedClassificationId
            this.form.name = ''
            this.form.description = ''
            this.form.descriptionEn = ''
            this.form.question1 = ''
            this.form.question2 = ''
            this.form.question3 = ''
            this.form.maxRegisters = 0
            this.form.price = 0
            this.form.priceUSD = 0
            this.openRegistrationDialog = true;
        },
        async saveRegisterType() {

            let result = this.balmUI.validate(this.form);
            let { valid, validMsg } = result;
            this.validMsg = validMsg;

            //console.log(valid)
            //console.log(validMsg)

            if (valid) {

                try {

                    this.loadingPopup = true
                    this.errorPopup = false
                    this.errorPopupMessage = ""

                    const registerTypeData = {
                        registerTypeTitle: { registerTypeClassificationId: this.form.selectedTitle },
                        registerTypeName: this.form.name,
                        registerTypeDescription: this.form.description,
                        registerTypeDescriptionEn: this.form.descriptionEn,
                        question1Form: this.form.question1,
                        question2Form: this.form.question2,
                        question3Form: this.form.question3,
                        registerTypePrice: Number(this.form.price),
                        registerTypePriceUSD: Number(this.form.priceUSD),
                        maxRegisters: Number(this.form.maxRegisters),
                        msiEnabled: this.form.msiEnabled
                    }

                    if (this.isNewRegisterType) {

                        //console.log("Creating " + registerTypeData)
                        await this.createRegisterType(registerTypeData)
                        this.$toast(this.$t('otherPayments.addConfirmation'));
                    } else {
                        registerTypeData.registerTypeId = this.selectedRegisterTypeId
                        //console.log("Updating " + registerTypeData)
                        await this.updateRegisterType(registerTypeData)
                        this.$toast(this.$t('otherPayments.updateConfirmation'));
                    }

                    this.loadingPopup = false
                    this.openRegistrationDialog = false;

                } catch (error) {
                    this.loadingPopup = false
                    this.errorPopup = true
                    this.errorPopupMessage = error
                }
            }
        },
        togglePlans() {
            this.showPlans = !this.showPlans
        }
    }
}
</script>

<style scoped>
.subtitle {
    text-align: center;
}

.content {
    margin-top: 40px;
}

.create-register-type {
    text-align: left;
    margin-bottom: 20px;
}

.save-order {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.number-field {
    width: 125px;
}

.amount-text {
    font-size: large;
    font-weight: bold;
}

.received-text {
    font-size: medium;
    font-weight: bold;
}

.active-text {
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
    text-align: right;
    font-weight: bold;
    font-size: large;
}

.active-color {
    color: #006600;
}

.inactive-color {
    color: gray;
}

.label-opcion {
    font-size: small;
    font-style: italic;
}

.action-button {
    margin-left: 20px;
}
</style>